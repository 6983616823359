/*
  @author Gilles Gerlinger
  Copyright Nokia 2017. All rights reserved.
*/

import ReactGA from 'react-ga';
import moment from 'moment';
// import Store from '../components/Store';
import authService from '../util/authService';

// import Remote from '../util/remote';
// const stats = Remote('Stats', ['incView', 'getViews', 'like', 'unlike', 'getLikes', 'open', 'getAllViews', 'getAllLikes', 'dump'], 'https://learningstore.nokia.com/srv1');
import { Jsql } from 'web_jsql';
const stats = Jsql({ key: '452a7601dbc6f2c38aa89e68bda8b603', url: 'https://learningstore.nokia.com/jsql2'})
// const stats = 
class B {
  back = false;
  isSearchOpen = false;
  expandedKeys = ['root'];
  expandedTopic=[];
  //recommended = [];
  intranet = false;
  currentColl = null;
  origin = [];
  images = [];
  shopcart;
  csoditem = [];
  views = [];
  likes = []; 
  newTopics = [];
  whatsnew = null;
  userProfile = null;
  currentStoreName = null;
  currentStoreDef = null;
  
  server = "https://learningstore.nokia.com";
  // server = "https://backend.store.dyn.nesc.nokia.net";

  constructor() {
    const image = new Image();
    image.onload = () => { this.intranet = true }
    image.src = "https://backend.store.dyn.nesc.nokia.net/employee/img/seeMore.png";
    try {
      this.shopcart = JSON.parse(localStorage.shopcart) || [];
    }
    catch(err) {
      this.shopcart = [];
    }
  }

  set(component) {

    this.component = component;
    this.history = component.props.history;
    this.pathname = component.props.location.pathname;
    // if (window.gtag && this.currentPath !== window.location.pathname) {
    //   this.currentPath = window.location.pathname;
    //   window.gtag('config', Config.trackingID, {'page_path': window.location.pathname});
    //   // console.log('ga');
    // }
    if (this.pathname.indexOf('/search/') === -1) {
      this.isSearchOpen = false;
      delete this.inputStyle;
    }
  }

  ga(UID) {
    this.gaUID = UID;
    if (UID) {
      ReactGA.initialize(UID, { gaOptions: { debug:false, cookieFlags: 'SameSite=None; Secure' } });
      ReactGA.ga('set', 'anonymizeIp', true);  
    }
  }
  pageview(url) {
    if (this.gaUID) ReactGA.pageview(url);
  }

  incView (name, id) { 
    stats({ query: { 'rep: stats.incView': '' }, variables: { sid: name, itemID: id, email: authService.getCLogin() ? authService.getCLogin().email : null}})
    // .then( rep => /* console.log('#views', rep.rep.total) */ null )
    .catch( err => console.log('error incrementing views', err)) 
  }

  getViews(name, id) { return new Promise( (resolve, reject) => {
    if (this.views[id]) 
      resolve(this.views[id]) 
    // else 
    //   stats.getViews(name, id).then( rep => {
    //     this.views[id] = rep.rep
    //     resolve(rep.rep)
    //   } ).catch( err => reject(err) );
  })}

  like(name, id, email = authService.email()) { 
    if (email) stats({ query: { 'rep: stats.like': '' }, variables: { sid: name, itemID: id, email }}); 
    if (this.likes[id]) {
      this.likes[id].push(email)
    }
  }
  unlike(name, id, email = authService.email()) { 
    if (email) stats({ query: { 'rep: stats.unlike': '' }, variables: { sid: name, itemID: id, email}}); 
    if (this.likes[id]) {
         this.likes[id] = this.likes[id].filter(elm => elm !== email) 
        }
  }

  getAllTheLikes() { return new Promise( (resolve, reject) => {
    if (this.likes)
     resolve(this.likes)
    // else 
    //   stats.getLikes(name, id).then( rep => {
    //     this.likes[id] = rep.rep
    //     resolve(rep.rep) 
    //   } ).catch( err => reject(err) );
  })}


  getLikes(name, id) { return new Promise( (resolve, reject) => {
      if (this.likes[id])
       resolve(this.likes[id])
      // else 
      //   stats.getLikes(name, id).then( rep => {
      //     this.likes[id] = rep.rep
      //     resolve(rep.rep) 
      //   } ).catch( err => reject(err) );
  })}

  viewFigure = (s) =>  isNaN(s)? 0: Number(s)

  calcViewsFigure = (v) => {
    let s1, s2, s3
    const t = new Date()
    let y = t.getFullYear()
    let m = t.getMonth() + 1 
    if (m >=4)  { 
      s1 = y.toString() + 'M' + (m-1).toString()
      s2 = y.toString() + 'M' + (m-2).toString()
      s3 = y.toString() + 'M' + (m-3).toString()
    }
    else if (m===3) {
      s1 = y.toString() + 'M2' 
      s2 = y.toString() + 'M1'
      s3 = (y-1).toString() + 'M12' 
    }
    else if (m===2) {
      s1 = y.toString() + 'M1' 
      s2 = (y-1).toString() + 'M12'
      s3 = (y-1).toString() + 'M11'
    }
    else { // january 
      s1 = (y-1).toString() + 'M12' 
      s2 = (y-1).toString() + 'M11'
      s3 = (y-1).toString() + 'M10'
    }
    return  this.viewFigure(v[s1]) + this.viewFigure(v[s2]) + this.viewFigure(v[s3])
  }

  getPopularity(name, id) { return new Promise ((resolve, reject) => {
    this.getLikes(name, id).then(l => {
      this.getViews(name, id).then(v => {
 
        let likes = l.length
        let views = this.calcViewsFigure(v)
        let score = likes*100+views
        if (score>319) resolve(5)  // 130 items
        else if (score>61) resolve(4) // 500 items
        else if (score>15) resolve(3) // 800 items
        else if (score>8) resolve(2) // 600 items
        else if (score>1) resolve(1) // A pack of items
        else resolve(0)  // 2700 items
      })
    })})
  }

  getAllLikes(name, ids) { return stats({ query: { 'rep: stats.getAllLikes': '' }, variables: { sid: name, items: ids }}) }
  getAllViews(name, ids) { return stats({ query: { 'rep: stats.getAllViews': '' }, variables: { sid: name, items: ids }}) }
  dumpStats(name, ids) { return stats({ query: { 'rep: stats.dump': '' }, variables: { sid: name, items: ids }}) }

  csOpen(sid, itemID) {
    const clogin = authService.getCLogin();
    if (clogin) stats({ query: { 'rep: stats.open': '' }, variables: { sid, itemID, email: clogin.email }});
  }

  addToCart(id,title){
    if (this.shopcart.indexOf(id) === -1) {
      this.shopcart.push({id,title});
      localStorage.shopcart = JSON.stringify(this.shopcart);
      this.header.setCart(this.shopcart.length);
    }
  }

  removeFromCart(item){
    this.shopcart.splice(this.shopcart.indexOf(item), 1);
    localStorage.shopcart = JSON.stringify(this.shopcart);
    this.header.setCart(this.shopcart.length);
  }

  formatCSOD(rep, item) {
    // description
    let desc = rep.Description.replace(/style=(").*?(")|style=(').*?(')/g, '').replace(/(<font .*>|<\/font>)/g,'').replace(/<((?!>).)*$/g,'');
    const exclude = desc.match(/(").*?(")/g);
    if (exclude) exclude.forEach( (occur, index) => desc = desc.replace(occur, 'occur'+index) )

    const ext = desc.replace(/((Target (Audiences?|Groups?))|Primary Audiences?|Audiences?)/g,"Audience")
    .replace(/(Course Description |Course Overview|Brief Overview|Brief Description|Overall Description)/gi,"Description")
    .replace(/(Overall Objectives|Learning (Objectives?|Targets?)|Course Objectives|Training Objectives|Objectives?)/g,"Objective")
    .replace(/(Pre-?requisites?|Recommended Training|(Expected|Recommended) Knowledge|Expected Foundation Knowledge)/g,"Prerequisite")
    .replace(/(Course Content|Course Outline:?|Lab Exerci(c|s)es|Contents?|Lab Equipments?:?)/gi,"NotVisible");
    const Description = ext.match(/Description.*?(NotVisible|Objective|Prerequisite|Audience|$)/gs);
    const Audience = ext.match(/Audience.*?(Prerequisite|NotVisible|Objective|Description|$)/gs);
    const Prerequisites = ext.match(/Prerequisite.*?(NotVisible|Objective|Audience|Description|$)/gs);
    const Objectives = ext.match(/Objective.*?(Prerequisite|NotVisible|Audience|Description|$)/gs);
    const NotVisible = ext.match(/NotVisible.*?(Objective|Prerequisite|Audience|Description|$)/gs);
    let csod = {
      description: Description ? Description[0].replace(/(Descriptions?:?)/g,'').replace(/(Audiences?:?|Prerequisite|Objective|NotVisible)/g,'').replace(/(<br><br>|<br>)/i,'').replace(/((<br*><br*>)*$)|((<br*>)*$)i/,'') : undefined,
      audience: Audience ? Audience[0].replace(/(Audiences?:?)/g,'').replace(/(Description|Prerequisite|Objective|NotVisible)/g,'').replace(/(<br><br>|<br>)/i,'').replace(/((<br*><br*>)*$)|((<br*>)*$)/i,'') : undefined,
      prerequisites: Prerequisites ? Prerequisites[0].replace(/(Prerequisites?:?)/g,'').replace(/(Description|Audiences?:?|Objective|NotVisible)/g,'').replace(/(<br><br>|<br>)/i,'').replace(/((<br*><br*>)*$)|((<br*>)*$)/i,''): undefined,
      objectives: Objectives ? Objectives[0].replace(/(Objectives?:?)/g,'').replace(/(Description|Audiences?:?|Prerequisite|NotVisible)/g,'').replace(/(<br><br>|<br>)/i,'').replace(/((<br*><br*>)*$)|((<br*>)*$)/i,'') : undefined,
      notvisible: NotVisible ? NotVisible[0].replace(/(NotVisibles?:?)/g,'').replace(/(Description|Audiences?:?|Prerequisite|Objective)/g,'').replace(/(<br><br>|<br>)/i,'').replace(/((<br*><br*>)*$)|((<br*>)*$)/i,'') : undefined
    };
 
    // description
    item.Description = csod.description;
    item.Audience = csod.audience;
    item.Prerequisites = csod.prerequisites;
    item.Objectives = csod.objectives;

    if (exclude) {
      if(item.Description) exclude.forEach( (occur, index) =>  item.Description = item.Description.replace('occur'+index, occur) );
      if(item.Audience) exclude.forEach( (occur, index) => item.Audience = item.Audience.replace('occur'+index, occur) );
      if (item.Prerequisites) exclude.forEach( (occur, index) =>  item.Prerequisites = item.Prerequisites.replace('occur'+index, occur) );
      if (item.Objectives) exclude.forEach( (occur, index) =>  item.Objectives = item.Objectives.replace('occur'+index, occur) );
    }

    item.Title = rep.Title;
    item.Format = rep.TrainingSubType;
    if (item.Format)
      item.Icon = '/img/CSOD-Icon-' + item.Format.replace(/ /g, '') + '.png';

    if (rep.Customfields) {
      for (let i = 0; i < rep.Customfields.length; i++) {
        if (rep.Customfields[i].Title === 'Owner') {
          item.Owner = rep.Customfields[i].Value;
          break;
        }
      }
    }
    item.Owner = item.Owner || 'NLDH';

    const fullhumanize = moment.duration(rep.Duration, 'minutes');
    const H = Math.floor(fullhumanize.asHours());
    // const H = fullhumanize._data.hours;
    const M = fullhumanize._data.minutes;
    item.Duration = H+':'+('0'+M).slice(-2)+' hour(s)' ;        

  }
}

export default new B();