/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
 */

import React, {Component} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AdvancedLoader from './components/AdvancedLoader'
import ConnectionRequest from './components/ConnectionRequest'

import './App.css';
import Store from './components/Store';
import Item from './components/Item';
import Collection from './components/Collection';
import Topic from './components/Topic';
import Search from './components/Search';
import Edit from './components/Edit';
import Source from './tools/data';
import Home from './components/Home';
import TreeView from './components/TreeView';
import CalendarComponent from './components/CalendarComponent';
import IconLibrary from './components/IconLibrary';
import B from './tools/back';
// import NotFound from './components/NotFound';
// import {FaSearch, FaBars, FaShoppingCart} from 'react-icons/fa';
import ShoppingCart from './components/ShoppingCart';
import Advanced from './components/Advanced';
// import Recommended from './components/Recommended';
import Gcc from './components/gcc';
import Csod from './components/csod';
import Ziggy from './components/ziggy';
import Audit from './components/Audit';
import HeaderComponent from './components/Header';
import LoginComponent from './components/Login';

// import './css/custom-antd.css';

const item = '/item/';
const pattern = /\/(item|search)\//;
const hash = /#\?item=/
class ModalSwitch extends Component {

  state = {isLoading:false, init:true, currentUser:B.userProfile}

  componentDidMount() {
    B.currentStoreName = window.location.pathname.split('/')[1]
    if (!B.currentstoreDef || B.currentStoreDef.id !== B.currentStoreName) B.currentStoreDef = Source.getDef(B.currentStoreName);
    this.setState({init:false});
    
    const {location} = this.props;
    if (location.hash.match(hash))  // for compatibility with old URL schema
      window.location.href = location.pathname + 'item/' + location.hash.split('item=')[1];
    else if (location.pathname.match(pattern)) {
      this.setState({isLoading:true});
      let url = location.pathname.split(pattern);
      let name = url[0].slice(1);
      if (!this.props.match.params.name) this.props.match.params.name = name;
      Source.fetch(name).then(rep => this.setState({isLoading: false}));
    }
  }

  render() {
    if (this.state.init) return null;

    if (this.state.isLoading || !B.currentStoreDef)  {
      return (
        <div>
          {false && <div className="head">
            <HeaderComponent {...this.props}  minimum setCurrentUser={(usr) => this.setState({currentUser: usr})} data={B.currentStoreDef}/>
          </div>}
          <div className="loading">
            <AdvancedLoader />
          </div>
        </div>
      );
    }

    if (B.currentStoreDef.requestLogin && !this.state.currentUser) {
      return <div>
              <div className="head">
                <HeaderComponent {...this.props} minimum setCurrentUser={(usr) => this.setState({currentUser: usr})} data={B.currentStoreDef}/>
              </div>
              <div className="loading">
                <ConnectionRequest />
              </div>
          </div>
    }

    const {location} = this.props;
    let isModal, elem;
    if (location.pathname.indexOf(item) > -1) {
      let url = location.pathname.split(item);
      let id = url[1] ? url[1].split('/')[0] : null;
      let store = Source.get(url[0].slice(1));
      if (store) {
        elem = store.getByID(id);
        if (elem && !elem.Solutions && !elem.Html && !elem.Sections) {
          this.previousLocation = {pathname: B.path || url[0]}
          isModal = true;
        }
      }
    }

    return (

      <div className={'minHeight'}>
        <Switch location={isModal ? this.previousLocation : location}>
          <Route path='/:name/search/:text' component={Search}/>
          <Route path='/:name/item/:id/:edit?' component={(elem && elem.Sections) ? Topic : Collection}/>
          <Route path='/:name/edit/:id' component={Edit}/>
          <Route path='/:name/create/:type' component={Edit}/>
          <Route path='/:name/csod' component={Csod}/>
          <Route path='/:name/ziggy' component={Ziggy}/>
          {/* <Route path='/:name/create-collection' render={props => (
            <Edit {...props} type="collection"/>
          )}/>
          <Route path='/:name/create-item' render={props => (
            <Edit {...props} type="item"/>
          )}/> */}
          {/* <Route path='/:name/create-item' type={"item"} component={Edit} /> */}
          <Route path='/:name/audit' component={Audit}/>
          <Route path='/:name/tree' component={TreeView}/>
          <Route path='/:name/calendar' component={CalendarComponent}/>
          <Route path='/:name/icon' component={IconLibrary}/>
          <Route path='/:name/shopcart' component={ShoppingCart}/>
          <Route path='/:name/gcc' component={Gcc}/>
          <Route path='/:name/advanced/:uriFormat?' component={Advanced}/>
          {/* <Route path='/:name/recommended' component={Recommended} /> */}
          <Route path='/:name/login/:ID/:url' component={LoginComponent} />
          <Route path='/:name' component={Store}/>

          
          
        </Switch>
        {isModal ? <Route path='/:name/item/:id/:reco?' component={Item}/> : null}
      </div>
    )
  }
}

export default class App extends Component {
  state = {isLoading: true}

  componentDidMount() {
    B.currentStoreName = window.location.pathname.split('/')[1]
    if (!B.currentStoreDef || B.currentStoreDef.id !== B.currentStoreName) B.currentStoreDef = Source.getDef(B.currentStoreName);

    fetch(`${process.env.PUBLIC_URL}/store.json`)
    .then(resp => resp.json())
    .then(resp => {
      Source.setDefs(resp);
      this.setState({items: resp, isLoading: false});
    });
  }

  render() {
    if (this.state.isLoading) return null;

    return (
      <Router basename={`${process.env.PUBLIC_URL}/`}>
        <Switch>
          <Route exact path='/' render={() => {
            if (window.location.port) return <Home stores={this.state.items}/>

            window.location.href ='https://learningstore.nokia.com';
            return null;
          }}
            //<Route exact path='/' render={() => ( <Redirect to={"/employee"} />)}
          />
          <Route exact path='/index.html' render={() => <Home stores={this.state.items}/>} />
          <Route component={ModalSwitch}/>
        </Switch>
      </Router> 
    ); 
  }
}