/*
  @author Gilles Gerlinger
  Copyright Nokia 2017. All rights reserved.
*/

import editCtl from './editCtl';
import wip from './editWip';
import { Config } from '../config';
import md5 from 'md5';
import lz from 'lz-string';
import moment from 'moment';
// import {saveAs} from 'file-saver';
// import renderHTML from 'react-render-html';
// import h2p from 'html2plaintext';

import B from './back';
// import { keys } from '@material-ui/core/styles/createBreakpoints';
// import Remote from '../util/remote';
// const redis = Remote('Redis', ['getAll'], 'https://learningstore.nokia.com/srv1');
import { decode } from "web_jsql";
// init('https://learningstore.nokia.com/sites/lzw_bg.wasm');
// init();//.then(rep => console.log('!!!!!!!!!'));

const reserved = ['o.1', 'c.33', 'w.1'];

const last = (arr) => arr[arr.length-1]

class Data {
  // data = [wip, wip.unsaved];
  ids = [];
  names = []; // to check names in double
  allData = [];
  searchStack = [];

  create(name, data) {
    window._ids = this.ids;
    if (!this.names[name]) this.names[name] = [];

    this.ids[wip.ID] = wip;
    this.ids[wip.unsaved.ID] = wip.unsaved;
    this.ids[wip.admin.ID] = wip.admin;
    this.ids[wip.ready.ID] = wip.ready;
    this.ids[wip.review.ID] = wip.review;

    data = data.filter( item => item.ID );
    this.allData = data;

    //BV INCLUDEIN PROBLEM 
    //const collections = data.filter( item => (item.Solutions || item.Sections) && reserved.indexOf(item.ID) === -1 && !item.Exclude );
    const collections = data.filter( item => (item.Solutions || item.Sections) && reserved.indexOf(item.ID) === -1);
  
    const rst = [];
    const parent = (col, item) => {
      const ref = { id:col.ID, title:col.Title }
      if (!rst[item.ID]) rst[item.ID] = [ref];
      else if (rst[item.ID].findIndex( obj => obj.id === ref.id ) === -1) rst[item.ID].push(ref);
    }
    
    data.forEach( item => {
      if (this.ids[item.ID] && !item.ReadOnly) console.log('double ID', name, ':', item.ID);
      this.ids[item.ID] = item; // build dictionary
      if (item.Title) {
        const title = item.Title.trim().toLowerCase();
        // if (this.names[name][title]) console.log('double name', name, ':', title);
        this.names[name][title] = item;
      }
      if (!item.sid) item.sid = name;
      if (!item.Status) item.Status = item.Wip ? 'Development' : 'Published';
      if (item.Url) item.Url = item.Url.replace('(', ' >>').split(')')[0];
      if (item.Html) {
        item.Html = item.Html
        .replace(/src=img/g, `src=${process.env.PUBLIC_URL}/img`)
        .replace(/href=#/g, 'href=')
        .replace(/border=0/g, '')
        .replace(/valign=top/g, '')
      }

      // collections.forEach( col => {
      //   if(col.Solutions){
      //     // if (col.Flat) col.Solutions.forEach( subcol => {
      //     //     const theColl = this.ids[subcol];
      //     //     if (theColl.Exclude && theColl.Solutions.indexOf(item.ID) > -1) parent(col, item);
      //     //   });
      //     if (col.Solutions.indexOf(item.ID) > -1) parent(col, item);
      //   }
      //   if(col.Sections){
      //     col.Sections.forEach(section => {
      //       if(section["objects_"+section.ID]){
      //         if(section["objects_"+section.ID].indexOf(item.ID) > -1) parent(col, item);
      //       }
      //     });
      //   }
      // });

      wip.process(item);
    }); 

    collections.forEach( item => {
      if(item.Solutions) {
        item.Solutions = item.Solutions.filter( id => this.ids[id] );
        item.Solutions.forEach( sol => parent(item, this.ids[sol]) );
      }
      else if(item.Sections) item.Sections.forEach( section => {
        // if(section["objects_"+section.ID]) 
        section["objects_"+section.ID] = section["objects_"+section.ID].filter( id => this.ids[id] ); 
        section["objects_"+section.ID].forEach( sol => parent(item, this.ids[sol]) );
      });
    });

    const o1 = this.ids['o.1'] = this.ids['o.1'] || { ID: 'o.1', Title: 'Root Collections' }
    o1.Solutions = collections.filter( item => item.Icon ).map( item => item.ID ).filter( item => !rst[item] || rst[item].length === 0 );
    o1.Solutions = o1.Solutions.sort( (a,b) => this.ids[a].Title > this.ids[b].Title ? 1 : -1);
    o1.Description = o1.Description || 'This shows all root collections of the store';
    o1.Exclude = o1.ReadOnly = true;
    // console.log('data', o1)    
    return rst;
  }

  getAllData = () => Object.keys(this.ids).map(key => this.ids[key]).filter(item => ["wip", "unsaved", "admin", "ready", "review"].indexOf(item.ID) === -1);

  getByID = id => this.ids[id]

  _match(attr, term) { return attr.replace(/<(?:.|\n)*?>/gm, ' ').toLowerCase().indexOf(term) > -1; }

  _filterSection(sections, term) { // filter through Topic Pages
    return sections.filter(section => Object.keys(section).filter(key => {
      if (key.match(/text_s\.|title_s\./) && (typeof section[key] === 'string' || section[key] instanceof String) && this._match(section[key], term)) return true;
      if (key.match(/objects_s\./)) 
        return section[key].filter( id => this.ids[id] && this.ids[id].Title && this._match(this.ids[id].Title, term)).length;
      return false;
      // console.log(key, section[key])
    }).length ).length;
  }

  _filterCollection(Solutions, term) {
    return Solutions.filter( id => this.ids[id] && this.ids[id].Title && this._match(this.ids[id].Title, term)).length;
  }

  filter(term, mapping) {
    // console.log('filter', term, mapping, this.allData.length, Object.keys(this.ids).length)
    if (!term) return [];

    let data;
    term = term.toLowerCase();
    if (this.searchStack.length) {
      // console.log('term', term, 'searchStack', this.searchStack)
      const prevSearch = last(this.searchStack)
      // console.log('prev', term, prevSearch)
      if (term === prevSearch.term) return prevSearch.data
      if (term.startsWith(prevSearch.term)) data = prevSearch.data
      else if (term.length === prevSearch.term.length-1 && prevSearch.term.startsWith(term)) {
        this.searchStack.pop()
        if (this.searchStack.length) return last(this.searchStack).data
      }
      else this.searchStack = []
    }

    if (!data) data = Object.keys(this.ids).map(key => this.ids[key])

    let field = null;
    const pos = term.indexOf(':');
    if (pos > -1) {
      field = term.slice(0, pos);
      if (field === 'title' || (mapping && mapping.some( item => item.split('|')[0].replace('_', '').replace('*', '').toLowerCase() === field)))
        term = term.slice(pos+1);
      else field = null;
      // console.log('s', field, term);
    }
  
    data = data.filter(item =>
      item.del || (item.Exclude && !editCtl.editMode) ? false : Object.keys(item).filter( key =>
        field && key.toLowerCase() !== field ? false : 
          (key!=='Url' && (typeof item[key] === 'string' || item[key] instanceof String) && this._match(item[key], term) )
          || (key === 'Sections' && this._filterSection(item.Sections, term) > 0)
          || (key === 'Solutions' && this._filterCollection(item.Solutions, term) > 0)
          || (key === 'Segment' && item[key].Segment && item[key].filter(seg => seg.toLowerCase().trim().indexOf(term.toLowerCase().trim()) > -1).length > 0)
      ).length
    );

    const collections = [], LOs = [], collTitles = [], LOTitles = [], collTitlesFirst = [], LOTitlesFirst= [];
    data.forEach( item => {
      let pos = item.Title && item.Title.toLowerCase().indexOf(term);
      if (pos === 0) {
        if (item.Solutions || item.Sections) collTitlesFirst.unshift(item);
        else LOTitlesFirst.unshift(item);
      }
      else if (pos > -1)
        if (item.Solutions || item.Sections) collTitles.unshift(item);
        else LOTitles.unshift(item);
      else
        if (item.Solutions || item.Sections) collections.unshift(item);
        else LOs.unshift(item);
    });
    data = collTitlesFirst.concat(collTitles).concat(LOTitlesFirst).concat(LOTitles).concat(collections).concat(LOs);
    // console.log('push', term, data.length)
    this.searchStack.push({ term, data });
    return data;
  }

  recommendFilter(profile) {
    // Extract Job from position and create a new field
    //profile["Job Discipline Text"] = profile["Position"].split(">")[0].trim().slice(0, -1).trim()
    profile["Job Discipline Text"] = profile["Position"].split(">")[0].trim().match(/.+(\d)*/)[0].trim()
    console.log("Employee job is [", profile["Job Discipline Text"], "]")
  
    const tmp = Object.keys(this.ids).map(key => this.ids[key]).filter(item =>
      item.del || (item.Exclude && !editCtl.editMode) || !item.Tags ? false : item.Tags.filter( tagObj => {
        let yes = true;
        Object.keys(tagObj).forEach(key =>{
          if(!profile[key] || (profile[key].trim() !== tagObj[key].trim())) yes = false;
        })
        return yes;
      }).length
    );
    return tmp;
  }
}

class Store {
  defs = [];
  stores = [];
  elts = [];
  loaded = [];

  get = name => this.stores[name]

  set(name, data, preload) {
    // console.log('data > set >', name)
    // if (!this.get(name)) {
      this.stores[name] = new Data();

      // "this.collections" contains all the parent of each items
      this.collections = this.stores[name].create(name, data);

      const hp = this.defs[name].homepage.id;
      if (hp) this.stores[name].getByID(hp).noDel = true;
      if (!preload) editCtl.load(name);// apply changes if any
      const rules = this.defs[name].rules;
      if (rules) {
        fetch(`${process.env.PUBLIC_URL}/${rules}`)
        .then( rep => rep.json())
        .then( rep => this.defs[name].fieldInfo = rep );
      }

    // }
    return this.get(name);
  }

  getDef = name => this.defs[name]
  setDefs = storeJson => storeJson.forEach(store => this.defs[store.id] = store)

  getCollections = (name, solutionID) => {
    if (!this.collections[solutionID]) return null;
    return this.collections[solutionID].filter(coll => {
      const item = this.get(name).getByID(coll.id);
      return item && !item.del && !item.Exclude
    });
  }

  // Same as getCollections (get the parents), but including the parents excluded from search
  getParentCollections = (name, solutionID) => {
    if (!this.collections[solutionID]) return null;
    return this.collections[solutionID].filter(coll => {
      const item = this.get(name).getByID(coll.id);
      return item && !item.del
    });
  }



  getSync(name) {
    return new Promise(resolve => {
      const store = this.get(name);
      if (store) resolve(store);
      else this.synchro = resolve;
    });
  }

  fetch(name, zip) {
    B.currentStoreName = window.location.pathname.split('/')[1]
    if (!name) name = B.currentStoreName;  else B.currentStoreName = name
    return new Promise((resolve, reject) => {
      if (this.stores[name] && this.loaded[name]) resolve(this.stores[name]);
      else {
        fetch(`${B.server}/sites/${md5(name+'.gz')}`)
        .then( r => r.arrayBuffer())
        .then( r => {
          // console.log('r', r.byteLength, `${B.server}/sites/${md5(name+'.gz')}`)
          const all = decode(new Uint8Array(r))
          this.elts = JSON.parse(all)
          const store = this.set(name, this.elts);
          this.loaded[name] = true; // store is fully loaded
          const allIDS = this.elts.map( item => item.ID );
          B.dumpStats(name, allIDS).then( ({ data }) => {
            // console.log('data', data)
            data.rep[0].forEach( (view, i) => B.views[allIDS[i]] = view )
            data.rep[1].forEach( (like, i) => B.likes[allIDS[i]] = like )
            resolve(store);
          })
          .catch( err => {
            console.log('Error',err)
            resolve(store)
          })
          if (this.synchro) this.synchro(this.get(name));          
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
      }
    });
  }

  // BV : allow Format to also apply to "Collection", "Learning object" and "Topic page" if no value defined in "Format" fied
  filterFormat(elt, format) {
    if (!elt.Format) {
      if (elt.Solutions && format === "Collection") return true
      else if (elt.Sections && format === "Topic page") return true;
      else if (elt.Url && (format === "Learning object" || format === "Item")) return true;
      else return false;

    } else return elt.Format === format;
 
  }

  format = text => text ? text.replace(/&amp;/g, '&').replace(/&nbsp;/g, ' ').replace(/<.*?>/g, '') : ''

  filter = (name, term) => this.get(name).filter(term, this.getDef(name).mapping);
  advancedFilter = (name, term, format, segment, date) => {
    //console.log(name, term, format, segment, date)
    //format = format.trim();
    // console.log('advancedFilter', name, term)
    let ret = term ? this.get(name).filter(term, [...this.getDef(name).mapping, 'Segment']) : this.get(name).getAllData();
    if (ret && format)  ret = ret.filter(elt => this.filterFormat(elt, format) );
    if (ret && segment) ret = ret.filter(elt => (elt.Segment && (elt.Segment.indexOf(segment) > -1)));
    if (ret && date)    ret = ret.filter(elt => (elt.date && (moment(elt.date).isSame(moment(date), 'day'))));
    return ret.filter(elt => elt.Title);
  }

  recommendFilter = (profile, name) => this.get(name).recommendFilter(profile);

  decodeIcon(data, url) {
    // console.log('decoding image', data.Icon)
    if (!data.Icon) data.Icon = Config.defaultIcon;
    if (data.Icon.startsWith('data:') || data.Icon.startsWith('/')) return data.Icon;
    return url + "/" + data.Icon;
  }

  getCalendar(name) {
    return new Promise((resolve, reject) => {
      if (this.calendar) resolve(this.calendar);
      else {
        fetch(`${B.server}/${md5('calendar.' + name)}`)
        .then( rep => rep.text() )
        .then( rep => resolve( this.calendar = JSON.parse(lz.decompressFromBase64(rep)) ))
        .catch(err => {
          console.log(err);
          reject(err);
        });
      }
    });
  }
}

export default new Store();