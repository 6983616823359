/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import '../css/Header.css'
import {
  FaBars,
  FaTimes,
  FaSignInAlt,
  FaInfoCircle,
} from 'react-icons/fa'
// import SearchInput from './SearchInput'
import { Drawer, Tooltip, Avatar, Badge } from 'antd'
import lz from 'lz-string'

import Navigation from './Navigation'
import { MdSchool } from 'react-icons/md'
import NavigationEdit from './NavigationEdit'
import Edit from '../tools/editCtl'
import B from '../tools/back'
import Source from '../tools/data'
import Gcc from './gcc'
import Ziggy from './ziggy'


// Icons to be used as React Components
import { ReactComponent as GlobalSearchIcon } from "./search-web.svg";
import { ReactComponent as LocalSearchIcon } from "./magnify.svg";

// import Remote from '../util/remote'

import authService from '../util/authService'
import { Button } from 'antd'

// const privacy = new Remote('sendMail', ['getOrg'], B.server + '/srv1')
import ac_getOrg from '../tools/getOrg'
const localKey = 'cf06bce7b2cb'

const ctx = {}

export default class Header extends Component {
  toggleSideNav = () => {
    this.setState({ isOpenSideNav: !this.state.isOpenSideNav })
  }
  search = (event) => {
    let newNalue = event.target.value
    this.setState({ value: newNalue })
    if (this.props.searchInput) {
      this.props.searchInput(newNalue)
    }
    this.props.history.push(
      '/' +
        this.props.location.pathname.split('/')[1] +
        '/search/' +
        encodeURI(newNalue),
    )
  }
  resetSearch = () => {
    this.setState({ value: '' })
    if (this.props.searchInput) {
      this.props.searchInput('')
    }
    this.props.history.push('/' + this.props.location.pathname.split('/')[1])
  }

  updateLogin = (image) => {
    this.setState({
      photo: image,
      msUser: authService.app.getUser(),
    })
  }

  

  constructor(props) {
    super(props)
    if (this.props.match.params.text) {
      this.state = {
        value: decodeURI(this.props.match.params.text),
        isOpenSideNav: false,
      }
    } else if (this.props.location.pathname.split('/')[2] === 'search') {
      this.state = {
        value: '',
        isOpenSideNav: false,
      }
      this.firstLoad = true
    } else {
      this.state = {
        value: '',
        isOpenSideNav: false,
      }
    }
    B.header = this
    //const { name } = this.props.match.params
    //this.storeDef = Source.getDef(name)
    //this.storeDef = this.props.data
    const name = B.currentStoreName

    this.menu = B.currentStoreDef.menuOpen ? 1 : 0

    if (window.localStorage[localKey]) {
      const user = JSON.parse(
        lz.decompressFromBase64(window.localStorage[localKey]),
      )
      this.state.msUser = { displayableId: user.email }
      this.setUserProfile(name)
      //if (this.props.setCurrentUser) this.props.setCurrentUser({email: user.email, recommended: B.recommended})
    } else {
        this.state.msUser = authService.app.getUser()
        this.setUserProfile(name)
        //if (this.props.setCurrentUser) this.props.setCurrentUser(this.state.msUser? {email: this.state.msUser.displayableId, recommended: B.recommended}: null)
        if (this.state.msUser)
          authService
            .getPhoto()
            .then((image) => (image ? this.setState({ photo: image }) : void 0))
            .catch((e) => console.log('Fail getting user photo:', e))
    }
  }

  // setUserProfile(name) {
  //   Source.fetch(name).then(() => {
  //     if (authService.app.getUser()) {
  //       const email = localStorage.badgeEmail ? localStorage.badgeEmail : authService.app.getUser().displayableId
  //       privacy
  //         .getOrg(email).then(org => {
  //             if (org.rep && org.rep === 'no record') throw new Error('This email is not attributed')
  //             let recommendedSolutions = Source.recommendFilter(org.rep, name)
  //             B.getAllTheLikes().then( likes => {
  //               if (likes) {
  //                 let myLikes = Object.entries(likes).filter(elm => {
  //                   //console.log("ELM1", elm[1])
  //                   return elm[1]? elm[1].includes(email): false
  //                 }  ).map(elm => elm[0])
  //                 if (this.props.setCurrentUser) this.props.setCurrentUser({email: email, recommended: recommendedSolutions, likes: myLikes})
  //               }
  //           })
  //         })
  //         .catch((err) => { console.log('ERROR', err) })
  //     }
  //   })
  // }

  getOrg = (email) =>
    new Promise((resolve) => {
      if (ctx.org) resolve(ctx.org)
      else
        ac_getOrg(email)
          .then((org) => resolve((ctx.org = org)))
          .catch((e) => console.log('Fail to get organizations', e))
    })

  setUserProfile(name) {
    if (!B.currentStoreName) B.currentStoreName = window.location.pathname.split('/')[1]
    if (!name) name= B.currentStoreName
    Source.fetch(name).then(() => {
      if (authService.app.getUser()) {
        //const email = localStorage.badgeEmail ? localStorage.badgeEmail : authService.app.getUser().displayableId
        //if (localStorage.badgeEmail) console.log('Warning: you are connected as', email)
        // console.log('user', authService.app.getUser())
        const email = authService.app.getUser().displayableId

        // We immediatly give back control after setting the email
        if (this.props.setCurrentUser) {
          this.props.setCurrentUser(
            (B.userProfile = {
              email: email,
              recommended: [],
              likes: [],
            }),
          )
        }
 
        // Only after giving back the control, we add the recommendationd and likes
        Promise.all([this.getOrg(email), B.getAllTheLikes()])
          .then(([org, likes]) => {
            if (org.rep && org.rep === 'no record')
              throw new Error('This email is not attributed')
            if (this.props.setCurrentUser) { 
              const myLikes = likes
                ? Object.entries(likes)
                    .filter((elm) => (elm[1] ? elm[1].includes(email) : false))
                    .map((elm) => elm[0])
                : []
              this.props.setCurrentUser(
                (B.userProfile = {
                  email: email,
                  recommended: Source.recommendFilter(org.rep, name),
                  likes: myLikes,
                }),
              )
            }
          })
          .catch((err) => {
            console.log("Fail to get employee organization and 'likes'", err)
          })
      }
    })
  }

  UNSAFE_componentWillMount() {
    // componentDidMount() {
    //   this.setState({isOpenSideNav: this.props.data.isOpenSideNav});

    document.title = this.props.title
      ? this.props.title + ' | Nokia Learning Store'
      : 'Nokia Learning Store'

    // only allow google search if stated in store.json
    if (
      !this.props.data.googlesearch &&
      !document.querySelector("[name='robots']")
    ) {
      let noGoogle = document.createElement('meta')
      noGoogle.setAttribute('name', 'robots')
      noGoogle.content = 'noindex, nofollow'
      document.getElementsByTagName('head')[0].appendChild(noGoogle)
    }
  }

  moveCaretAtEnd(e) {
    const temp_value = e.target.value
    e.target.value = ''
    e.target.value = temp_value
  }

  setCart(value) {
    this.setState({ cart: value })
  }

  logout = () => {
    //B.recommended=[]
    //localStorage.removeItem(this.props.match.params.name + "HPRec");
    this.setState({ msUser: null })
    if (this.props.setCurrentUser) this.props.setCurrentUser(null)
  }

  render() {
    const { name } = this.props.match.params
    const pathTitle = this.props.icon
      ? '/' + name + '/icon'
      : `/${this.props.data.id}/`

    if (this.menu === 0) B.currentStoreName = name

    return (
      <div>
        <Drawer
          placement="right"
          width={320}
          closable={false}
          maskClosable={true}
          maskStyle={{ opacity: 1 }}
          onClose={this.toggleSideNav}
          zIndex={100000}
          style={{ padding: 0 }}
          visible={this.state.isOpenSideNav}
        >
          <div className={'topDrawer'}>
            <div className="headerIcons">
                <FaTimes
                  size={20}
                  style={{ marginTop: '15px' }}
                  onClick={() => this.toggleSideNav()}
                  color="#ffffff"
                />
            </div>
            <div>
              <MdSchool color="white" className="imageDrawer" size="120" />
            </div>
          </div>
          {!this.props.minimum && <div className="menu">
            <Navigation
              toggleSideNav={this.toggleSideNav}
              history={this.props.history}
              data={this.props.data}
            />
          </div>} 
        </Drawer>

        <div className="header headercolor">
          <div className="logo-title">
            <Link to={`/`} replace>
              <div className="logo" title="logo">
                <img
                  src={`${process.env.PUBLIC_URL}/img/NOKIA_rgb.png`}
                  // src={`${process.env.PUBLIC_URL}/img/NOKIA_w.png`}
                  alt=""
                />
              </div>
            </Link>
            {/* <div className="pipe"></div> */}
            <Link to={pathTitle} replace>
              <span className="itemH">
                {this.props.icon
                  ? 'Learning Store Icon-Library'
                  : this.props.data.title}
              </span>
            </Link>
          </div>

          <div className="header-input">
            {this.state.msUser && (
              <div className="header-icons">
                <Tooltip
                  placement="bottom"
                  title={"Disconnect from " + this.state.msUser.displayableId}
                  overlayStyle={{ zIndex: 100000 }}
                  overlayClassName="infoText"
                >
                  <Avatar
                    size={40}
                    style={{ marginTop: '15px' }}
                    shape="square"
                    icon="user"
                    src={this.state.photo}
                    onClick={() => {
                      authService
                        .logout()
                        .then((flag) => (flag ? this.logout() : false))
                    }}
                  />
                </Tooltip>
              </div>
            )}
            {/* { this.state.msUser && !this.state.photo && <div className="header-icons">
              <Tooltip placement="bottom" title={this.state.msUser.displayableId} overlayStyle={{zIndex:100000}}>
                <FaUser className="infoIcon" size={20} onClick={ ()=> authService.logout() }/>
              </Tooltip>
            </div>} */}

            {!this.state.msUser && B.currentStoreDef.id !== 'partner' && (
              <div className="headerIcons">
                <Tooltip
                  placement="bottom"
                  title="Connect as a Nokia employee"
                  overlayStyle={{ zIndex: 100000 }}
                  overlayClassName="infoText"
                >
                  <FaSignInAlt
                    className="info-icon"
                    size={20}
                    onClick={() => {
                      if (
                        !this.msUser &&
                        !authService.app.isCallback(window.location.hash)
                      ) {
                        authService.login().then((token) => {
                          this.setUserProfile(name)
                          //if (this.props.setCurrentUser) this.props.setCurrentUser({email: authService.app.getUser().displayableId, recommended: B.recommended})
                          if (token)
                            authService
                              .getPhoto()
                              .then((image) =>
                                this.setState({
                                  photo: image,
                                  msUser: authService.app.getUser(),
                                }),
                              )
                              .catch((e) =>
                                this.setState({
                                  msUser: authService.app.getUser(),
                                }),
                              )
                        })
                      }
                    }}
                  />
                </Tooltip>
              </div>
            )}

            {B.currentStoreDef.id !== 'partner' && !this.props.minimum && (
              <div className="headerIcons">
                <Tooltip
                  placement="bottom"
                  title="Open menu drawer"
                  overlayStyle={{ zIndex: 100000 }}
                  overlayClassName="infoText"
                >
                <FaBars
                  className="info-icon"
                  onClick={() => this.toggleSideNav()}
                  size={20}
                  color="#ffffff"
                />
                </Tooltip>
              </div>
            )}

            
            {B.currentStoreDef.id !== 'partner' && !this.props.minimum && (
              <Tooltip
                placement="bottom"
                title={"Search " + B.currentStoreDef.title}
                overlayClassName="infoText"
              >
                <div className="headerIcons">
                  <Link to={'/' + name + '/advanced'}>
                    <LocalSearchIcon style={{height: 30, verticalAlign: 'middle'}} />
                  </Link>
                </div>
              </Tooltip>
            )}


            {B.currentStoreDef.id === 'employee' && !this.props.minimum && (
              <Tooltip
                placement="bottom"
                title="Search for learning across all our platforms"
                overlayClassName="infoText"
              >
                <div className="headerIcons">
                  <a href="https://learningstore.nokia.com/search/index.html" target="_blank"  rel="noopener noreferrer">
                    <GlobalSearchIcon style={{height: 30, verticalAlign: 'middle'}} />
                  </a>
                </div>
              </Tooltip>
            )}


            {B.currentStoreDef.headerButtonsWithText && authService.app.getUser() && !this.state.msUser && (
              
              <div className="header-icons">
                <Tooltip
                  placement="bottom"
                  title="Nokia employee login"
                  overlayClassName="infoText"
                  onClick={() => {
                    
                    authService.login().then((token) => {
                      this.setUserProfile(name)
                      if (this.props.setCurrentUser)  this.props.setCurrentUser({email: authService.app.getUser().displayableId, recommended: B.recommended})

                      if (token)
                        authService
                          .getPhoto()
                          .then((image) =>
                            this.setState({
                              photo: image,
                              msUser: authService.app.getUser(),
                            })
                          )
                          
                    })
                  
                }}
                >
                 
                </Tooltip>
              </div>
            )}

            {B.currentStoreDef.headerButtonsWithText && (
              <div className="header-icons">
                <Button
                  className="styleButton"
                  ghost
                  onClick={() => this.toggleSideNav()}
                >
                  <FaBars className="infoIcon" size={20} />
                  Menu
                </Button>
              </div>
            )}

            {B.currentStoreDef.headerButtonsWithText  && (
              <div className="header-icons">
                <Button
                  className="styleButton"
                  href={'/' + name + '/advanced'}
                  ghost
                >
                  <LocalSearchIcon style={{height: 25, verticalAlign: 'middle'}} />
                  Search
                </Button>
              </div>
            )}

            {/* theshoppingcart */}
            {B.currentStoreDef.hasCart && !Edit.editMode && (
              <div className="headerIcons">
                <Link to={'/' + name + '/shopcart'}>
                  <Badge count={this.state.cart || B.shopcart.length} />
                  <Tooltip
                    placement="bottom"
                    title={'Go to the information cart'}
                    overlayClassName="infoText"
                  >
                    <FaInfoCircle className="info-icon" size={25} />
                  </Tooltip>
                </Link>
              </div>
            )}
          </div>
        </div>
        {!this.props.isEditOnItem && Edit.editMode && (
          <div id="edit">
            <NavigationEdit props={this.props} data={this.storeDef} />
          </div>
        )}
        {B.currentStoreDef.feedback && (
          <div>
            <div className="usrp-feedback-button-1">
              <i>
                <svg viewBox="0 0 50 50" enableBackground="0 0 50 50">
                  <path
                    className="fill"
                    d="M37.1,15.9L35,13.7c-0.9-0.9-2.6-0.9-3.5,0l-2.1,2.1c0,0,0,0,0,0s0,0,0,0L16.1,29.1c0,0,0,0,0,0s0,0,0,0l-0.3,0.3c-0.1,0.1-0.1,0.1-0.1,0.2L14.2,36c0,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.1,0.4,0.1c0,0,0.1,0,0.1,0l6.5-1.5c0.1,0,0.2-0.1,0.2-0.1l0.2-0.2c0,0,0,0,0,0s0,0,0,0l13.2-13.2c0,0,0,0,0,0c0,0,0,0,0,0l2.1-2.1C38.1,18.4,38.1,16.8,37.1,15.9z M21.4,33.7l-4.2-4.2l12.5-12.5l4.2,4.3L21.4,33.7z M16.6,30.3l4,4l-5.2,1.2L16.6,30.3z M36.4,18.7l-1.8,1.8l-4.2-4.3l1.8-1.8c0.6-0.6,1.6-0.6,2.1,0l2.1,2.1C37,17.1,37,18.1,36.4,18.7z"
                  />
                </svg>
              </i>
              <span>
                <a
                  rel="noopener noreferrer"
                  href="https://forms.office.com/Pages/ResponsePage.aspx?id=URdHXXWWjUKRe3D0T5YwsEsOeddWydZNrr_QqkC2r8hUMFBDVDJXVFQ4RzdLQkRCSDdDRE8zQjZJVy4u"
                  target="_blank"
                >
                  Feedback
                </a>
              </span>
            </div>
          </div>
        )}
        {B.currentStoreDef.GCC && <Gcc />}
        {!this.props.minimum && B.currentStoreDef.ask && <Ziggy />}
      </div>
    )
  }
}
