import React, { useState, useEffect, Component } from 'react';
// import {Redirect} from 'react-router-dom';
// import NotFound from './NotFound';
import AdvancedLoader from './AdvancedLoader'
import {Button, Form, Input, Row, Checkbox} from 'antd';
import lz from 'lz-string';

import HeaderComponent from './Header';
import FooterComponent from './Footer';
import B from '../tools/back';
import authService from '../util/authService';
// import BottomScrollListener from "react-bottom-scroll-listener";
import Source from '../tools/data';
import Remote from '../util/remote';

import '../css/Login.css'
import paramLoginForm from '../ressources/loginForm.json';

const track = Remote('External', ['signIn'], 'https://learningstore.nokia.com/srv1');

const { TextArea } = Input;

const validateEmailRegex = (string) => {
  if (typeof string !== "string") { return false }
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(string).toLowerCase());
};

export default props => {

  const {name, url, ID} = props.match.params;
  const redirect = (goBack) => {
    if (goBack) B.history.goBack();
    window.open(lz.decompressFromBase64(url), '_blank');
  }

  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loginForm, setLoginForm] = useState({newsletter: true});
  const [errors, setErrors] = useState({});

  const adv = new Component()
  adv.props = props;
  adv.state = {};
  B.set(adv);

  const storeDef = Source.getDef(name);

  useEffect(() => {
    // authService.app.getUser() ? setIsAuthenticated(true) : setLoginForm(authService.getCLogin() || {});
    if (authService.app.getUser() || authService.getCLogin()) setIsAuthenticated(true);
    setIsLoading(false);

  }, [name]);

  function proceedToCourse () {
    let err = [];
    paramLoginForm.forEach( elt => !loginForm[elt.param] && !elt.optional ? err[elt.param] = elt.message : 0 );

    if (!validateEmailRegex(loginForm.email)) err['email'] = 'Please enter a valid email';

    if (!loginForm.policy) err['policy'] = 'Please acknowledge the Nokia Privacy Policy'

    if(Object.keys(err).length !== 0){
      setErrors(err);
    } else {
      loginForm.ID = ID;
      loginForm.sid = name;
      delete loginForm.policy; // not usefull to store
      setErrors({});
      authService.setCLogin(loginForm);
      track.signIn(loginForm).then( () => redirect(true) );
    }
  }

  if (isLoading) return (
    <div>
      <div className="head">
        <HeaderComponent {...props} data={storeDef} />
      </div>
      <div className="loading">
        <AdvancedLoader />
      </div>
    </div>
  );

  if (isAuthenticated) return redirect();

  return (
    <div className="store">
      <div className="head">
        <HeaderComponent {...props} data={storeDef} />
      </div>
      <Row className="loginForm">
        Please fill the form below to access the course you have selected
      <Form>
          {paramLoginForm.map((p,key)=>                   
          <div key={key+1}>    
              <span className="label bordershare">{key+1}. {p.label} </span>                   
              <TextArea autosize={true} placeholder={p.placeholder} name={p.param} value={loginForm[p.param]} onChange={e => setLoginForm({...loginForm, [e.target.name]: e.target.value})}/>                    
              <span style={{color: "red"}}>{errors[p.param]}</span>                                                                                
          </div>)}
          <br/>
          {/* <Form.Item>
            <Checkbox name="newsletter" checked={loginForm.newsletter} onChange={e => setLoginForm({...loginForm, [e.target.name]: e.target.checked})}>I would like to receive a regular email newsletter</Checkbox> */}
            {/* {loginForm.signIn && <div>
              <a onClick={()=> {
                authService.delCLogin();
                setLoginForm({});
              }}>Clear the form</a>
            </div>} */}
          {/* </Form.Item> */}
          <Form.Item>
            <Checkbox name="policy" onChange={e => setLoginForm({...loginForm, [e.target.name]: e.target.checked})}>I have read and accept the Nokia <a href="https://www.nokia.com/en_int/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></Checkbox>
            <br></br><span style={{color: "red"}}>{errors['policy']}</span>
          </Form.Item>
          <Form.Item style={{textAlign:'center'}}> 
          <Button type="primary" htmlType="submit" onClick={proceedToCourse}>Sign In</Button>
          </Form.Item>
      </Form>
      {/* <Divider></Divider>
      <div><i style={{}}>By Clicking the button, bla bla bla</i></div> */}
      </Row>

     <FooterComponent props={props} data={storeDef} />
    </div>
  );
}

/* SIDE NOTES
The URL of the courses are public so anyone can copy it and share it


Presentation
No, not adapted to long lists and mobile

Tracking -> 
    what is launched (only for login items)
    people do not want that we collect their click and navigation. We had clear instructions on that from users and Nokia

Once analytics are in place, recommendations based on previous courses taken (along with individual’s profile) can be suggested
We have our own recommendation mechanism which is based on job roles...

•	Their history – what did they click on in the past; what did they consumed in the past (free and paid)
paid is managed by CSOD / customer portal they have 2 logins
*/