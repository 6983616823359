/*
  @author Benoit Vaillant
*/

import React, {Component} from 'react';
import {Modal, Tooltip } from 'antd'; 

export default class ZoomImage extends Component {

  state = {
    visible: false 
  }

  handleCancel = e => {
    this.setState({ visible: false, });
  };

  render() {  
    return(
      <React.Fragment>

          <Modal 
            width='fit-content'
            zIndex= '100000'
            style={{top: 20}}
            bodyStyle={{padding: 0, textAlign: 'center' }}
            visible={this.state.visible}
            onCancel={this.handleCancel}
            footer=""
            title=""
          >
            <img 
              src={this.props.src} 
              style={{ maxWidth: 'calc(100vw - 10px)', height: 'unset', maxHeight: 'calc(100vh - 4em)'}} 
              alt="enlarged illustration"
            />
            <p>click outside the image to close</p>
          </Modal>

        <Tooltip title="click to enlarge">
          <button onClick={() => this.setState({visible: true})} style={{border:'none'}}>
            <img src={this.props.src} className={this.props.className} style={{height: 'unset'}} alt="illustration" />
          </button>
        </Tooltip>
      </React.Fragment>
  )
  }
}