import React, {Component} from 'react';
import {Form, FormEventsEmitter, TextField} from 'react-components-form';
import {Button, Divider, Modal, Row} from 'antd'
import ToggleField from './ToggleField';
import Quill from './Quill';
import wipC from '../tools/editWip';
import Source from '../tools/data';

import '../css/ModalSection.css';

let ready = false;
let currentID = '';
const eventsListener = new FormEventsEmitter();
eventsListener.listen('modelChange', (evt) => {
  // console.log('changeModel', evt)
  if (!ready) return;
  wipC.unsave(currentID);
  setunLoad();
});

const unload = (e) => {
    e.returnValue = 'ok';
    return 'ok';
  }
const setunLoad = () => window.onbeforeunload = wipC.stay() ? unload : null;

export default class ModalSection extends Component {

    state={
      toggle: false,
      linked: this.props.model['linked_' + this.props.model.ID] ? this.props.model['linked_' + this.props.model.ID] : false,
    };

    UNSAFE_componentWillMount(){
      const name = this.props.name;
      Source.getSync(name)
      .then(store => {
        this.store = store;
      });
    }

    render(){

        currentID = this.props.currId;
        // console.log(this.props.model['sequence_' + this.props.model.ID]);
        // console.log(this.props.selectedSolutions);

        this.props.model['sequence_' + this.props.model.ID] = this.state.toggle;
        this.props.model['linked_' + this.props.model.ID] = this.state.linked;
        //console.log(this.props.model);

        return(

            <Modal
                title={"Add " + this.props.model.type}
                style={{ top: 200 }}
                visible={this.props.visible}
                onOk={this.props.handleOk}
                onCancel={this.props.handleCancel}
                maskClosable={false}
                className="sectionModal"
                footer={[
                    <Button className="buttonModal" key="back" onClick={this.props.handleCancel}>Cancel</Button>,
                  <Button className="buttonModal" key="submit" type="primary" onClick={this.props.handleOk}>
                    Save
                  </Button>,
                  this.props.model["title_"+this.props.model.ID] && <Button className="buttonModal" key="delete" type="primary" onClick={this.props.handleDelSection}>Remove</Button>
                ]}
              >
                <Form model={this.props.model} eventsListener={eventsListener} onSubmit={data => {
                }} className="form">
                    {
                        <div>
                        {(!(this.props.model.type === "collection") || (!this.state.linked)) && <Row>
                            <label className="editLabel">Title : </label>
                            <TextField className='editField' name={"title_" + this.props.model.ID}/>
                        </Row>}
                        {(this.props.model.type === "image" || this.props.model.type === "video") && <Row>
                            <label className="editLabel">Url : </label>
                            <TextField className='editField' name={"url_" + this.props.model.ID}/>
                        </Row>}
                        {(this.props.model.type !== "title") && (!(this.props.model.type === "collection") || (!this.state.linked)) && <Row>
                            <label className="editLabel">Description : </label>
                            <Quill className='editField' name={"text_" + this.props.model.ID}/>
                        </Row>}
                        {this.props.model.type === "objects" && 
                          <div>
                            <div className="selectedItems">
                              <Divider>Items</Divider>
                              {!(this.props.selectedSolutions.length > 1) && <div className="selectedItemsEmpty">Empty</div>}
                              {this.props.selectedSolutions}
                            </div>
                          <ToggleField className='editField' change={() => this.setState({toggle: !this.state.toggle})} name={'sequence_' + this.props.model.ID} checked={this.props.model['sequence_' + this.props.model.ID]} field={'Sequence'} info={null} />
                        </div>}
                        {this.props.model.type === "collection" && 
                          <div>
                            <div className="selectedItems">
                              <Divider>Items</Divider>
                              {!(this.props.selectedCollections.length > 1) && <div className="selectedItemsEmpty">Empty</div>}
                              {this.props.selectedCollections}
                            </div>
                            <ToggleField className='editField' change={() => this.setState({linked: !this.state.linked})} style={{display: "inline-block"}} name={'linked_' + this.props.model.ID} checked={this.props.model['linked_' + this.props.model.ID]} field={'Title and Description linked'} info={null} /> 
                          </div>}
                    </div>
                    }
                </Form>
            </Modal>
        );
    }
}