import React, {Component} from 'react';

import { Grid } from '@material-ui/core'
import '../css/ThumbnailContainer.css'

import "../css/slick.css"; 
import "../css/slick-theme.css";
import Slider from "react-slick";
import { FaDownload } from 'react-icons/fa'

export default class ThumbnailContainer extends Component {
  state = {batchSize: 20}

  UNSAFE_componentWillMount() {
    this.setState({batchSize: this.props.batchSize})     
  }
 
  moreItem =  <Grid item xs={12} sm={6} md={4} lg={3} xl={3}  key={'moreItem'}>
                <div className="moreItem" onClick={() => this.setState({batchSize: this.state.batchSize + 20})}>
                  Load more results..
                  <div style={{color: 'lightsteelblue', fontSize: 'xxx-large', marginTop: '30px' }}><FaDownload /></div>
                </div>
                
              </Grid>


  render() {
    let items, currentSlice

    if (this.props.thumbnailList.length===0) 
      return <div style={{height:'50px', background:'ghostwhite', padding:'5px'}}>{this.props.emptyListMessage? this.props.emptyListMessage: 'Nothing to show'}</div>
    
      if (this.props.singleLine) 
      return <Slider infinite={false} swipeToSlide={true} variableWidth={true}> {this.props.thumbnailList}</Slider>
  
    items =  this.props.thumbnailList.map((item, index) => 
        item ? <Grid style= {{marginTop: '20px'}} item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>{item}</Grid> : null
    )

    // console.log('ThumbnailContainer render', items) 
    if (items.length > this.state.batchSize) {
      currentSlice = items.slice(0, this.state.batchSize)
      currentSlice.push(this.moreItem)
    }
    else currentSlice = items

    return  <Grid container alignItems="center" spacing={3} >{currentSlice}</Grid>
  }

}