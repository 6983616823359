
export const appConfig = {
  // clientID: "576e147d-05f1-4ba4-860e-a2d66d55dda9",
  clientID: "601e5c6a-33d4-4c23-9a24-6e36b7f57846",
  authority: "https://login.microsoftonline.com/5d471751-9675-428d-917b-70f44f9630b0",
  // graphScopes: ["People.Read", "user.read", "mail.send", "sites.readWrite.all", "Contacts.Read", "User.ReadBasic.All"],//, "sites.read.all", "files.read.all" ],
  graphScopes: ["User.Read", "User.ReadBasic.All"],//, "sites.read.all", "files.read.all" ],
  graphEndpoint: "https://graph.microsoft.com/v1.0",
  // serverUrl: process.env.NODE_ENV === 'production' ? tdpBackend + ":8091" : "http://localhost:8091",
};
