import React, {Component} from 'react';
import B from '../tools/back';
import '../css/Banner.css';
import {Button, Col, Modal, Row} from 'antd'


export default class Banner extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: true,
    };
    this.bannerText = 'Nokia sites use cookies to improve and personalize your experience and to display advertisements. The sites may also include cookies from third parties. By using this site, you consent to the use of cookies.'
  }

  handleCancel = () => {
    B.isCookiesAlreadyShown = true;
    this.setState({open: false})
  }

  accept = () => {
    localStorage.nokia_cookieconsent_dismissed = true
    B.isCookiesAlreadyShown = true;
    this.setState({open: false})
  }


  render() {



    return (
      <Modal
        maskClosable
        destroyOnClose={true}
        maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0)',}}
        visible={this.state.open}
        bodyStyle={{borderRadius: 0, backgroundColor: '#273142'}}
        style={{top: 0}}
        onCancel={this.handleCancel}
        closable={false}
        width={'100%'}
        zIndex={1000000000}
        footer={null}
      >
        <Row type="flex" align="middle">
          <Col span={16} offset={2}><p className={'bannerText'}>{this.bannerText} <a className={'linkBanner'}
                                                                                     target={'_blank'}
                                                                                     rel="noopener noreferrer"
                                                                                     href="https://www.nokia.com/en_int/cookies">Learn
            More</a></p></Col>
          <Col span={5} offset={1}> <Button size={'large'} className={'buttonBanner'} onClick={() => this.accept()}>
            Agree
          </Button></Col>
        </Row>

      </Modal>
    )
  }
}