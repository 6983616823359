/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, {Component} from 'react';
// import {Link} from 'react-router-dom'
import '../css/Footer.css';
import {FaCircle} from "react-icons/fa";
import {Row} from 'antd'

export default class Footer extends Component {


  render() {
    const links = [];
    const data = this.props.data.footer;
    const dataUrl = this.props.data.footerUrl;
    const year = new Date().getFullYear();
    if(!dataUrl){
      if(data) data.forEach( (item, i) => {
        links.push(<a key={i} className={'footerLink'} href={item.url} target='_blank' rel="noopener noreferrer">{item.title}</a>);
        if (i < data.length - 1) links.push(<FaCircle key={50 + i} style={{padding: 4, marginRight: 5, marginLeft: 5}}/>)
      });
      return (
        <div className="footer">
          <Row>{links}</Row>
          <Row>Copyright © {year} Nokia.All Rights Reserved.</Row>
        </div>
      );
    }
    else return <iframe width="100%" height="410" className="foot" src={dataUrl} title="footer"/>
  }
}