/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, {Component} from 'react';
import {Row,Col, Input, Button, Table} from 'antd';
import Source from '../tools/data';
import HeaderComponent from './Header';
import FooterComponent from './Footer';
import '../css/Csod.css';
// import Remote from '../util/remote';
import {Config} from '../config.js';
import Ctl from '../tools/editCtl';
import wipC from '../tools/editWip';
import B from '../tools/back';
// import {saveAs} from 'file-saver';
import moment from 'moment';
import renderHTML from 'react-render-html';

// const csod = Remote('Csod', ['get', 'print']);

// const print = (ID, title) => {
//   csod.print(ID)
//   .then( rep => {
//     const byteCharacters = atob(rep.rep);
//     const byteNumbers = new Array(byteCharacters.length);
//     for (let i = 0; i < byteCharacters.length; i++) {
//         byteNumbers[i] = byteCharacters.charCodeAt(i);
//     }
//     saveAs(new Blob([new Uint8Array(byteNumbers)], {type: 'application/pdf'}), title.replace(/ /g, '_') + '.pdf');
//   });
// }

import { Jsql } from 'web_jsql';
const csod = Jsql({ key: 'b43fdd98b1fd705ae4c3a10cf25aad8a', url: 'https://backend.store.dyn.nesc.nokia.net/jsql' })

const Search = Input.Search;

const columns = [{
  title: 'Title',
  width: 250,
  dataIndex: 'title',
  key: 'title',
},{
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
},{
  title: 'Duration',
  dataIndex: 'Duration',
  key: 'Duration',
},{
  title: 'Description',
  dataIndex: 'Descr',
  key: 'Descr',
}];

const col = [{
  title:'Title',
  width: 50,
  dataIndex: 'title',
  key: 'title',
},{
  title:'Type',
  width: 240,
  dataIndex: 'type',
  key: 'type',
},{
  title:'Course number',
  width: 240,
  dataIndex: 'cn',
  key: 'cn',
}]

const colFullSearch = [{
  title:'Title',
  width: 250,
  dataIndex: 'Title',
  key: 'Title',
},{
  title:'Type',
  width: 50,
  dataIndex: 'Type',
  key: 'TrainingSubType',
},{
  title:'Provider',
  width: 50,
  dataIndex: 'Provider',
  key: 'provider',
},{
  title:'Duration',
  width: 20,
  dataIndex: 'Duration',
  key: 'duration',
}, 
{
  title: 'Action',
  key: 'action',
  width: 20,   
  dataIndex: 'Action'
},

]



export default class Csod extends Component { 
    
  state = { value: "" };
  state = { mode : false};

  UNSAFE_componentWillMount(){
    const {name} = this.props.match.params;        
    if (!Ctl.editMode) 
      this.props.history.push('/' + name);
    window.scrollTo(0, 0);
    this.storeDef = Source.getDef(name);
    // window.pdf = print;
  }

  searchBasedOnCN = (courseNumber) => {
    const {name} = this.props.match.params;        
    const exist = Source.filter(name, "title:" + courseNumber);
    if (exist.length) {
      const reponse = 
        <div>
          <div className="searchResult">Already in store</div>
        </div>
      this.setState({value:reponse}); 
    }
    else {      
      const reponse = 
        <div style={{margin:0}}>
          <div id="loader"></div>
        </div>
      this.setState({value:reponse});                
     
      // csod.get(courseNumber)
      csod({ query: {"rst: csod.get":""}, variables: { courseNumber } })
      .then( rep => {
          console.log('rep',rep);

          if (rep.error) {
            // console.log(rep.err);
            if (rep.error.startsWith('Too many candidates')) {
              const candidates = JSON.parse(rep.err.replace('Too many candidates: ', ''));
              // console.log(candidates);
              const datac = [{
                key: candidates.map(index => index),
                title: candidates.map((candidat,index) => <p key={index}>{candidat.title}</p>),
                type: candidates.map((candidat,index) => <p key={index}>{candidat.type}</p>),
                cn: candidates.map((candidat,index) => <p key={index}>{candidat.cn}</p>),
              }]
              const reponse = 
              <div >
                  <div className="searchResult">
                    <h1>Too many candidates</h1>
                    {<div><Table columns={col} dataSource={datac} pagination={false}/></div>}  
                  </div>  
              </div>           
              this.setState({value:reponse});  
            }
            else {
              const reponse = 
              <div >
                  <div className="searchResult notfound">
                    <span>{rep.err}</span><br/><br/>
                    <Button onClick={() => this.handleSend(courseNumber, rep.rep)} style={{color:"#21ba45",borderColor:"#21ba45"}}>Create proxy</Button>
                  </div>  
              </div>          
              this.setState({value:reponse});
            }
          }
          else {
            rep = rep.data.rst;
            const fullhumanize = moment.duration(rep.Duration, 'minutes');
            const H = Math.floor(fullhumanize.asHours());
            const M = fullhumanize._data.minutes;
            const duration = H+':'+('0'+M).slice(-2)+' hour(s)';
            const data = [{
              key: rep.Title,
              title: rep.Title,
              type: rep.TrainingSubType,
              Duration: duration,
              Descr : renderHTML(""+rep.Description+"")
            }];              
                          
            const reponse = 
              <div >
                  <div className="searchResult">
                    <Table columns={columns} dataSource={data} pagination={false}/>
                    <Button onClick={() => this.handleSend(courseNumber, rep)} style={{color:"#21ba45",borderColor:"#21ba45"}}>Valid</Button>
                  </div>  
              </div>           

            this.setState({value:reponse});                
          }
      }) 
    }
  }
  
  handleSend = (courseNumber, rep) => {
    const {name} = this.props.match.params;        
    this.storeDef = Source.getDef(name); 
    const item = { Icon: this.storeDef.defaultIcon || Config.defaultIcon, sid:name, del:true, ID:'n.' + new Date().getTime(),  ...this.storeDef.defaultItem };
    B.origin[item.ID] = JSON.stringify(item);
    delete item.del;
    // Description, Audience, Prerequisites and Objectives are retrieved from rep.Description 
    if (rep) B.formatCSOD(rep, item);  

    // If no named sections are found in the descripton, we just copy the full text from rep.Description
    if (rep && !item.Description && !item.Audience && !item.Prerequisites && !item.Objectives && rep.Description)
      item.Description = rep.Description; 

    // BV March 12 - Exclude CO courses 
    // BV - Match 18 - Exclude CX courses
    if (rep && courseNumber 
            && courseNumber!=="" 
            && !courseNumber.match('^CO_') 
            && !courseNumber.match('^CX_')
            && !courseNumber.match(/\/|&/) 
            && (rep.Title.includes(courseNumber) || courseNumber.startsWith("~")))  
      item.Url = 'https://go.nokialearn.com/cd/' + courseNumber;
    else if (rep)
      item.Url = 'https://nokialearn.csod.com/DeepLink/ProcessRedirect.aspx?module=lodetails&lo=' + rep.ObjectId;

    item.cn = courseNumber;
    if (rep) item.loid = rep.ObjectId;
    item.Wip = true;
    item.Status = 'Development';
    // console.log("workbench",item.Wip)
    const tmp = JSON.stringify(item);
    Ctl._push(tmp, item);
    Ctl.update(item); // so that it can be searched and stored in the unsaved collection
    wipC.save(item.ID);
    // wipC.unsave(item.ID);       
    this.props.history.push(this.props.location.pathname.replace('csod', 'item/'+item.ID));
    B.history.push({
      pathname: '/' + name + '/edit/' + item.ID,
      state: {name: name, id: item.ID, from: 'csod'}
    });
    //console.log(item);
  };

  //
  // SEARCH BASED ON FULL TEXT
  //

  inStoreMatches(elm) {
    const CN = elm.Customfields.find(i => i.Title==="Course Number")
    let courseNumber = CN ? CN.Value : ""
    
    // Ignore course number fields when too short (non-standard). Risking to make too many matches with 
    // random sequences is title and Url. Found case of course number is "S": 600++ matches with title and URL 
    if (courseNumber.length<5) courseNumber=""

    const inStoreList = Source.elts.filter(item => {
      let matchCN1 = (courseNumber !== "") && item && item.Url && item.Url.includes(courseNumber);
      let matchCN2 = (courseNumber !== "") && item && item.Title && item.Title.includes(courseNumber); 
      let matchTitle = item && item.Title && (item.Title === elm.Title); 
      let matchURL = item && item.Url && item.Url.includes(elm.ObjectId); 

      return (matchTitle && ((matchCN1 && matchCN2) || matchURL));
    });
    return inStoreList;
  }

  
  formatData(rep, inStore) {
    const fullhumanize = moment.duration(rep.Duration, 'minutes');
    const H = Math.floor(fullhumanize.asHours());
    const M = fullhumanize._data.minutes;
    const duration = H+':'+('0'+M).slice(-2)+' hour(s)';
    const CN = rep.Customfields.find(i => i.Title==="Course Number")
    const courseNumber = CN ? CN.Value : ""
    let data;
 
    if (inStore.length===0) { 
      data = {
        key: rep.Title,
        Title: rep.Title,
        Type: rep.TrainingSubType,
        Duration: duration,
        Provider: rep.Provider, 
        CN: courseNumber, 
        Action:  <Button onClick={() => this.handleSend(courseNumber, rep)} style={{color:"#21ba45",borderColor:"#21ba45"}}>Import</Button> ,
      }
    }
    else {
      data = {
        key: rep.Title,
        Title: rep.Title,
        Type: rep.TrainingSubType,
        Duration: duration,
        Provider: rep.Provider, 
        CN: courseNumber, 
        Action:  "Already imported",
      } 
    }
    return data              
  }

  isCNCompliant(elm) {
    const courseNumber = elm.Customfields.find(i => i.Title==="Course Number").Value;
    const comply = (courseNumber !== "") 
                        && elm.Title 
                        && elm.Title.endsWith(courseNumber)
                        && !elm.Title.startsWith(courseNumber); // avoiding CO CN or title in CN 
    return comply; 
  }

  isAuthorizedFormat(elm, format) {
    return !format.includes(elm.TrainingSubType)
  }

  searchBasedonFullText(searchText) {

      let message = "";
      const reponse = 
        <div style={{margin:0}}>
          <div id="loader"></div>
        </div>
      this.setState({value:reponse});                
     
      // csod.get(searchText, "all")
      csod({ query: {"rst: csod.get":""}, variables: { courseNumber: searchText, all: true }})
      .then( rep => {
 
        // FORMATING THE ANSWERS IN AN ARRAY
          console.log('dgb', rep)
          let listResults;
          if (rep.err && rep.err.statusCode===503) {
            listResults = [];
            message = message + "NLDH is not reachable (error 503). You can check your connection and NLDH availabilty. Eventually try again. "
          }
          else if (rep.err && rep.err.name==="RequestError") {
            listResults = [];
            message = message + "The search string includes unauthorized characters. "

          }
          else if (rep.error && rep.error.startsWith('Too many candidates')) {
            // Multiple elements
            listResults = JSON.parse(rep.error.replace('Too many candidates: ', ''));
            // message += "Too many candidates"
          }
          else if (rep.data) {
            // Single element
            listResults = [rep.data.rst];
          }
          else {
            // empty response
            listResults = [];
          }
          
          // console.log("Results (before filtering):", listResults);
          
          const csodListSize = listResults.length;
          if (csodListSize>24) 
            message = message + "Stopped after 25 matching courses. Be more specific in your search. "
          else
            message = message + csodListSize + ' matching course(s) found in NLDH. ';

          // FILTER THE ANSWERS TO ONLY AUTORIZED LO
          let requiredCNCompliance = Source.defs[this.props.match.params["name"]].restrictedImport;
          let listForbiddenFormats = Source.defs[this.props.match.params["name"]].listForbiddenImport;

          if (listForbiddenFormats && listForbiddenFormats.length!==0) message = message+" Filtering on forbidden types ("+ listForbiddenFormats + "). ";
          if (requiredCNCompliance) message = message + "Filtering on courses complying to EDU course numbering rules. "; 
          message = message + "Filtering out unavailable courses. ";

          listResults = listResults.filter(result => {
            let complianceOK = !requiredCNCompliance || this.isCNCompliant(result); 
            let authorizedFormat = this.isAuthorizedFormat(result, listForbiddenFormats);
            let isAvailable = result.Availabilities.length>0;
            return complianceOK && authorizedFormat && isAvailable;
          });  

          message = message + listResults.length + " candidate course(s).";

          // DISPLAYING THE ANSWERS
          if (listResults.length===0) {
            const reponse =
                <div className="messageBox">
                   {message}
                </div>              
            this.setState({value:reponse});
          }
          else {
            let data  = listResults.map(item => {                
              return this.formatData(item, this.inStoreMatches(item)) } )
              const reponse =
              <div >
                <div className="messageBox">
                   {message}
                </div>   
                <div key="searchResult" className="searchResult">
                  {<div><Table key="TableSearchResults" columns={colFullSearch} dataSource={data} pagination={false}/></div>}  
                </div>  
              </div>            
              this.setState({value:reponse});
          }
        }) 
  }

  showSearchFields() {
    let restrictedImport = Source.defs[this.props.match.params["name"]].restrictedImport
    
    if (!restrictedImport) return(
      <Search
                placeholder="Title search (case sensitive)"
                enterButton="Search"
                size="large"
                onSearch={value => this.searchBasedonFullText(value)}
      />
    )
    else return (
      <Search

                placeholder="NLDH Course Number"
                enterButton="Import"
                size="large"
                onSearch={value => this.searchBasedOnCN(value)}
      />
    ); 
  }



  render() {

    return(
    <div>
        <div id="editDimmer">
          <div><img src="" id="editDimmerImg" alt="Dimmer"/>
            <div id="editDimmerText"></div>
          </div>
        </div>
        <div className="head">
          <HeaderComponent {...this.props} data={this.storeDef}/>
        </div>
        <div className="store" style={{marginTop:"50px"}}>
          <Row type="flex" justify="center">
            <Col xs={22} sm={20} md={20} lg={18} xl={18}>   
               
                {this.showSearchFields()}
                
                {!this.state.value &&
                <div className="searchResult">
                    Import Results
                </div>
                }
                {this.state.value &&
                <div style={{marginTop:"16px"}}>
                    <div>
                    {this.state.value}
                    </div>                 
                </div>                 
                }                              
            </Col>
          </Row>
        </div>
        <FooterComponent props={this.props} data={this.storeDef}/>
      </div>)
  }
}
