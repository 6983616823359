import React  from 'react';
import * as Msal from 'msal';
import { appConfig } from './const';
import { notification, Modal } from 'antd';
import lz from 'lz-string';

const cloginKey = 'cf06bce7b2cb';

export const isLocalhost = !!(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

class AuthService {
  constructor() {
    this.applicationConfig = {
      ...appConfig
    };
    this.app = new Msal.UserAgentApplication(
      this.applicationConfig.clientID,
      this.applicationConfig.authority,
      this.acquireTokenRedirectCallBack,
      {
        redirectUri: isLocalhost ? "http://localhost:6767/" : 
          window.location.origin === 'https://backend.store.dyn.nesc.nokia.net' ? 
            window.location.origin + '/dev' : 
            window.location.origin,
        storeAuthStateInCookie: true,
        cacheLocation: "localStorage"
      }
    );
  }
  acquireTokenRedirectCallBack = (errorDesc, token, error, tokenType) => {
    if (tokenType === "access_token") {
      console.log(token)
      // callMSGraph(this.applicationConfig.graphEndpoint, token, graphAPICallback);
    } else {
      console.log("token type is:" + tokenType);
    }
  };

  _getToken = () => {
    return this.app.acquireTokenSilent(this.applicationConfig.graphScopes).then(
      accessToken => {
        this.delCLogin(); // one cannot be a customer and an employee
        return accessToken;
      },
      error => {
        return this.app
          .acquireTokenPopup(this.applicationConfig.graphScopes)
          .then(
            accessToken => {
              return accessToken;
            },
            err => {
              console.error(err);
            }
          );
      }
    );
  };

  login  = () => this.app.loginPopup(this.applicationConfig.graphScopes)
  logout = () => new Promise( (resolve, reject) => { 
    const customer = this.getCLogin(), self = this;
    console.log('cu', customer)
    if (this.getCLogin()) Modal.confirm({
      title:"Click OK to clear your data and disconnect",
      content:<div>
        <p>Name: {customer.name}</p>
        <p>Email: {customer.email}</p>
        <p>Company: {customer.company}</p>
        <p>City/Country: {customer.city}</p>
        <p>Position: {customer.position}</p>
        <p>Newsletter: {customer.newsletter ? 'Yes' : 'No'}</p>
      </div>,
      onOk() { self.delCLogin(); resolve(true); window.location.reload() }
    });
    else {
      this.app.logout();
      resolve(true);
    }});

  email = () => this.app.getUser() && this.app.getUser().displayableId

  name = (full) => {
    const name = this.email().split('@')[0].split('.');
    if (full) return name.join('.');
    if (name[name.length - 1].toLowerCase() === 'ext') name.pop();
    return name[name.length - 1];
  }

  sid = () => String(Math.random()).slice(2,14)
  
  getToken = () => new Promise( (resolve, reject) => {
    this._getToken().then( token => token ? resolve(token) : 
      reject(notification.error({
        message: 'Error',
        description: <div><p>You need to sign in!</p><p>Please click the sign in icon on the top bar to authenticate yourself.</p></div>,
        placement: 'bottomRight'
      })) )
  });
// gilles.gerlinger@nokia.com
  getPhoto = () => new Promise( (resolve, reject) => {
    this._getToken().then( token => {
      const headers = { "Authorization": `Bearer ${token}`, 'Content-Type': 'application/json', "Accept": "application/json" };
      // console.log('TOKEN', token, headers, appConfig.graphEndpoint + "/me/photo/$value")
      fetch(appConfig.graphEndpoint + "/me/photo/$value", { headers: headers })
      // fetch("https://graph.microsoft.com/v1.0/users/mohamed.eljaafari.ext@nokia.com/photo/$value", { headers: headers })
      .then( rep => {
        // console.log(rep, rep.status === '404')
        if (rep.status !== 200) reject(rep.status);
        else rep.blob().then(r => {
          const reader = new FileReader();
          reader.onload = (event) => resolve(event.target.result);
          reader.readAsDataURL(r);
        }).catch( e => reject(e) ) 
      })
      .catch( e => reject(e) );
    })
  });

  // for Customer login
  getCLogin = () => this.clogin ? this.clogin : window.localStorage[cloginKey] ? this.clogin = JSON.parse(lz.decompressFromBase64(window.localStorage[cloginKey])) : null
  setCLogin = (id) => this.clogin = window.localStorage[cloginKey] = lz.compressToBase64(JSON.stringify(id));
  delCLogin = () => { delete window.localStorage[cloginKey]; delete this.clogin; }

}

export default new AuthService();
