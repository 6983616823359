import React, {Component} from 'react';
import '../css/Store.css';
import Source from '../tools/data';
import HeaderComponent from './Header';
import FooterComponent from './Footer';
import { Tree, BackTop, Button } from 'antd';
import EditCtl from '../tools/editCtl';
import {Link} from 'react-router-dom';
import  B  from '../tools/back';
import AdvancedLoader from './AdvancedLoader'

const TreeNode = Tree.TreeNode;

export default class TreeView extends Component {
    state = { 
        isLoading: true,
        expanded: ['root']
    }

    UNSAFE_componentWillMount() {
        const {name} = this.props.match.params;
        this.storeDef = Source.getDef(name);
        Source.fetch(name).then( store => {
            this.store = store;
            this.setState({isLoading: false});
        });
    }

    renderTreeNodes = (data, parent) => {
        const {name} = this.props.match.params;
        if (data && typeof data[0] === 'string') {
            data = data.map( elem => this.store.getByID(elem) ).filter( item => item && !item.Wip );
        }
        
        return data.filter( item => parent.indexOf(item.ID) === -1 ).map( (item, index) => {
          if (item.Solutions) {
            if (item.Solutions.length === 1) return this.renderTreeNodes(item.Solutions, parent.concat([item.ID]));

            const title = <p>{item.Title}</p>
            return (
              <TreeNode title={title} key={item.Title}>
                {this.renderTreeNodes(item.Solutions, parent.concat([item.ID]))}
              </TreeNode>
            );
          }
     
        //   if (item.ID === 'n.1508250225262') return null;
          const linkTitle = <Link
                                className="link"
                                to={{
                                    pathname: `/${name}/item/${item.ID}`
                                }}
                                >{item.Title}</Link>
          return <TreeNode key={item.Title} title={linkTitle} />;
        });
    }

    collapseAll = () => {
        this.setState({expanded: B.expandedKeys = ['root']});
    }

    render(){

        EditCtl.editMode = false;

        if (this.state.isLoading) return (
            <div>
            <div className="head">
                <HeaderComponent {...this.props} data={this.storeDef}/>
            </div>
            <div className="loading">
                <AdvancedLoader />
            </div>
            </div>
        );
        // console.log(B.expandedKeys)

        B.set(this);

        return(
            <div>
                <div className="head">
                    <HeaderComponent {...this.props} data={this.storeDef}/>
                </div>
                <div className="store">
                    <BackTop />
                    <div className="paraph">
                        <h2>Tree View</h2>
                        <p>
                            This page shows the main store collections as a tree where you can navigate by expanding the little black arrows until you reach a Learning Object that you can click / open.
                        </p>
                    </div>
                    <Button type="primary" onClick={this.collapseAll} className="collapseB" ghost >Collapse All</Button>
                    <Tree
                        // showLine
                        // autoExpandParent={this.state.expanded}
                        // defaultExpandAll={this.state.expanded}
                        expandedKeys={B.expandedKeys}
                        onExpand={(expandedKeys, {expanded, node}) => {
                              B.expandedKeys = expandedKeys;
                              this.setState({expanded: expandedKeys});
                          }}
                    >
                        <TreeNode title={this.storeDef.title} key="root">
                            {this.renderTreeNodes(this.storeDef.tree, [])}
                        </TreeNode>
                        
                    </Tree>
                </div>
                <FooterComponent props={this.props} data={this.storeDef}/>
            </div>
            
        );
    }
}