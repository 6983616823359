import React, { Component } from "react";
import {FieldConnect} from 'react-components-form';
import {Switch} from 'antd';
import '../css/Edit.css';

const Toggle = FieldConnect(
  ({ name, checked, onChange, field, info, change }) => (
    <div className='editFlow'>
      <label className='editLabel'>{field}<br/>{info}</label>
      <div onClick={change} style={{display: "inline-block"}} >
        <Switch className="toggleWip" name={name} checked={checked} onChange={value => onChange(value)} />
      </div>
    </div>
  ))

export default class ToggleField extends Component {

  render(){
    return(
     <Toggle name={this.props.name} change={this.props.change} checked={this.props.checked} field={this.props.field} info={this.props.info} />
    )
  }
}
  