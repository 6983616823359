/*
  @author Gilles Gerlinger
  Copyright 2020. All rights reserved.
*/

import { wrap, unwrap, merge, Box } from "web_jsql"

const Authorization = '452a7601dbc6f2c38aa89e68bda8b603';
const backend = 'https://backend.store.dyn.nesc.nokia.net';
const options = { method: 'POST', headers: { 'content-type': 'application/json' } }
const uid = () => Math.random().toString().substring(2, 10); // impure
const xwrap = x => wrap(x, Authorization)
const xtext = x => x.text()

const oneforall = r => (...args) => Box(JSON.stringify({ method:r.method, args, id:`${r.uid}-${++r.count.v}` }))
    .map(xwrap) // returns signature and compressed body
    .map(JSON.parse)
    .map( x => merge(options, { body: x.body, headers: merge(options.headers, x.headers) }) ) // merge with existing headers
    .map( x => fetch(`${r.uri}/${r.Class}`, x) ) // trigger the API
    .map(xtext) // decode jsql response
    .map(unwrap) // decompress
    .map(JSON.parse)
      // .log('<<')
    .unwrap() // out of the Box

export default (Class, methods, uri = backend, ctx = { uid:uid(), count:{ v:0 } }) =>
  Object.fromEntries([methods].flat().map( method => [method, oneforall({ Class, method, uri, ...ctx })] ))
