import React, { Component } from "react";
import {FieldConnect} from 'react-components-form';
import DatePicker from 'react-datepicker';
import '../css/Edit.css';
import "react-datepicker/dist/react-datepicker.css";


const Date = FieldConnect(
    ({ name, selected, onChange, field, info }) =>{
        // console.log(selected ? new window.Date(selected) : new window.Date())
        return (
            <div className='editFlow'>
            <label className='editLabel'>{field}<br/>{info}</label>
            <DatePicker className="dateField" name={name} selected={selected ? new window.Date(selected) : new window.Date()} onChange={value => onChange(value)} />
            </div>
        );
})

export default class DateField extends Component {

  render(){
    return(
     <Date name={this.props.name} selected={this.props.selected} field={this.props.field} info={this.props.info} />
    )
  }
}