import 'react-app-polyfill/ie11';
// FOR IE11
// eslint-disable-next-line
import poly from './util/polyfill'; // DO NOT REMOVE
import 'core-js/fn/reflect';
import 'core-js/fn/symbol/iterator.js';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App/>, document.getElementById('root'));
// registerServiceWorker();
