import React, { Component } from 'react';
import AdvancedLoader from './AdvancedLoader'
import '../css/Store.css';
import Source from '../tools/data';
import FooterComponent from './Footer';
import HeaderComponent from './Header'
import { BackTop, Button, Table, Select, Input } from 'antd';
import { Redirect } from 'react-router-dom';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import {saveAs} from 'file-saver';
import '../css/CalendarComponent.css'
// import dates from './utils/dates'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'antd/dist/antd.css';  // or 'antd/dist/antd.less'

const localizer = BigCalendar.momentLocalizer(moment); // or globalizeLocalizer

const Option = Select.Option;
const Search = Input.Search;

export default class CalendarComponent extends Component {
  state = { 
    isLoading: true,
    data: [],
    dataList: [],
    calendar: true,
    week: moment().week(),
    year: moment().year(),
    redirect: false,
  };
  
  tabDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  data = [];
  dataCountry = [];

  columns = (day) => [{
    title: this.tabDays[day] + ", " + moment().year(this.state.year).week(this.state.week).subtract(moment().day(), "days").add(day, "days").format("MMM DD - YYYY"),
    children: [{
      //title: 'Training Title',
      dataIndex: 'Training Title',
      key: 'title',
      width: 480,
    }, {
      //title: 'Reference',
      dataIndex: 'Reference',
      key: 'ref',
    }, {
      //title: 'ILT Facility Time Zone',
      dataIndex: 'FacilityTimeZone',
      key: 'zone',
    }, {
      //title: 'Total Seats Available',
      dataIndex: 'Total Seats Available',
      key: 'seats',
    }],
  }];
 
  UNSAFE_componentWillMount() {
    const {name} = this.props.match.params;
    this.storeDef = Source.getDef(name);
    if(this.storeDef.hasCalendar){
      Source.getCalendar(name)
      .then( calendar => {
        // console.log('calendar', calendar)
        let tab = [];
        let tabList = [];
        this.original = [];
        // this.data = calendar.map(item => { return {...item} });
        calendar.forEach(elt => {
          this.original.push(elt);
          if(this.dataCountry.indexOf(elt["ILT Facility Country"]) === -1) this.dataCountry.push(elt["ILT Facility Country"]);
          this.make(elt, tabList, tab);
        });
        this.dataCountry = this.dataCountry.sort( (a,b) => a < b ? -1 : 1);
        this.setState({isLoading: false, data: tab, dataList: tabList});
        // console.log(tabList)
      });
    }else{
      this.setState({redirect: true});
    }
  }

  make = (eltSafe, tabList, tab) => {
    const elt = {...eltSafe}
    if (moment().isAfter(elt.tzEnd)) return; // discard past dates

    elt['key'] = elt['Training Object ID'];
    if(elt["Offering Type"] === "Open" && elt["OU Training Availability"].startsWith("Availability_EXT:  Customer")){
      elt.Reference = 'Locator Number: ' + elt['Training Locator Number']; //elt["Training Title"].split("|")[1];
      elt["Training Title"] = <a href={"https://nokialearn.csod.com/DeepLink/ProcessRedirect.aspx?module=lodetails&lo="+elt["Training Object ID"]} target='_blank' rel="noopener noreferrer" className="titleLink" >{elt["Training Title"].split("|")[0]}</a>;
      // elt["Training End Date"] = elt.tzEnd;
      // elt["Training Start Date"] = elt.tzStart;
      elt["Total Seats Available"] = "Seats: " + elt["Total Seats Available"];
      tab.push({...elt});
      elt["Training Title"] = <div>{elt["Training Title"]}<br/><br/>{elt["Reference"]}</div>
      elt.Reference = <div>Start: {elt.tzStart}<br/><br/>End: {elt.tzEnd}</div>
      // const city = elt["ILT Facility City"];
      elt.FacilityTimeZone = <div>Location: {elt["ILT Facility Country"]} - {elt['ILT Facility Name']}<br/><br/>Time zone: {elt["ILT Facility TIme Zone"]}</div>
      elt.tzStart = moment(elt.tzStart);
      elt.tzEnd = moment(elt.tzEnd);
      let ref = moment(elt.tzStart);
      while(ref.isSameOrBefore(elt.tzEnd)){
        if (!tabList[ref.year()]) tabList[ref.year()] = [];
        if (!tabList[ref.year()][ref.week()]) tabList[ref.year()][ref.week()] = [];
        if (!tabList[ref.year()][ref.week()][ref.day()]) tabList[ref.year()][ref.week()][ref.day()] = [];
        tabList[ref.year()][ref.week()][ref.day()].push(elt);
        ref.add(1, 'days');
      }
    }
  }

  remake = (value, country) => {
    let tab = [];
    let tabList = [];
    this.original.forEach(elt => {
      if(country){
        if((value === "All Trainings") || (value === "All Virtual Trainings" && elt["ILT Facility Name"].startsWith("Virtual")) || (elt["ILT Facility Country"] === value)){
          // console.log('elt', {...elt})
          this.make(elt, tabList, tab);
        }
      }else{
        value = value.toLowerCase();
        if((elt["Training Title"].toLowerCase().indexOf(value) >= 0) || (elt.tzStart.toLowerCase().indexOf(value) >= 0) || (elt.tzEnd.toLowerCase().indexOf(value) >= 0) || (elt["ILT Facility TIme Zone"].toLowerCase().indexOf(value) >= 0) || (elt["Total Seats Available"].toLowerCase().indexOf(value) >= 0) || (value === "")) 
          this.make(elt, tabList, tab);
      }
    });
    this.setState({data: tab, dataList: tabList});
  }
  
  download = () => {
    let downCal = "\uFEFF\"sep=~\"\nTitle~Url~Location~Country~Locator Number~Remaining Seats~Start Date~End Date~Time Zone~Language~Provider\n";
    // console.log('dataList', this.state.week, this.state.dataList)
    // if (!this.state.dataList[this.state.week]) return;
    this.state.dataList.forEach( year => {
      if (year) year.forEach( week => {
        if (week) week.forEach( tab => {
          tab.forEach(elt => {
              downCal += elt['Training Title'].props.children[0].props.children + "~";
              downCal += elt['Training Title'].props.children[0].props.href + "~";
              // downCal += elt['Training Location'] + "~";
              downCal += elt['ILT Facility Name'] + "~";
              downCal += elt['ILT Facility Country'] + "~";
              downCal += elt['Training Locator Number'] + "~";
              downCal += elt['Total Seats Available'] + "~";
              downCal += elt.tzStart.format('llll') + "~";
              downCal += elt.tzEnd.format('llll') + "~";
              downCal += elt['ILT Facility TIme Zone'] + "~";
              downCal += elt['Language'] + "~";
              downCal += elt['Training Provider'] + "\n";
              // console.log(downCal)
              // console.log(elt['Training Location'])
          });
        })
      })
  });
  const buffer = Buffer.from(downCal);
  downCal = buffer.toString('utf8'); // ascii seems to work
  saveAs(new Blob([downCal], {type: "data:text/csv;charset=utf-8"}), "Nokia Customer Store Calendar.csv");

  // m=moment.tz("09/15/2019 08:30 PM", "MM/DD/YYYY hh:mm a", "America/Los_Angeles")
  // m.tz("Asia/Kolkata")
}

render() {

    // let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

    const {name} = this.props.match.params;
    // console.log(this.state);
    if(this.state.redirect) return <Redirect to={'/'+name} />

    // console.log('isloading', this.state.isLoading, config)
    if (this.state.isLoading) return (
      <div className="container">
        <HeaderComponent {...this.props} data={this.storeDef}/>
        <div className="loading">
          <AdvancedLoader />
        </div>
      </div>
    );

    return (
      <div className="container">
        {/* <div className="filterbar">
          {title}
        </div> */}
        <div className="head">
            <HeaderComponent {...this.props} data={this.storeDef}/>
        </div>
        <div className="table">
          <BackTop />
          <Button type="primary" className="collapseB calendarCollapseB" onClick={() => {this.setState({calendar: !this.state.calendar})}} ghost >{this.state.calendar ? "List" : "Calendar"}</Button>
          <Select
            showSearch
            style={{ width: 230 }}
            placeholder="Select a Country"
            optionFilterProp="children"
            onChange={(value) => {this.remake(value, true)}}
            // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
            <Option value="All Trainings">All Trainings</Option>
            <Option value="All Virtual Trainings">All Virtual Trainings</Option>
            {this.dataCountry.map((country, index) => <Option value={country} key={index}>{country}</Option>)}
          </Select>

            <Button type="primary" className="collapseB calendarCollapseB pullRight" onClick={this.download} ghost >Download</Button>
          <Search
            placeholder="Search..."
            onSearch={value => {this.remake(value, false)}}
            style={{ width: 240 }}
            className = "pullRight"
            // allowClear
          />

          { !this.state.calendar && <div><Button type="primary" className="collapseB calendarCollapseB" onClick={() => {
            this.setState({week: this.state.week === 1 ? 52 : this.state.week-1, year: this.state.week === 1 ? this.state.year-1 : this.state.year});
          }} ghost >{"< Back"}</Button>
          <Button type="primary" className="collapseB calendarCollapseB" onClick={() => {this.setState({week: moment().week(), year:moment().year()})}} ghost >Today</Button>
          <Button type="primary" className="collapseB calendarCollapseB" onClick={() => {
            // console.log(this.state.week, this.state.year)
            this.setState({week: this.state.week === 52 ? 1 : this.state.week+1, year:this.state.week===52 ? this.state.year+1 : this.state.year});
          }} ghost >{"Next >"}</Button>
          <div className="week pullRight" >{moment().week(this.state.week).subtract(moment().day(), "days").format("MMM DD")} - {moment().week(this.state.week).add(6-moment().day(), "days").format("MMM DD")}, {moment().year(this.state.year).week(this.state.week).add(5, "days").format("YYYY")}</div>
          </div>}
          
          { 
            this.state.calendar ?
            <div className="calendar">
                <BigCalendar
                    popup
                    localizer={localizer}
                    events={this.state.data}
                    // views={allViews}
                    startAccessor="tzStart"
                    endAccessor="tzEnd"
                    titleAccessor="Training Title"
                    step={60}
                    showMultiDayTimes
                    // max={dates.add(dates.endOf(new Date(2015, 17, 1), 'day'), -1, 'hours')}
                    views={['month']}
                    className="calendar"
                    // onRangeChange={(evt) => console.log('evt', evt)}
                />
            </div> : this.state.dataList[this.state.year][this.state.week] ? this.state.dataList[this.state.year][this.state.week].map( (tab, index) => {
              return <Table dataSource={tab} columns={this.columns(index)} pagination={false} key={index}/>;
            }) : <Table dataSource={null} />
          }
        </div>
        <FooterComponent props={this.props} data={this.storeDef}/>
      </div>
    );
  }
}
