import '../css/NokiaCoverflow.css';

var Coverflow = require('react-coverflow')

export default class NokiaCoverflow extends Coverflow {

    render() {
        // console.log(this.state.current)
        //this.props.currentSlide(this.state.current)
        return super.render()

    }
}
