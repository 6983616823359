/*
  @author Félix Fuin - Guillaume Rachet
  Copyright Nokia 2017-2018. All rights reserved.
*/

import React, {Component} from 'react';
import renderHTML from 'react-render-html';
import {Checkbox, Modal} from 'antd'
import 'antd/dist/antd.css';

export default class ModalWindow extends Component {

  render() {
    if (!this.props.dontclose && this.props.open) setTimeout(() => this.props.closeModal(), 20000);

    return (

      <Modal
        destroyOnClose={true}
        title={this.props.title}
        visible={this.props.open}
        onCancel={this.props.closeModal}
        closable
        width={990}
        maskClosable
        zIndex={10000000}
        centered
        maskStyle={{backgroundColor: 'rgba(0, 0, 0, 0.55)'}}
        footer={this.props.check ?
          <Checkbox onChange={() => this.props.closeModal(true, this.props.id)}>OK, got it!</Checkbox> : null}
      >
        {renderHTML("<div class='modalText'>" + this.props.html + "</div>")}
      </Modal>
    )
  }
}