/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, {Component} from 'react';
import AdvancedLoader from './AdvancedLoader'

import '../css/Search.css';
import Source from '../tools/data';
import HeaderComponent from './Header';
import Thumbnail from './Thumbnail';
import B from '../tools/back';
import EditCtl from '../tools/editCtl';
import {Col, Icon, Row,BackTop} from 'antd';
import 'antd/dist/antd.css';
import FooterComponent from "./Footer";
import BottomScrollListener from 'react-bottom-scroll-listener'
import { FaChevronRight } from 'react-icons/fa';

const addLim = 10;

export default class Search extends Component {

  state = {isLoading: true, lim: 10};
  searchInput = (param) => {
    this.search = Source.filter(this.props.match.params.name, param);
    this.setState({lim: addLim});
    clearTimeout(this.sendGA);
    this.sendGA = setTimeout(() => this.sendAnalytics(param), 1800);
  }

  sendAnalytics = (param) => {
    // with wip
    //this.search.filter(item => item.Icon && (EditCtl.editMode || !item.Wip)).length === 0
    if (this.search.length === 0) {
      B.pageview('/' + this.props.match.params.name + '/index.html?fail=' + param);
    } else {
      B.pageview('/' + this.props.match.params.name + '/index.html?search=' + param);
    }
  }

  loadMore = () => {
    this.setState({lim: this.state.lim + 10});
    this.thumbnails = this.map();
  }

  UNSAFE_componentWillMount() {
    const {name} = this.props.match.params;
    Source.getSync(name).then(store => {
      B.back = true;
      // this.searchInput(text)
      this.setState({isLoading: false});
    });
    this.storeDef = Source.getDef(name);
    B.ga(this.storeDef.analytics);
  }

  map() {
    let ret = this.search.filter(item => item.Icon && (EditCtl.editMode || !item.Wip))
      .map((item, index2) => {
        return <div key={index2} style={{marginTop: 20, marginBottom: 20}}>
          <Thumbnail
            props={this.props}
            data={item}
            store={this.storeDef}/></div>
      });

    if (ret.length > this.state.lim + 1) {
      ret = ret.slice(0, this.state.lim);
      return (
        <Row type="flex" gutter={24}>
          {ret}
         <Col span={24}>
            <div className="loadMore" onClick={this.loadMore}>See more results... <FaChevronRight style={{fontSize:'14px'}} /></div>
          </Col>
        </Row>
      );
    }
    if (ret.length === 0)
      return (

        <div className="icons-list" id="iconSearchP">
          <Icon type="file-search" theme="outlined" id="iconSearchNF"/>
          <h1><p>Oops !</p></h1>
          <p>Sorry, there are no results for "{this.props.match.params.text}"</p>
        </div>

      );

    return <Row gutter={16} type="flex" justify={'start'} align="top">{ret}</Row>;

  }

  render() {
    B.set(this);

    if (this.state.isLoading) {
      return (
        <div>
          <div className="head">
            <HeaderComponent searchInput={this.searchInput} {...this.props} data={this.storeDef}/>
          </div>
          <div className="loading">
            <AdvancedLoader />
          </div>
        </div>
      );
    }

    const {name, text} = this.props.match.params;
    if (!this.search) this.search = Source.filter(name, text);
    // console.log('search', this.search)
    this.thumbnails = this.map();

    return (
      <div className={'minHeight'}>
        <div id="editDimmer">
          <div><img src="" id="editDimmerImg" alt="Dimmer"/>
            <div id="editDimmerText"></div>
          </div>
        </div>
        <div className="head">
          <HeaderComponent searchInput={this.searchInput} {...this.props} data={this.storeDef}/>
        </div>
        <div className="store">
          <div className={'thumbnails'}>
          <Row type="flex" justify="center">
            <Col xs={21} sm={18} md={21} lg={20} xl={18}>
              {this.thumbnails}</Col>
          </Row>
          </div>
        </div>
        <FooterComponent props={this.props} data={this.storeDef}/>
        <BackTop visibilityHeight={1500}/>
        <BottomScrollListener onBottom={this.loadMore} offset={1000}/>
      </div>
    );
  }
}
