/*
  @author Benoit Vaillant
*/

import React, {Component} from 'react';
import B from '../tools/back.js';
import {Tooltip, Tag, Popover, Rate } from 'antd'; 
import authService from '../util/authService';
import renderHTML from 'react-render-html';

const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December"];
export default class ViewsAndLikes extends Component {

  state = {
    currentUser: 'not yet set',
    nbViews: '...',
    pastViews:"", 
    showLikes: true, 
    nbLikes: 0, 
    alreadyLiked: false,
    currentItem:false,
    currentStore: false,
    popularity: 0
  }

  UNSAFE_componentWillMount() {
    if (this.state.currentUser==='not yet set') this.setState({currentUser: authService.email()}) 

    if (this.state.currentItem !== this.props.item.ID || this.state.currentStore !== this.props.item.sid) {
      B.getLikes(this.props.item.sid, this.props.item.ID).then( rep => {
        this.setState({currentItem: this.props.item.ID, currentStore: this.props.item.sid, showLikes: true, nbLikes: rep.length, alreadyLiked: rep.includes(this.state.currentUser)});  
      }).catch(() => {
        this.setState({showLikes: false})
      });
      this.calculateViews();
      this.calcPopularity()
    }
  }

  calcPopularity() {
    B.getPopularity(this.props.item.sid, this.props.item.ID).then(p => this.setState({popularity: p}))
  }

  calculateViews() {
    let store = this.props.item.sid;
    let item = this.props.item.ID;
    let valueString = ""; 

    B.getViews(store, item).then( rep => {
      const total = rep.total;
      //delete rep.total;
      for (let [month, views] of Object.entries(rep)) {
        if (month!=='total') 
          valueString = valueString + monthNames[month.substring(5, 7)-1] +" " + month.substring(0, 4) + ": " + views + " <br />"
      } 
      valueString = "<strong>Total Views:</strong>   " + total + "<br />" + valueString;
  
      this.setState({nbViews: total, pastViews: valueString});
    }).catch(() => {
        valueString = "Unable to show number of views, please reload the page.";
        this.setState({nbViews: '?', pastViews: valueString});
      });
  }

  showViews() {
    return (
      <Popover content={renderHTML(this.state.pastViews)} placement="right">
        <Tag color="geekblue">   👁   {this.state.nbViews}</Tag> 
      </ Popover >
    )
  }


  // Likes management

  swapLike() {
    let store = this.props.item.sid
    let item = this.props.item.ID

    if (this.state.alreadyLiked) {
        B.unlike(store, item, this.state.currentUser)
        this.setState({
          nbLikes:  this.state.nbLikes-1, 
          alreadyLiked: false}) 
    }
    else {
        B.like(store, item, this.state.currentUser)      
        this.setState({
          nbLikes:  this.state.nbLikes+1, 
          alreadyLiked: true}); 
    }
  }
  

  showLikes() {
    let color = (this.state.alreadyLiked)? 'red' : 'geekblue'; 
    let heart = (this.state.alreadyLiked)? '🧡' : '💛'; 

    if (this.state.showLikes)
      return (
          <Tag onClick={() => this.swapLike()} color={color}> 
               {heart}   {this.state.nbLikes}
          </Tag> 
      )
    else return(
      <Popover content="Unable to show number of likes, please reload the page." placement="right">
        <Tag color='geekblue'> {heart} ?</Tag> 
      </Popover>
    )

  }

  showOwner() {
    let owner = this.props.item.Owner; 
    if (owner && owner.trim().endsWith("@nokia.com")) 
      return (
          <Tooltip title={owner} >
            <a href={"mailto:"
                        + owner 
                        + "?subject=Feedback on Learning Store page '" 
                        + this.props.item.Title 
                        + "'" } ><Tag color='geekblue'>    🖂   </Tag></a> 
          </Tooltip>
      )
  }


  showPopularity() {

    return <span>
              <Rate tooltips="Popularity is calculated based on number of 'views' and 'likes'" value={this.state.popularity} />
              ( 👁 {this.state.nbViews})
            </span>
  }

  render() {    

    if (this.state.currentUser && this.state.currentUser.endsWith("@nokia.com")) {
      if (this.props.popularity) {
        if (this.props.item.Url && this.props.item.Url.includes("nokialearn")) {
          return <span style={{fontSize: 'small', fontStyle: 'italic', marginLeft: '15px'}}>See rating and read reviews in NLDH</span>
        }
        return <span style={this.props.style}>{this.showPopularity()} </span>
      }

      return (
        <span style={this.props.style}>
          {this.showViews()}
          {this.showLikes()}
          {!this.props.noOwner? this.showOwner() : null}
        </span>
    )
    }
    else return null; 
  }

}