import React, {Component} from 'react';
import { ScaleLoader as Loader } from 'halogenium'
import { Card } from 'antd';

export default class AdvancedLoader extends Component {

  state = {
    lsReachable: false,
    internetReachable: false,
    testCompleted: false
  }
  

  checkLSStatus() {
    fetch('https://learningstore.nokia.com', {mode: 'no-cors'})
    .then(()=> this.setState({lsReachable: true, internetReachable: true, testCompleted: true}))
    .catch(() => fetch('https://nokia.com', {mode: 'no-cors'})
                  .then(()=> this.setState({lsReachable: false, internetReachable: true, testCompleted: true}))
                  .catch(()=> this.setState({lsReachable: false, internetReachable: false, testCompleted: true})))
    setTimeout(() => this.setState({testCompleted: false}), 30000)
  }

  noInternetMessage() {
    return <Card title="Cannot connect to Internet" style={{ width:'50%', marginTop:'200px', marginLeft:'auto', marginRight:'auto' }}>
       <p style={{marginBottom:'20px'}}>Trying again in a few seconds...</p>
       <Loader color="#124191" size="25px" margin="4px"/>

      <p style={{marginTop:'20px'}}>If this message persists, verify your internet connection and reload the Learning Store.</p>
      </Card>
  }

  noLearningStoreMessage() {
    return <Card title='Cannot connect Nokia Learning Store' style={{ width:'50%', marginTop:'200px', marginLeft:'auto', marginRight:'auto' }}>
        <p style={{marginBottom:'20px'}}>Trying again in a few seconds...</p>
       <Loader color="#124191" size="25px" margin="4px"/>
      <p style={{marginTop:'20px'}}>If taking more time than expected, you can reload the Learning Store from the server with 'ctrl+F5'</p>
      <p>Nokia employees can verify unavailability notices published in <a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiI3NTg5MzM2In0">Learning Store Yammer group</a>. </p>
      <p><br />Please, wait 10 min. and try again before reporting the problem.</p>
    </Card>
  }

  loadingMessage() {
    return <Card title="Loading Nokia Learning Store..." style={{ width:'50%', marginTop:'200px', marginLeft:'auto', marginRight:'auto' }} >
       <Loader color="#124191" size="25px" margin="4px"/>
       <p style={{marginTop:'20px'}}>If taking more time than expected, you can reload the Learning Store from the server with 'ctrl+F5'.
      </p>
      </Card>
  }  

  render() {
     if (!this.state.testCompleted) this.checkLSStatus()

    if (this.state.testCompleted && !this.state.internetReachable)  return this.noInternetMessage()
    else if (this.state.testCompleted && !this.state.lsReachable)  return this.noLearningStoreMessage()
    else return  this.loadingMessage()   
  }

}