import React, { Component } from 'react';
import { FieldConnect } from 'react-components-form';
import { Tag } from 'antd';
import '../css/Edit.css';

const { CheckableTag } = Tag;

class MyTag extends Component {
    state = { checked: (this.props.selected.indexOf(this.props.segment) > -1) };
  
    handleChange = checked => {
        if(checked === true) this.props.selected.push(this.props.segment);
        else this.props.selected.splice(this.props.selected.indexOf(this.props.segment), 1);
        this.props.onChange(this.props.selected);
        this.setState({ checked });
    };
  
    render() {
      return (
        <CheckableTag {...this.props} checked={this.state.checked} onChange={this.handleChange} />
      );
    }
  }

const TagList = FieldConnect(({name, data, selected, onChange, field, info}) => {

    if(!selected) selected = [];

    return(
        <div className='editFlow'>
            <label className='editLabel'>{field}<br/>{info}</label>
            <div className="tagList">
                {data.map((segment, index) => segment && <MyTag name={name} key={index} selected={selected} onChange={onChange} segment={segment}>{segment}</MyTag>)}
            </div>
        </div>
    );
});

export default class TagListField extends Component {

    render(){

        return(
            <TagList name={this.props.name} data={this.props.data} selected={this.props.selected} field={this.props.field} info={this.props.info} />
        );
    }
}