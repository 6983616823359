/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Source from '../tools/data'
import { Icon } from 'antd'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import lz from 'lz-string'
import authService from '../util/authService'
import B from '../tools/back'
import ViewsAndLikes from '../components/viewsAndLikes'

import '../css/Thumbnail.css'

const Formats = [
  'Book',
  'Certification',
  'Curriculum',
  'Event',
  'Event - Classroom',
  'Event - Virtual Class',
  'External Resources',
  'External Training',
  'Library',
  'Material',
  'Material - SWAY',
  'Online Class',
  'Online Class - Self Assessment',
  'Program',
  'Test',
  'Collection',
  'Topic Page',
  'Video',
]

const formatIcons = Formats.map((icon) => icon.replace(/ /g, ''))
const formatMaps = Formats.map((icon) => icon.toLowerCase())

const urlDelim = '>>'

export default class Thumbnail extends Component {
  stripHTML = (string) =>
    string
      ? string
          .replace(/<[^>]*>?/gm, ' ')
          .replace(/&nbsp;/g, ' ')
          .replace(/&amp;/g, '&')
      : ''

  trunc = (data, n) => {
    return data.length > n ? data.substr(0, n) + '...' : data
  }

  superImage = (src, data) => {
    return (
      <div
        className="thumbnailLogo"
        style={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: '2px',
        }}
      >
        <LazyLoadImage
          style={{
            objectFit: 'fill',
            height: 'unset',
            position: 'absolute',
            minWidth: '120%',
            minHeight: '120%',
            top: 0,
            left: 0,
            filter: 'blur(5px)',
          }}
          effect="blur"
          src={src}
          alt=""
          className={data.Wip ? 'imgBlur' : ''}
        />
        <LazyLoadImage
          style={{
            position: 'relative',
            objectFit: 'cover',
            backgroundColor: 'white',
            borderTopLeftRadius: 'unset',
            borderTopRightRadius: 'unset',
          }}
          effect="blur"
          src={src}
          alt=""
          className={data.Wip ? 'imgBlur' : ''}
        />
      </div>
    )
  }

  loFormat = (data) => {
    let subT = ''

    if (data.Format)
      subT +=
        data.Format.replace(/<(?:.|\n)*?>/gm, '') +
        (data.Wip ? ' (' + data.Status + ')' : '')
    else if (data.Solutions)
      subT += 'Collection' + (data.Wip ? ' (' + data.Status + ')' : '')
    else if (data.Sections)
      subT += 'Topic Page' + (data.Wip ? ' (' + data.Status + ')' : '')
    else subT += 'Learning Object' + (data.Wip ? ' (' + data.Status + ')' : '')

    return subT
  }

  formatIcon = (data) => {
    // const defaultIcon = `${process.env.PUBLIC_URL}/img/CSOD-Icon-Library.png`
    const defaultIcon = ''
    if (data.Format) {
      const format = data.Format.toLowerCase().trim()
      const iconIndex = formatMaps.findIndex((icon) => icon === format)
      return iconIndex > -1
        ? `${process.env.PUBLIC_URL}/img/Icon-${formatIcons[iconIndex]}.png`
        : defaultIcon
    }
    if (data.Solutions || data.Sections) return defaultIcon

    return null
  }

  reformatedDuration = (duration,format) => {
    if (format!=='Event') return duration
    
    let correct = duration.match(/[0-9]+:[0-9][0-9] hour\(s\)/) 
    if (correct) {
        let breakdown = correct[0].match(/[0-9]+/g)
        let H = parseInt(breakdown[0])
        let M = parseInt(breakdown[1])
        if (H%7===0 && M===0)
            return Math.floor(H/7).toString()+ " day(s)"
        if (H%7===3 && M===30)
            return Math.floor(H/7).toString() +",5 day(s)"
        else return duration
    }
    return duration
}



  subTitle = (format, duration) => {
    if (this.props.store.noBottomLineThumbnails) return ""
    let subt = this.trunc(format, 50)
    if (subt==="Learning Object") subt=""

    let dur = duration ? this.stripHTML(duration) : ''
    if (dur ==='N/A' || dur==="n/a") dur = ''
    if (dur.length > 16) dur=""
   
    dur =this.reformatedDuration(dur, format)
    return (subt.length>0 && dur.length>0 ) ? subt + " | " + dur : subt+dur 
  }

  thumbnailFace = (data, store, src, title, recommended) => {
    //let pathName = `/${store.id}/item/${data.ID}`
    //if (recommended) pathName += '/recommended'

    title = this.trunc(title, this.props.currentUser ? 30 : 90)

    // let greyTitle = this.trunc(this.loFormat(data), 50)
    // greyTitle += data.Duration
    //   ? ' | ' + this.trunc(this.stripHTML(data.Duration), 16)
    //   : ''

    return (
      <div className={data.Wip ? 'thumbnailFace wip' : 'thumbnailFace'}>
        <div title={data.Title}>
          <div className="thumbnailLogo">{this.superImage(src, data)}</div>
          <div className="thumbnailTitle">{title}</div>
          {this.props.currentUser ? (
            <ViewsAndLikes
              item={data}
              popularity
              style={{
                paddingLeft: '10px',
                position: 'absolute',
                bottom: '45px',
                fontSize: '13px',
              }}
            />
          ) : null}
          <div className="thumbnailGreyTitle">
            {data.NokiaLearn && <LazyLoadImage
              style={{
                height: 40,
                width: 40,
                position: 'fixed',
                left: 4,
                bottom: 0,
              }}
              src="/img/app-icon_learn_color-png.png"
              alt="Available in Learn App"
            />}
            {this.subTitle(this.loFormat(data),data.Duration)}
          </div>
        </div>
      </div>
    )
  }

  thumbnailBack = (data, store, title, url, target, recommended) => {
    let pathName = `/${store.id}/item/${data.ID}`
    if (recommended) pathName += '/recommended'

    // let greyTitle = this.trunc(this.loFormat(data), 50)
    // greyTitle += data.Duration
    //   ? ' | ' + this.trunc(this.stripHTML(data.Duration), 16)
    //   : ''

    return (
      <div className={data.Wip ? 'thumbnailBack wip' : 'thumbnailBack'}>
        <Link to={{ pathname: data.del ? B.pathname : pathName }}>
          <div style={{ height: '250px' }}>
            <div className="thumbnailTitle">{title}</div>
            {data.Description ? (
              <div className="thumbnailDescription">
                {this.trunc(this.stripHTML(data.Description), 160)}
              </div>
            ) : null}
          </div>
        </Link>

        {data.NokiaLearn && (
          <a
            href={data.NokiaLearn}
            target={target}
            className={'NokiaLearnLaunchText'}
            rel="noopener noreferrer"
            onClick={(event) => {
              // console.log('Click')
              B.pageview('/' + store.id + '/index.html#?launch=' + data.ID)
              B.csOpen(store.id, data.ID)
            }}
          >
            <div>
              Nokia Learn
              <Icon type="right" theme="outlined" />
            </div>
          </a>
        )}

        {url[0] && (
          <a
            href={url[0]}
            target={target}
            className={'thumbnailLaunchText'}
            rel="noopener noreferrer"
            onClick={(event) => {
              // console.log('Click')
              B.pageview('/' + store.id + '/index.html#?launch=' + data.ID)
              B.csOpen(store.id, data.ID)
            }}
          >
            <div>
              Open
              <Icon type="right" theme="outlined" />
            </div>
          </a>
        )}

        <div className="thumbnailGreyTitle">{this.subTitle(this.loFormat(data),data.Duration)}</div>
      </div>
    )
  }

  render() {
    let data = this.props.data
    let store = this.props.store
    let recommended = this.props.recommended
    const url = data.Url ? data.Url.split(urlDelim) : ['']
    let target = '_blank'
    if (url[0].startsWith('login ')) {
      if (authService.app.getUser() || authService.getCLogin()) {
        url[0] = url[0].slice(6)
      } else {
        url[0] = `/${store.id}/login/${data.ID}/${lz.compressToBase64(
          url[0].slice(6),
        )}`
        target = '_self'
      }
    }

    let title = Source.format(data.Title)
    // if (title.length > lim) {
    //   title = title.substr(0, lim) + "...";
    // }
    store.url = store.url || '.'
    store.url = store.url.replace('https://learningstore.nokia.com', B.server)

    let src = Source.decodeIcon(data, store.url)

    return (
      <div className="thumbnailHolder">
        <div className="thumbnail">
          {this.thumbnailFace(data, store, src, title, recommended)}
          {this.thumbnailBack(data, store, title, url, target, recommended)}
        </div>
      </div>
    )
  }
}
