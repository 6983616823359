import React from 'react';
import ReactDOMServer from 'react-dom/server';

export default (webForm) => {
    console.log("webform",webForm)
    let items = 
        <ul>
            {webForm.id.map( item => <li key={item.id}>Item: {`http://learningstore.nokia.com/customer/item/${item.id} - '${item.title}'`}</li> )}
        </ul>
    console.log("items", items)

    let html1 = ReactDOMServer.renderToStaticMarkup (
        <div>
            <p>Dear Global Contact Center</p>
            <br />
            <p>Please find below the web form sent by a customer</p>
            <br />
            <b>Items</b>{items}
            <br />
            <b>Customer Information</b>
            <ul>
                <li>Name: {webForm.CName}</li>
                <li>Company: {webForm.CCompany}</li>
                {webForm.CCompany && <li>Address: {webForm.CAddress}</li>}
                {webForm.CCountry && <li>Country: {webForm.CCountry}</li>}
                <li>Email: {webForm.CEmail}</li>
                <li>Tel: {webForm.CTel}</li>
            </ul>
            <br />
            { (webForm.IDetails || webForm.IQuote || webForm.IBooking || webForm.IOther) && <b>Request Type</b>}
            <ul>
                { webForm.IDetails && <li>Detailed information about those trainings</li>} 
                { webForm.IQuote && <div>
                    <li>Quote for those trainings</li>
                    <ul>
                        {webForm.CNumber && <li>Number of participants: {webForm.CNumber}</li>}
                        {webForm.CLocation && <li>Desired location: {webForm.CLocation}</li>}
                        {webForm.COnSite && <li>Onsite location: {webForm.COnSite}</li>}
                    </ul>
                </div>
                } 
                { webForm.IBooking && <li>Available Schedule</li>}
                { webForm.IOther && <li>Other</li>}
            </ul>
            <br />
            {(webForm.NComments || webForm.NCusto || webForm.NComments || webForm.NUpdate)  && <b>Contact Information</b>}
            <br />
            <ul>
                {webForm.NContact && <li>Contact Name: {webForm.NContact}</li>}
                {webForm.NCusto && <li>Tailored Solution: {webForm.NCusto}</li>}
                {webForm.NComments && <li>Comments: {webForm.NComments}</li>}
                {webForm.NUpdate && <li>Monthly Update: {webForm.NUpdate}</li>}
            </ul>
            <br />
            <p>Best Regards,</p>
            <p>The Customer Store Team</p>
    </div> );          
      
    
        
    let html2 = ReactDOMServer.renderToStaticMarkup (
    <div>
        <br />
        <p>Thank you for your email</p>
        <br />
        <p>NokiaEDU will contact you regarding your e-mail within 48 hours.</p>
        <br />
        <p> This is an automated mail, do not reply, if you have any questions of additional information, 
      please send an email to: <a href={`mailto:${webForm.Smail}`}>{webForm.Smail}</a></p>
      <br />
      <p>Best Regards,</p>
      <p>NokiaEDU</p>

    </div> );
    
    return [html1, html2];
    
}
