/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import AdvancedLoader from './AdvancedLoader'
import { Col, Row, notification } from 'antd'
import renderHTML from 'react-render-html'

import '../css/Store.css'
import SliderHome from './SliderHome'
import Source from '../tools/data'
import HeaderComponent from './Header'
import FooterComponent from './Footer'
import Thumbnail from './Thumbnail'
import B from '../tools/back'
import EditCtl from '../tools/editCtl'
import Banner from './Banner'
import { FaChartLine, FaCommentDots, FaMedal, FaUserGraduate} from 'react-icons/fa'

import ModalWindow from './ModalWindow'

import Remote from '../util/remote'
import authService from '../util/authService'

import ThumbnailContainer from './ThumbnailContainer'

const backend = Remote('Tunnel', 'login')
const itemUrl = 'item/'


let homeProps = null
window.__Route = url => homeProps.history.push(url)
window.__Navigation = r => { B.openKeys = [r]; B.header.toggleSideNav() }




export default class Store extends Component {
  state = { 
    openNewsModal: false, 
    isLoading: true,
    currentUser: null, 
    newTopics: [],
   }

  // Close the modal (the menu)

  closeModalNews = (check, id) => {
    if (check === true) {
      localStorage.newsOff = id
    }
    B.isNewsAlreadyShown = true
    this.setState({ openNewsModal: false })
  }

  // retrieve all the desired information from the store.json file
  // more precisely the homepage in store.json
  // filter them by id

  _storeHP(store, storeDef) {
    if (!storeDef.homepage.forEach) return

    let ids = []

    // add all new topics and the whatsnew section
    ids = ids.concat(B.newTopics)
    ids.push(B.whatsnew)

    // add all new Featured Learning
    storeDef.homepage.forEach((section) => (ids = ids.concat(section.items)))
    ids = ids
      .filter((id) => store.getByID(id))
      .map((id) => {
        const item = store.getByID(id)
        delete item.name
        delete item.date
        delete item.rev
        delete item.Owner
        item.stats = { views: B.views[id], likes: B.likes[id] }
        return item
      })
    localStorage[storeDef.id + 'HP'] = JSON.stringify(ids)
    ids.forEach((item) => delete item.stats)
  }

  // load the page
  _load(name, storeDef, norender) { 
    Source.fetch(name).then((store) => {
      if (!norender) this.setState({ isLoading: false })

       // Setting the new Topics from store.json whatsnew key
       B.whatsnew = Source.elts.find(elm => elm.ID === storeDef.whatsnew)
       if (B.whatsnew && B.whatsnew.Solutions) {
         B.newTopics = B.whatsnew.Solutions
         this.setState({newTopics: B.whatsnew.Solutions})
       }

      this._storeHP(store, storeDef)
      this.setState({})     
    })
  }

  _preload(name, storeDef) {
    if (Source.get(name)) {
      this.setState({ isLoading: false, newTopics: B.newTopics })
      return
    }

    const preload = name + 'HP'
    if (localStorage[preload]) {
      let hp = JSON.parse(localStorage[preload])
      Source.set(name, hp, true)
      hp.forEach((item) => {
        if (item.stats) {
          B.views[item.ID] = item.stats.views
          B.likes[item.ID] = item.stats.likes
        }
      })

      this.setState({ isLoading: false, newTopics: B.newTopics }, () =>
        setTimeout(() => this._load(name, storeDef, true), 150),
      ) // load the data
    } else this._load(name, storeDef)
  }

  // connection verification
  // rights check

  login(token, name, storeDef) {
    if (!token) {
      authService.login().then((token) => this.login(token, name, storeDef))
      return
    }
    // console.log('adal', adal.name(), storeDef.private);
    if (typeof storeDef.private === 'string') {
      // private contains the name of the store
      // console.log('backend', storeDef.private);
      backend
        .login(token, authService.sid(), storeDef.private)
        .then((rep) => {
          // console.log('rep', rep);
          if (rep.rep) this._preload(name, storeDef)
          else {
            notification.error({
              message: 'Error',
              description: 'You are not allowed to enter this store',
              placement: 'bottomRight',
            })
          }
        })
        .catch((err) => {
          notification.error({
            message: 'Error',
            description: 'You need to be connected to the Intranet',
            placement: 'bottomRight',
          })
        })
    } else this._preload(name, storeDef)
  }

  // to log in

  UNSAFE_componentWillMount() {
    const { name } = this.props.match.params
    document.body.style.overflow = 'auto'
    homeProps = this.props

    const storeDef = Source.getDef(name)
    if (!storeDef) return window.location.href ='https://learningstore.nokia.com';
    if (storeDef.private)
      authService
        ._getToken()
        .then((token) => this.login(token, name, storeDef))
        .catch((err) =>
          authService
            .login()
            .then((token) => this.login(token, name, storeDef)),
        )
    else this._preload(name, storeDef)
    B.back = true

    let url = window.location.pathname.split(itemUrl)
    let isEditOnItem = url[1] ? true : false
    if (!isEditOnItem) B.pageview('/' + name + '/index.html')

  }

  showModalNews() {
    this.setState({ openNewsModal: true })
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    const { name } = this.props.match.params
    const storeDef = Source.getDef(name)

    const news = storeDef.news
    if (
      news.id !== Number(localStorage.newsOff) &&
      new Date() < new Date(news.date) &&
      !B.isNewsAlreadyShown &&
      !this.state.openNewsModal
    )
      this.showModalNews()

    B.ga(storeDef.analytics)
    
  }

  displayHTML(content, key) {
    return (
      <div key={key} className="thumbnails">
        <div className="catTitle">{renderHTML(content.title || '')}</div>
        <div
          dangerouslySetInnerHTML={{ __html: content.html || '' }}
        ></div>
      </div>
    )
  }

  render() {
    B.set(this)

    const { name } = this.props.match.params
    let storeDef = Source.getDef(name)

    // we check if the page loads
    if (this.state.isLoading) {
      return (
        <div>
          <div className="head">
            <HeaderComponent {...this.props} setCurrentUser={p => this.setState({currentUser: p})} data={storeDef} />
          </div>
          <div className="loading">
            <AdvancedLoader />
          </div>
        </div>
      )
    }

    const location = window.location

    let store = Source.get(name)

    let tab = storeDef.homepage.id
      ? [
          {
            title: '',
            items: store.getByID(storeDef.homepage.id).Solutions,
            editable: storeDef.homepage.editable,
          },
        ]
      : storeDef.homepage

    if (storeDef.homepage.editable)
      B.currentColl = store.getByID(storeDef.homepage.id)
    else B.currentColl = null

    // The thumbnails in the pages // We get all the information we need for the display of the thumbnails from the .json file
    let displayArea = tab.map((stuffToShow, index) => {

      // First case, the page is pure HTML
      if (stuffToShow.html) return this.displayHTML(stuffToShow, index)

 
      let tmpItems = []
      // Second case, thumbnails will be displayed, first get the recommended   
      if (stuffToShow.recommend) {
        if (authService.app.getUser()) {
          tmpItems = (this.state.currentUser)? this.state.currentUser.recommended.map((elt) => elt.ID) : []
        } 
      }
      else if (stuffToShow.liked) tmpItems = (this.state.currentUser)? this.state.currentUser.likes : []
      else if (stuffToShow.topics) tmpItems = this.state.newTopics            
      else tmpItems = stuffToShow.items



      // Get the thubmnails to be displayed
      let thumbnails = tmpItems.length
        ? tmpItems.map((itemID, index2) => {
            let item = store.getByID(itemID)
            if (!item) return null
            return (
              (EditCtl.editMode || !item.Wip) && (
                <Thumbnail 
                  key={index2} 
                  props={this.props}
                  currentUser={this.state.currentUser} 
                  data={item} 
                  store={storeDef} />
              )
            )
          }).filter(elm => elm)
        : []

      // Display the thumbnails within the tabs (or not)
      let singleLine = storeDef.homepageSingleLineSlider
      return storeDef.homepagestyle === 'tabs' 
        ? (
            <div  key={stuffToShow.title}>
              <div className='catTitle' style={{marginTop:'30px'}}>{stuffToShow.title}</div>
              <ThumbnailContainer singleLine={singleLine} emptyListMessage={this.state.currentUser? stuffToShow.noItemMessage : stuffToShow.notConnectedMessage} thumbnailList={thumbnails} batchSize={20} />
            </div>) 

        : (<ThumbnailContainer singleLine={singleLine} thumbnailList={thumbnails} batchSize={20} />)
    })

    let isItemBackground = false, isEditOnItem = false
    let url = location.pathname.split(itemUrl)
    if (url[1]) {
      isEditOnItem = true
      if (
        storeDef.homepage[0] &&
        storeDef.homepage[0].items.indexOf(url[1]) === -1
      ) {
        isItemBackground = true
      }
    }

    // ModalWindows.js

    const news = storeDef.news
    const hpID = store.getByID(storeDef.homepage.id)
    return (
      <div className={'minHeight'}>
        {!isItemBackground && (
          <ModalWindow
            id={news.id}
            html={news.html}
            title={news.title}
            closeModal={this.closeModalNews}
            open={this.state.openNewsModal}
            check={true}
            dontclose={news.dontclose}
          />
        )}
        {!localStorage.nokia_cookieconsent_dismissed &&
          !B.isCookiesAlreadyShown && <Banner />}

        <div id="editDimmer">
          <div>
            <img src="" id="editDimmerImg" alt="Dimmer" />
            <div id="editDimmerText"></div>
          </div>
        </div>



        {!isItemBackground && (
          <div className="store">
            <div className="head">
              <HeaderComponent
                isEditOnItem={isEditOnItem}
                setCurrentUser={p => this.setState({currentUser: p})}
                {...this.props}
                data={storeDef}
             />
              
            </div>
            {storeDef.homepage.id && (
              <Row type="flex" justify="center" className="collTitle">
                <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                  <h2>{hpID.Title}</h2>
                  <div className="tagline">
                    <i>{renderHTML(hpID.Tagline || '')}</i>
                  </div>
                </Col>
                <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                  {renderHTML(hpID.Description || '')}
                </Col>
              </Row>
            )}

            {/* tag display */}
            <div className="col-3 col-s-12">
            {storeDef.hometags && (
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <div className="Box">
                    <div className="searchTags">
                      {/* <Link className= "linkStyle" to={`advanced/`} key={'loupe'}>
                          <FaSearch style={{marginBottom: '-2px'}}/>
                      </Link> ------------ this icon is already used in the menu */}
                      {storeDef.searchFormatFilter
                        .filter(
                          (item) =>
                            [
                              'Collection',
                              'Topic page',
                            ].indexOf(item) === -1,
                        )
                        .map((item, index) => (
                          <Link className= "linkStyle" to={`advanced/${item}`} key={index}>
                            {/* <Tag color="#124191"> */}

                             {item}{' '}
                            {/* </Tag> */}
                          </Link>
                        ))}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            </div>

            {/* display images */}

            <Row type="flex" justify="center">
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <SliderHome {...this.props} data={storeDef} />
              </Col>
            </Row>



            {/* thubnails display  */}
            
              <Row type="flex" justify="center">
                <Col xs={24} sm={24} md={22} lg={22} xl={22}>
                  {storeDef.homepagestyle === 'icons' && displayArea}
                  {storeDef.homepagestyle === 'html' && displayArea}
                  {storeDef.homepagestyle === 'tabs' && (
                    //<Tabs type="card" size="large" style={{marginTop:'20px'}}>{displayArea}</Tabs>
                    <div style={{marginTop:'20px'}}>{displayArea}</div>           
                    )}
                </Col>
              </Row>

            
          </div>
        )}

        {!isItemBackground && storeDef.shortcutBox && <Row  type="flex" justify="center"  >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row  className='Box_Counter' type="flex" justify="center"  >
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <a 
                    href='https://nokia.sharepoint.com/sites/learn/achiev/SitePages/index.aspx/myindex'
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="Box_nbr"><FaChartLine /></div>
                    <div className='sous_title'>Learning Index</div>
                  </a>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <a 
                    href='https://nokia.sharepoint.com/sites/learn/achiev/SitePages/index.aspx/myachievements'
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="Box_nbr"><FaMedal /></div>
                    <div className='sous_title'>Badges</div>
                  </a>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <a 
                    href="https://nokialearn.csod.com/"
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="Box_nbr"><FaUserGraduate / ></div>
                    <div className='sous_title'>NLDH</div>
                  </a>
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                  <a 
                    href='https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMDA2MzkwNyJ9/all'
                    target="_blank"
                    rel="noopener noreferrer">
                    <div className="Box_nbr"><FaCommentDots /> </div>
                    <div className='sous_title'>Support on Yammer</div>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>}


        {!isItemBackground && <FooterComponent props={this.props} data={storeDef} />}

      </div>
    )
  }
}
