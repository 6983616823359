/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom'
import {Config} from './../config.js';
import {Row,Icon} from 'antd'
import '../css/Home.css';
// import MdSchool from 'react-icons/lib/md/school';
import {MdSchool} from 'react-icons/md';

document.getElementById("root").style.marginTop = '0px';

export default class Home extends Component {
  state = { oneStore: null }

  UNSAFE_componentWillMount() {
    if (this.props.stores.length === 1) {
      this.setState({oneStore: this.props.stores[0].id});
    }
  }

  render() {
    if (this.state.oneStore) {
      return (
        <Redirect to={"/" + this.state.oneStore}/>
      )
    }
    
    const myStores = this.props.stores.filter( store => !store.hidden ).map((store, index) => {
      return (
        <Link key={index} to={"/" + store.id + "/"}>
          <div title={store.title} className="storePellet">
            <div className="storePellet1">
              <Row>
                {store.private && <Icon type="lock" theme="filled" style={{color:'transparent'}} />}
                <MdSchool color='#3d3d3d' className="storePellet1_icon" style={{marginTop:30}} size="60"/>
                {store.private && <Icon type="lock" theme="filled" style={{color:'#3d3d3d',marginBottom:10}} />}
              </Row>

              <div className="storePellet1_title">
                {store.title}
              </div>
              <div className="storePellet2_subtitle">
                {store.subtitle}
              </div>
            </div>
          </div>
        </Link>
      )
    });

    return (
      <div className='home'>
        <div className="select">
          <div className="selectTitle">
            {Config.Name}
          </div>
          {myStores}
        </div>
      </div>
    );
  }
}
