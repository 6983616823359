import React, { Component } from 'react';
import { FieldConnect } from 'react-components-form';
import { Tag, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons'
import '../css/Edit.css';
import '../css/ClosableTagsField.css';


let tagStringify = (list) => {
    let string = "";
    Object.keys(list).forEach((key, index) => {
        string += list[key];
        if (index < (Object.keys(list).length - 1)) string += ' | ';
    })
    if(string.length > 60){
        string = string.slice(0, 60);
        string+='...';
    }
    return string;
}

const TagList = FieldConnect(({ name, data, field, info, newTag, removeLabel, onChange }) => {

    // console.log(data)
    if (!data) data = [];

    removeLabel = (e, index) => {
        e.preventDefault();
        Modal.confirm({
            title: 'Do you really want to remove this tag?',
            onOk() {
                data = data.filter(elt => data.indexOf(elt) !== index); 
                console.log(data, index);    
                onChange(data);
            }
          });
    }

    return (
        <div className='editFlow'>
            <label className='editLabel'>{field}<br />{info}</label>
            <div className="tagList">
                {data.map((elt, index) => <Tag name={name} key={index} onClose={(e) => removeLabel(e, index)} closable >{tagStringify(elt)}</Tag>)}
                <Tag className="site-tag-plus" onClick={newTag}><PlusOutlined /> Add Tag</Tag>
            </div>
        </div>
    );
});

export default class ClosableTagsField extends Component {

    render() {

        return (
            <TagList name={this.props.name} data={this.props.data} field={this.props.field} info={this.props.info} newTag={this.props.newTag} />
        );
    }
}