/*
  @author Gilles Gerlinger / Friedrich Tane
  Copyright Nokia 2018. All rights reserved.
*/
import React, {Component} from 'react';
import {Redirect, Link} from 'react-router-dom'
import {Form, FormEventsEmitter, TextField, SelectField, CheckboxField, TextareaField} from 'react-components-form';
import Dropzone from 'react-dropzone';
import {Button, Divider, Input, Switch, notification, Modal, Tooltip} from 'antd'
import {FaTimesCircle} from 'react-icons/fa';
import {FaTrashAlt, FaEdit, FaFilm, FaImage, FaBook, FaInfoCircle, FaFolder} from 'react-icons/fa';
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';

import Quill from './Quill';
import ToggleField from './ToggleField';
import DateField from './DateField';
import TagListField from './TagListField';
import ClosableTagsField from './ClosableTagsField';
import ModalSection from './ModalSection'
import ModalTags from './ModalTags';
import HeaderComponent from './Header';
import {Config} from '../config.js';
import Source from '../tools/data';
import B from '../tools/back';
import NotFound from './NotFound';
import Ctl from '../tools/editCtl';
import EditCtl from '../tools/editCtl';
import wipC from '../tools/editWip';
import '../css/Edit.css';

import JSONInput from 'react-json-editor-ajrm/index';
import locale from 'react-json-editor-ajrm/locale/en';
import Remote from '../util/remote';
import authService from '../util/authService';
// import jsql from '../tools/jsql';


// const notifServer = jsql('b43fdd98b1fd705ae4c3a10cf25aad8a');


let ready = false;
let currentID = '';
const eventsEmitter = new FormEventsEmitter();
eventsEmitter.listen('modelChange', ({name, value}) => {
  // console.log('changeModel', name, value)
  if (!ready) return;
  wipC.unsave(currentID);
  setunLoad();
});

const unload = (e) => {
  e.returnValue = 'ok';
  return 'ok';
}
const setunLoad = () => window.onbeforeunload = wipC.stay() ? unload : null;

const SortableItem = SortableElement( ({value}) => {
  let icon;
  //if (value.type === "title") icon = <FaImage color="efefef"/>;
  if (value.type === "image") icon = <FaImage color="grey"/>;
  if (value.type === "video") icon = <FaFilm color="grey"/>;
  if (value.type === "text") icon = <FaEdit color="grey"/>;
  if (value.type === "objects") icon = <FaBook color="grey"/>;
  if (value.type === "collection") icon = <FaFolder color="grey"/>;

  return <div className="sortEltEdit" >
            <div className="typeIcon">
              {icon}
            </div>
            <div className="selectedSectionTitle">
              {value["title_"+value.ID]}
            </div>
         </div>
} );

const SortableList = SortableContainer( ({items, show}) => {
  return <div>
    {items.map( (value, index) => <div onClick={() => {show(value)}} key={`item-${index}`}><SortableItem index={index} value={value}/></div> )}
  </div>}
);

class SortableComponent extends Component {

  render() {
    return <SortableList items={this.props.items} onSortEnd={this.props.onSortEnd} axis="y" show={this.props.show}/>;
  }
};


export default class Edit extends Component {

  state = {
    isLoading: true,
    selectedItems: [],
    otgMetadata: null,
    itemsSolutions: [],
    // checked: false,
    otgChecked: false,
    flatChecked: false,
    excludeChecked : false,
    noReco: false,
    addToCollChecked: false,
    visible: false,
    tagvisible: false,
    sectionType: '',
    sections: []
  }
  
  storeDef;
  itemsSolutions = [];
  limit = 20;
  mode;
  moreItems = false;
  model = {};

  // toggleWip = () => this.setState({checked: !this.state.checked})
  toggleOtg = () => this.setState({otgChecked: !this.state.otgChecked})
  toggleFlat = () => this.setState({flatChecked: !this.state.flatChecked})
  toggleExclude = () => this.setState({excludeChecked: !this.state.excludeChecked})
  toggleReco = () => this.setState({noReco: !this.state.noReco})
  toggleAddToColl = () => this.setState({addToCollChecked : !this.state.addToCollChecked})

  

  onSortEnd = ({oldIndex, newIndex}) => {
    // console.log('old : ', oldIndex, 'new : ', newIndex);
    this.setState({ sections: arrayMove(this.state.sections, oldIndex, newIndex) });
    //EditCtl._reload();
  }

  loadItem(props) {
    const { name, id, type } = props.match.params;
    B.ga(this.storeDef.analytics);

    this.resetData(type, this.store.getByID(id));
    // console.log('edit', {...this.item})
    if (!this.item) {
      this.setState({ isLoading: false });
      return; 
    }

    if (this.item.Icon) this.setState({preview: Source.decodeIcon(this.item, this.storeDef.url)});
    if (this.item.tImage) this.setState({preview2: `${process.env.PUBLIC_URL}/${this.item.tImage}`});

    if (this.item.Solutions) {
      this.item.Solutions.forEach(itemID => {
        const item = this.store.getByID(itemID);
        if (item) this.handleAdd(item);
      });
    }

    if(this.item.Sections){
      this.item.Sections = this.item.Sections.filter(section => ((section.type !== 'collection') || this.store.getByID(section['objects_'+section.ID][0])));
      this.setState({sections: this.item.Sections});
    }

    const selectedItems = Object.keys(this.store.ids).map(key => this.store.ids[key])
    const myState = { name: name, store: selectedItems, selectedItems: selectedItems, isLoading: false }
    // if (this.item.Wip) myState.checked = true;
    if (this.item.Flat) myState.flatChecked = true;
    if (this.item.Exclude) myState.excludeChecked = true;
    if (this.item.noReco) myState.noReco = true;
    myState.otgChecked =  (this.item.otgMetadata !== undefined);
    if (this.item.otgMetadata) myState.otgMetadata = this.item.otgMetadata;

    this.setState(myState);
    this.selectItems(20);
  }

  UNSAFE_componentWillMount() {

    document.body.style.overflow = 'auto';

    const { name } = this.props.match.params;
    if (!B.intranet) {
      this.setState({ isLoading: false });
      return;
    }
    this.storeDef = Source.getDef(name);
    B.ga(this.storeDef.analytics);
    
    Source.fetch(name).then(store => {
      this.store = store;
      this.loadItem(this.props);
    });

    B.back = true;
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.loadItem(newProps);
    // const { name, id, type } = newProps.match.params;
    // const store = Source.get(name);
    // const item = store.getByID(id);
    // this.resetData(newProps.match.params.type, item);
  }

  componentDidUpdate() {
    EditCtl.switchEditMode(true, false);
  }

  componentDidMount() {
    ready = false;
    setTimeout(() => ready = true, 100); // html can be improved by Quill, but no user changes
  }

  resetData(type, item) {
    this.itemsSolutions = [];

    // console.log('reset', type)
    if (type === "item") {
      this.item = { Status: 'Development', Icon: this.storeDef.defaultIcon || Config.defaultIcon, Wip: true, ...this.storeDef.defaultItem };
      // console.log('new item');
      this.mode = "create";
    }
    else if (type === "collection") {
      this.item = { Status: 'Development', Solutions: [], Icon: this.storeDef.defaultCollectionIcon || Config.defaultIcon, Wip: true, ...this.storeDef.defaultCollection };
      // console.log('new collection');
      this.mode = "create";
      if (this.storeDef.addToColl) this.setState({ addToCollChecked: true });
    }
    else if (this.storeDef.topicPage && type === "topic") {
      this.item = { Status: 'Development', Sections: [], Icon: this.storeDef.defaultCollectionIcon || Config.defaultTopicIcon, Wip: true, tImage: this.storeDef.topicImages[0], ...this.storeDef.defaultTopic };
      // console.log('new topic page', {...this.item});
      this.mode = "create";
    }
    else {
      this.item = item;
      this.mode = "edit";
    }
    eventsEmitter.emit('reset', this.item);
  }

  /*
    componentDidMount() {
      setTimeout(() => {
        const height = document.getElementById('nlsForm').clientHeight;
        // console.log(height)
        if (height > window.innerHeight)
          document.firstElementChild.setAttribute('style', 'overflow-y: scroll');
        else
          document.firstElementChild.setAttribute('style', 'overflow-y: hidden');
      }, 100)
    }
  */
  onDrop(file) {
    const image = file[0];
    const reader = new FileReader();
    let image_type = file[0].type;
    if (image_type !== "image/png" && image_type !== "image/jpg" && image_type !== "image/jpeg" && image_type !== "image/gif") {
      notification.error({
        message: 'Error',
        description: "This format is not correct",
        placement: 'bottomRight'
      });
    }
    reader.onload = (event) => {
      // console.log('ev', event);
      this.item.Icon = event.target.result;
      this.setState({preview: this.item.Icon})
    };
    reader.readAsDataURL(image);
  }

  removeIcon() {
      delete this.item.Icon;
      this.setState({preview: null});
  }

  removeImage() {
    delete this.item.tImage;
    this.setState({preview2: null});
  }

  selectItems(limit, search) {
    let data = this.state.store.filter( item1 => wipC.isNotWip(item1) );
    // item1 !== wipC && item1 !== wipC.unsaved && item1 !== wipC.admin );
    if (search) data = data.filter( item0 => item0.Title && item0.Title.toLowerCase().indexOf(search.toLowerCase()) !== -1 );
    else data = [];

    if (data.length > limit) {
      this.moreItems = true;
      data = data.slice(0, limit);
    } else {
      this.moreItems = false;
    }

    this.setState({selectedItems: data});
  }

  handleSearch(btn) {
    this.setState({inputSearch: btn.target.value});
    this.selectItems(this.limit, btn.target.value);
  }

  handleJson(data) {
    this.setState({otgMetadata: data.jsObject});
  }

  handleAdd(el, it, toast) {
    if (this.itemsSolutions.indexOf(el) !== -1) {
      notification.error({
        message: 'Error',
        description: "This item is already added",
        placement: 'bottomRight'
      });
    } else {
      // if (toast) {
      //   notification.success({
      //     message: 'Success',
      //     description: "Item added",
      //     placement: 'bottomRight'
      //   });
      // }
      this.itemsSolutions.push(el);
      this.setState({itemsSolutions: this.itemsSolutions});
    }
  }

  handleRemove(el, it) {
    const self = this;
    Modal.confirm({
      title: 'Do you really want to remove this item ?',
      content: 'When you remove an item from collection, it will no longer appear in the collection, but it will not be deleted.',
      onOk() {
        self.itemsSolutions.splice(self.itemsSolutions.indexOf(el), 1);
        self.setState({itemsSolutions: self.itemsSolutions});
      }
    });
  }

  showModal = (elt) => {
    //if(elt.type){On push item et on envoit le dernier elt à la modal}else{On envoit l'élément sélectionné à la modal}
    if(!elt["title_"+elt.ID]){
      this.model = {
        ID: 's.' + new Date().getTime(),
        type : elt.type,
      };
      this.model["title_"+ this.model.ID] = '';
      this.model["url_"+ this.model.ID] = '';
      this.model["text_"+ this.model.ID] = null;
      this.model["objects_"+ this.model.ID] = [];
      this.model["sequence_"+ this.model.ID] = false;
    }else{
      this.model = elt;
      this.itemsSolutions = this.model["objects_"+ this.model.ID].map(id => this.store.getByID(id)).filter( id => id);
    }
    this.setState({
      visible: true,
      itemsSolutions: this.itemsSolutions,
    });
  }
  
  handleOk = (nSection) => {
    nSection["objects_"+nSection.ID] = this.state.itemsSolutions.map(item => item.ID);

    let okay = true;
    if(!nSection["title_"+nSection.ID] || !nSection["text_"+nSection.ID]) okay = false;
    if(nSection.type === "image" || nSection.type === "video"){
      if(!nSection["url_"+nSection.ID]) okay = false;
    }
    if(nSection.type === "objects"){
      if(nSection["objects_"+nSection.ID].length === 0) okay = false;
    }
    if(nSection.type === "collection" && nSection["objects_"+nSection.ID].length > 0) okay = true;
    
    if(okay === true){
      if(nSection.type === "collection"){
        let coll = this.store.getByID(nSection["objects_"+nSection.ID][0]);
        nSection["title_"+nSection.ID] = nSection["linked_"+nSection.ID] ? coll.Title : (nSection["title_"+nSection.ID] || coll.Title);
        nSection["text_"+nSection.ID] = nSection["linked_"+nSection.ID] ? coll.Description : (nSection["text_"+nSection.ID] || coll.Description);
      }
      const nTab = this.state.sections.map((section) => {
        if(section.ID === nSection.ID){
          section = nSection;
        }
        return section;
      });
      this.moreItems = false;
      this.itemsSolutions = [];
      this.setState({
        sections: nTab.indexOf(nSection) === -1 ? [
          ...this.state.sections,
          this.model,
        ] : nTab,  
        visible: false,
        itemsSolutions: [],
        selectedItems: [],
      });
    }else{
      notification.error({
        message: 'Error',
        description: "All these fields are required",
        placement: 'bottomRight'
      });
    }
  }
  
  handleCancel = (e) => {
    this.moreItems = false;
    this.itemsSolutions = [];
    this.setState({
      visible: false,
      itemsSolutions: [],
      selectedItems: [],
    });
  }

  tagCancel = () => {
    this.setState({
      tagvisible : false,
    })
  }

  tagOk = (tag, item) => {
    if(!item) item = [];
    // console.log(tags, item);
    item.push(tag);
    this.setState({
      tagvisible : false,
    })
    return item;
  }

  showTagModal = () => {
    this.setState({
      tagvisible : true,
    })
  }

  handleDelSection = (section) => {
    const self = this;
    Modal.confirm({
      title: 'Do you really want to remove this section ?',
      //content: 'When you remove an item from collection, it will no longer appear in the collection, but it will not be deleted.',
      onOk() {
        // console.log(self.state.sections.indexOf(section));
        self.state.sections.splice(self.state.sections.indexOf(section), 1);
        self.handleCancel();
      }
    });
  }
  
  _setFields(mapping) {
    const rules = this.storeDef.fieldInfo;
    return mapping && mapping.map( (field, index) => {
      if (field.startsWith('_')) field = field.replace('_', '');
      let type = field.split('|type:')[1];
      if (type) type = type.split(/(=|\|)/)[0];
      let options = field.split('=')[1];
      // console.log(field, type, options)

      // store.json test entries
      // "Select|type:select=['12mn', '24mn', '36mn', '48mn', '60mn']",
      // "Check|type:groupCheck=['a', 'c', 'b', 'd', 'k']",

      let disp = field.split('|disp:')[1];
      if (disp) disp = disp.split(/(=|\|)/)[0];
      let pholder = field.split('|pholder:')[1];
      if (pholder) pholder = pholder.split(/(=|\|)/)[0];
      let action = field.split('|action:')[1];
      if (action) action = action.split(/(=|\|)/)[0].split(',');

      field = field.split('|')[0];
      const short = field.replace('*', '');
      field = disp || field;
      // console.log('edit', field, type, short, disp)
      let info;
      if(rules) info = rules[short] ? <Tooltip title={rules[short]}><FaInfoCircle color="#1890ff"/></Tooltip> : null;
      if (type === 'text') return (
        <div className='editFlow' key={index}>
          <label className='editLabel'>{disp || field}<br/>{info}</label>
          <TextField className='editField' name={short} placeholder={pholder || ''}/>
        </div>);

      if (type === 'textarea') return (
        <div className='editFlow' key={index}>
          <label className='editLabel'>{field}<br/>{info}</label>
          <TextareaField className='editField' name={short}/>
        </div>);

      if(type === 'select'){
        return(
          <div className='editFlow' key={index}>
            <label className='editLabel'>{field}<br/>{info}</label>
            <SelectField 
              className="editField" 
              placeholder='select' 
              name={short} 
              options={JSON.parse(options.replace(/'/g, '"'))} 
              
            />
          </div>
        );
      }

      if (type === 'toggle'){
        return(
          <ToggleField name={short} checked={this.item[short]} key={index} field={field} info={info}/>
        );
      }

      if (type === 'date'){
        return(
          <DateField name={short} selected={this.item[short]} key={index} field={field} info={info}/>
        );
      }

      if(type === 'groupCheck') {
        options = JSON.parse(options.replace(/'/g, '"'));

        return(
          <div className='editFlow' key={index}>
            <label className='editLabel'>{field}<br/>{info}</label>
            <div className='editFlow'>
              { options.map( (arg, index) => { return (
                <CheckboxField key={index} classname="editField" name={arg} label={arg}/>);
              }) }
            </div>
          </div>
        );
      }

      if(type === 'tag') {
        options = JSON.parse(options.replace(/'/g, '"'));
        // console.log(this.item);
        return(
          <TagListField name={short} data={options} selected={this.item[short]} key={index} field={field} info={info}/>
        );
      }

      if(type === 'closabletag'){
        // console.log(short, this.item[short]);
        return(
          <div>
            {this.state.tagvisible && <ModalTags visible={this.state.tagvisible} handleOk={(tag) => {this.item[short] = this.tagOk(tag, this.item[short])}} handleCancel={this.tagCancel} newTag={this.showTagModal} {...this.props} />}
            <ClosableTagsField name={short} data={this.item[short]} key={index} field={field} info={info} newTag={this.showTagModal} />
          </div>
        );
      }



      return (
        <div key={index}>
          <Quill name={short} key={index} value={this.item[short]} display={field} info={info}/>
          {action && <div style={{textAlign:'end',padding:'2%'}}><Button type='primary' onClick = {() => {
            authService.getToken().then( token => {
              // console.log(this.item)
              const exec = new Remote('StoreAction', [action[1]]);
              exec[action[1]](authService.email(), this.item)
              .then( rep => { 
                console.log('action OK', rep);
                if(rep.err){
                  notification.error({
                    message: 'Error',
                    description: "The request failed",
                    placement: 'bottomRight'
                  })
                } else { 
                  notification.info({
                    message: 'Email send',
                    description: "You will receive your email shortly",
                    placement: 'bottomRight'
                  }) 
                  }
                })
              .catch( err => console.log('action err', err) );  
            })
          }}>{action[0]}</Button></div>}
        </div>)
    });
  }

  render() {
    B.set(this);

    if (this.state.isLoading) return null;

    const {name} = this.props.match.params;
    if (!B.intranet) return <Redirect to={"/" + name}/>

    let item = this.item;
    // console.log('edit what ?', item);
    if (!item) return <NotFound/>
    // window.item = item
    if (this.state.otgChecked && !item.otgMetadata) item.otgMetadata = {
      "type": "video__html5__unity__pdf__threesixty",
      "file_size": "file_size_(ex:_1.98MB)",
      "skey":"sharing_session_key",

      // "type": "video__html5__unity__pdf__threesixty",
      // "url": "url_to_the_file",
      // "thumb": "url_of_the_thumbnail_image",
      // "title": "title_of_the_material",
      // "description": "description_of_the_material_that_will_appear_on_the_ontheGo_app",
      // "file_size": "file_size_(ex:_1.98MB)",
      // "duration": "duration_of_the_file_(ex:_3_min)._This_field_is_optional",
      // "storageType": "",
      // "skey":"sharing_session_key",
      // "scene": "when_type_is_unity",
      // "model": "when_type_is_unity",
      // "structure": ["when_type_is_unity"]
    }

    if (item.otgMetadata && item.otgMetadata.type === 'unity')
      item.otgMetadata.scene = "";

    if (item.otgMetadata && !item.otgMetadata.skey) item.otgMetadata.skey = '';

    // console.log('otg', item.otgMetadata)

    // console.log('item', item)
    if (item.sid) B.origin[item.ID] = B.origin[item.ID] || JSON.stringify(item);
    else {
      // console.log('creating new item', item.ID);
      item.sid = this.state.name;
      item.ID = 'n.' + new Date().getTime();
      item.del = true;
      B.origin[item.ID] = JSON.stringify(item);
      delete item.del;
      // item.Icon = this.storeDef.defaultIcon || Config.defaultIcon;
      Ctl.update(item); // so that it can be searched and stored in the unsaved collection
      // wipC.unsave(item.ID); // new item
      // setunLoad();
    }
    currentID = item.ID;
    const dataCollections = Source.getCollections(name, currentID);

    // console.log(item);

    const cancelMethod = () => {
      const oldItem = JSON.parse(B.origin[this.item.ID]);
      // console.log('cancel', oldItem)
      EditCtl.update(oldItem)
      // this.item = JSON.parse(JSON.stringify(this.oldItem));
      // EditCtl._reload();
      // EditCtl.gotoWip();
      if(B.back){
        B.history.goBack();
      }
      wipC.unsaved.update(item.ID);
    };

    const submitMethod = () => {
      if (!this.state.preview) {
        notification.error({
          message: 'Error',
          description: "You need to add an icon",
          placement: 'bottomRight'
        });
        return;
      }
      if (item.Title) item.Title = item.Title.trim(); // trim first
      if (!item.Title) {
        notification.error({
          message: 'Error',
          description: "You need to add a title",
          placement: 'bottomRight'
        });
        return;
      }
      const mapping = item.Solutions ? this.storeDef.collectionMapping || [] : (item.Sections ? this.storeDef.topicMapping : this.storeDef.mapping) ;
      const failed = mapping.some(field => {
        field = field.split('|')[0];
        const short = field.replace(/(^_|\*)/g, '');
        if (typeof item[short] === 'string') {
          // console.log('short', short, item[short])
          const empty = item[short].replace(/<(?:.|\n)*?>/gm, '').trim();
          if (!empty) delete item[short];
        }
        // console.log(field, typeof item[short], item[short])
        if (field.indexOf('*') > -1 && !item[short] ) {
          notification.error({
            message: 'Error',
            description: short + " must not be empty",
            placement: 'bottomRight'
          });
          return true;
        }
        return false;
      });
      // console.log('failed', failed)
      if (failed) return;

      if (item.Solutions) item.Solutions = this.state.itemsSolutions.map( it => it.ID );
      if (item.Sections) item.Sections = this.state.sections;
      
      // this.state.checked ? item.Wip = true : delete item.Wip;
      this.state.flatChecked ? item.Flat = true : delete item.Flat;
      this.state.excludeChecked ? item.Exclude = true : delete item.Exclude;
      this.state.noReco ? item.noReco = true : delete item.noReco;

      if (this.state.otgChecked) {
        if (this.state.otgMetadata)
          item.otgMetadata = this.state.otgMetadata;
      }
      else {
        // console.log('deleting otgmeta');
        delete item.otgMetadata;
      }

      // console.log(B.currentColl.Solutions.indexOf(item.ID) === -1);
      
      // item.date = new Date().getTime();
      // console.log('itID', item.ID, B.origin[item.ID]);
      // console.log('saving', item)
      wipC.save(item.ID);
      wipC.process(item, true);
      // if (item.Status === 'Published') {
      //   delete item.Wip;
      //   wipC.save(item.ID);
      //   wipC.admin.remove(wipC.admin.indexOf(item.ID));
      //   wipC.ready.remove(wipC.ready.indexOf(item.ID));
      //   wipC.review.remove(wipC.review.indexOf(item.ID));
      // }
      // else if (item.Status === 'Review') {
      //   item.Wip = true;
      //   wipC.ready.save(item.ID);
      //   wipC.save('ready');

      //   wipC.remove(wipC.indexOf(item.ID));
      //   wipC.unsaved.remove(wipC.unsaved.indexOf(item.ID));
      //   wipC.admin.remove(wipC.admin.indexOf(item.ID));
      // }
      // else wipC.save(item.ID);
      Ctl._push(B.origin[item.ID], item);
      delete B.origin[item.ID];

      setunLoad();

      if (B.currentColl && B.currentColl.Solutions.indexOf(item.ID) === -1 && this.state.addToCollChecked) {
        B.origin[B.currentColl.ID] = JSON.stringify(B.currentColl);
        // console.log('Adding to collection', B.currentColl, item.ID)
        B.currentColl.Solutions.push(item.ID);
        Ctl._push(B.origin[B.currentColl.ID], B.currentColl);
        delete B.origin[B.currentColl.ID];
        wipC.save(B.currentColl.ID);
        wipC.process(B.currentColl, true);
        // delete B.currentColl; //?
      }

      B.history.replace(`/${this.storeDef.id}/item/wip`);
    };

    let myImage;
    // if (this.state.image.length === 0) {
    if (!this.state.preview) {
        myImage = (
        <div className="imgPreview">
          No selected image
        </div>
      )
    } else {
      // console.log('myImage', this.state.preview)
      myImage = (
        <div className="imgPreview">
          {/* <FaCross onClick={this.removeImage.bind(this)} style={{marginRight: '5px', cursor: 'pointer'}}/> */}
          <span>
            <img src={this.state.preview} className="dropzonePreview" alt="Preview"/>
          </span>
        </div>
      )
    }

    let myImage2;
    // if (this.state.image.length === 0) {
    if (!this.state.preview2) {
        myImage2 = (
        <div className="imgPreview">
          No selected image
        </div>
      )
    } else {
      // console.log('myImage', this.state.preview)
      myImage2 = (
        <div className="imgPreview">
          {/* <FaCross onClick={this.removeImage.bind(this)} style={{marginRight: '5px', cursor: 'pointer'}}/> */}
          <span>
            <img src={this.state.preview2} className="dropzonePreview" alt="Preview"/>
          </span>
        </div>
      )
    }

    const path = '/' + name + '/icon';
    const rules = this.storeDef.fieldInfo;

    const header = <div>
      <div id="editDimmer">
        <div><img src="" id="editDimmerImg" alt="Dimmer"/>
          <div id="editDimmerText"></div>
        </div>
      </div>
      <div className="head">
        <HeaderComponent {...this.props} data={this.storeDef}/>
      </div>
      <div className='top'></div>

      <div className="wrapperEdit edit_box">
        <div className='editFlow'>
          <label className='editLabel'>ID</label>
          <TextField className='editField editFieldDisabled' name="ID"
                     fieldAttributes={{disabled: true, style: {border: 0, fontSize: '110%'}}}/>
        </div>
        <div className='editFlow'>
          <label className='editLabel'>Status</label>
          <SelectField className="editField" placeholder='select' name='Status' options={['Development', 'Review', 'Published']} />
        </div>
        {item.Sections && <div className='editFlow'>
            {/* <label className='editLabel'>Icon</label> */}
            <label className="editLabel">Title Image*</label>

            <div className="dropzoneimage">
              <div>
                <SelectField className='editField' placeholder="Select an image" name="tImage" onModelChange={(e)=>{
                  // console.log('txtfield', storeDef.url + "/" + item.Icon, e)
                  if(item.tImage) this.setState({preview2: `${process.env.PUBLIC_URL}/${item.tImage}`});
                  else this.removeImage.bind(this)
                }} options={this.storeDef.topicImages} />
              </div>
              {myImage2}
            </div>

          </div>}
        <div className='editFlow'>
          <label className='editLabel'>Title*<br/>{ rules && rules['Title'] && <Tooltip title={rules['Title']}><FaInfoCircle color="#1890ff"/></Tooltip> }</label>
          <TextField className='editField' name="Title"/>
          
        </div>
        <div className='editFlow'>
          {/* <label className='editLabel'>Icon</label> */}
          <label className="editLabel">Icon*<br/>{ rules && rules['Icon'] && <Tooltip title={rules['Icon']}><FaInfoCircle color="#1890ff"/></Tooltip> }</label>
          <div className="dropzone">
            <Dropzone onDrop={this.onDrop.bind(this)}>
              <p>Drop your image here, or click to select image to upload.</p>
            </Dropzone>
          </div>

          <div className="dropzoneimage">
            <span className="dropzoneTitle">Your selected image: </span>
            <div className="removeImage">
              <FaTimesCircle onClick={this.removeIcon.bind(this)} style={{marginRight: '5px', cursor: 'pointer'}}/>
              <TextField className='dropzoneUrlField' placeholder="Or write/paste URL" name="Icon" onModelChange={({name, value})=>{
                // console.log('txtfield', this.storeDef.url + "/" + item.Icon, name, value);
                if(item.Icon) this.setState({preview: this.storeDef.url + "/" + item.Icon});
              }}/>
            </div>
            {myImage}
            <span className="linkIcon">You can select an icon <Link to={path}>here</Link></span>
          </div>

        </div>
      </div>
    </div>

    const submit = <Button type='primary' onClick={submitMethod} className='editSave' icon={'check'}>Save</Button>
    const cancel = <Button type='primary' onClick={cancelMethod} className='editSave' icon={'close'}>Cancel</Button>

    // const wip = 
    //   <div className='editFlow'>
    //     <label id="toggleWipLabel" className='editLabel'>Admin mode</label>
    //     < Switch
    //       className="toggleWip"
    //       name="Wip"
    //       checked={this.state.checked}
    //       onChange={this.toggleWip}
    //     />
    //     <span className = "explain">When on, this item is visible only in admin mode</span>
    //   </div>

    const excludeSwitch = (
      <div className='editFlow'>
        <label id="toggleExcludeLabel" className='editLabel'>Exclude from search</label>
        < Switch
          className="toggleWip"
          name="Exclude"
          checked={this.state.excludeChecked}
          onChange={this.toggleExclude}
        />
        <span className = "explain">When on, this item is excluded from the search (this is useful for collections that appear in a topic page).</span>
      </div>
    );  

    const noRecommendations = (
      <div className='editFlow'>
        <label id="toggleNoReco" className='editLabel'>No Related Learning</label>
        < Switch
          className="toggleWip"
          name="NoReco"
          checked={this.state.noReco}
          onChange={this.toggleReco}
        />
        <span className = "explain">When on, this item will not include any Related Learning.</span>
      </div>
    );  

    // const flatSwitch = (
    //   <div className='editFlow'>
    //     <label id="toggleFlatLabel" className='editLabel'>Topic Page</label>
    //     < Switch
    //       className="toggleWip"
    //       name="Flat"
    //       checked={this.state.flatChecked}
    //       onChange={this.toggleFlat}
    //     />
    //     <span className = "explain">Transform this collection into a topic page where inner collections are expanded into sections.</span>
    //   </div>
    // );

    const addToCollSwitch = (
      B.pathname.match(/\/(create)\//) && (B.currentColl) && wipC.isNotWip(B.currentColl) ?
      // (B.currentColl !== wipC) && (B.currentColl !== wipC.unsaved) && (B.currentColl !== wipC.admin) ? 
      <div className='editFlow'>
        <label id="toggleAddToCollLabel" className='editLabel'>Append</label>
        < Switch
          className="toggleAddToColl"
          checked={this.state.addToCollChecked}
          onChange={this.toggleAddToColl}
        />
        <span className = "explain">Add this item to the collection {B.currentColl.Title}</span>
      </div> 
      : null
    );

    const otgSwitch = 
      <div className='editFlow'>
        <label id="toggleOtgLabel" className='editLabel'>ontheGO</label>
        < Switch
          className="toggleWip"
          name="Otg"
          checked={this.state.otgChecked}
          onChange={this.toggleOtg}
        />
        <span className = "explain">Add metadata for ontheGO mobile app</span>
      </div>

    // console.log('onthego', storeDef.ontheGO)
    const parentIds = dataCollections ? dataCollections.map( item => item.id ) : [];

    const selectedItemsMap = this.state.selectedItems.sort((a, b) =>  new Date(a.date)>new Date(b.date) ? -1 : new Date(a.date)<new Date(b.date) ? 1 : 0).sort((a, b) => (a.Owner === item.Owner) && (b.Owner !== item.Owner) ? -1 : (a.Owner !== item.Owner) && (b.Owner === item.Owner) ? 1 : 0 ).filter( item => !parentIds.includes(item.ID) ).map( (item, index) => 
          <div key={index} className="selectItem" title="Click to add this item"
               onClick={this.handleAdd.bind(this, item, true)}>
            <div className="selectItemTitle">
              {item.Title + (item.Solutions ? ' (Collection)' : '') + (item.date ? " - " + new Date(item.date).toString().slice(0, 15) : "")}
            </div>
          </div>
    );

    const selectedCollectionsMap = this.state.selectedItems.sort((a, b) =>  new Date(a.date)>new Date(b.date) ? -1 : new Date(a.date)<new Date(b.date) ? 1 : 0).sort((a, b) => (a.Owner === item.Owner) && (b.Owner !== item.Owner) ? -1 : (a.Owner !== item.Owner) && (b.Owner === item.Owner) ? 1 : 0 ).filter( item => (!parentIds.includes(item.ID) && item.Solutions) ).map( (item, index) => 
          <div key={index} className="selectItem" title="Click to add this item"
               onClick={this.handleAdd.bind(this, item, true)}>
            <div className="selectItemTitle">
              {item.Title + (item.Solutions ? ' (Collection)' : '') + (item.date ? " - " + new Date(item.date).toString().slice(0, 15) : "")}
            </div>
          </div>
    );

    const selectedSolutions = this.state.itemsSolutions.map((item, index) => {
      return (
        <div key={index} className="selectItem">
        <div className="delicon" title="Click to remove this item">
          <FaTrashAlt onClick={this.handleRemove.bind(this, item)} color="grey"/>
        </div>
          <div className="selectItemTitle">
            {item.Title + (item.Solutions ? ' (Collection)' : '') + (item.date ? " - " + new Date(item.date).toString().slice(0, 15) : "")}
          </div>
        </div>
      )
    });

    const selectedCollections = this.state.itemsSolutions.map((item, index) => {
      return (
        <div key={index} className="selectItem">
        <div className="delicon" title="Click to remove this item">
          <FaTrashAlt onClick={this.handleRemove.bind(this, item)} color="grey"/>
        </div>
          <div className="selectItemTitle">
            {item.Title + (item.Solutions ? ' (Collection)' : '') + (item.date ? " - " + new Date(item.date).toString().slice(0, 15) : "")}
          </div>
        </div>
      )
    });

    selectedSolutions.push(
      <div key='addItem' className="selectItem">
        <div>
          <div className='editFlow selectItems'>
            <Input className="selectSearch" onChange={this.handleSearch.bind(this)}  icon='search'
                    placeholder='Add existing items. Start typing to see proposals.'/>
            {selectedItemsMap}
            {this.moreItems && <div className="selectMore">Search for more...</div>}
          </div>
        </div>
      </div>

    )

    selectedCollections.length < 1 && selectedCollections.push(
      <div key='addItem' className="selectItem">
        <div>
          <div className='editFlow selectItems'>
            <Input className="selectSearch" onChange={this.handleSearch.bind(this)}  icon='search'
                    placeholder='Add existing items. Start typing to see proposals.'/>
            {selectedCollectionsMap}
            {this.moreItems && <div className="selectMore">Search for more...</div>}
          </div>
        </div>
      </div>

    )

    if (item.Solutions) {
      return (
        <Form model={item} eventsEmitter={eventsEmitter} onSubmit={data => {
        }}>
          {header}
          <div className="wrapperEdit edit_box">
            <Quill name='Description' value={item.Description} display='Description' />
            {this._setFields(this.storeDef.collectionMapping)}

            <div className='editFlow'>
              <div className="selectedItems">
                <Divider>Items</Divider>
                {!(selectedSolutions.length > 1) && <div className="selectedItemsEmpty">Empty</div>}
                {selectedSolutions}
              </div>
              

              

              {/* <div className='editFlow'>
                <label className='editLabel'></label>
                { <div className='editField'> }
                  <Button type='primary' className='editSave editField buttonAdd' onClick={() => {
                    B.currentColl = item;
                    console.log(item);
                    EditCtl.create('item');
                  } }>Add new Item</Button>
                  { <Button type='primary' className='editSave' onClick={() => {
                    B.currentColl = item;
                    EditCtl.create('collection');
                  } }>Collection</Button>
                </div> }
              </div> */}

            </div>
            {/* {wip} */}
            {/* {this.storeDef.topicPage && flatSwitch} */}
            {this.storeDef.topicPage && excludeSwitch}
            {/* {noRecommendations} */}
            {addToCollSwitch}
            {cancel}
            {submit}
          </div>
        </Form>
      );
    }
    
    if(this.storeDef.topicPage && item.Sections){
      return (
        <div>
          {this.state.visible && <ModalSection visible={this.state.visible} model={this.model} handleOk={() => this.handleOk(this.model)} handleCancel={this.handleCancel} handleDelSection={() => this.handleDelSection(this.model)} currId={currentID} ready={ready} selectedSolutions={selectedSolutions} selectedCollections={selectedCollections} name={name} />}
          <Form model={item} eventsEmitter={eventsEmitter} onSubmit={data => {
          }}>
            {header}
            <div className="wrapperEdit edit_box">
              {/* <Quill name='Description' value={item.Description} display='Description' /> */}
              {this._setFields(this.storeDef.topicMapping)}

              <div className='editFlow'>
                <div className="selectedItems">
                  <Divider>Sections</Divider>
                  {/* {this.state.sections.map((section, index) => {
                    return(
                      <div className="selectItem" key={index}>
                        {section.title}
                      </div>
                    );
                  })} */}
                  <SortableComponent items={this.state.sections} onSortEnd={this.onSortEnd} show={(section) => {this.showModal(section)}}/>
                </div>
                <label className="editLabel">Add a section</label>
                <div className="buttonGroup">
                    {/* <Button type='primary' onClick={() => {this.showModal({type: "title"})}} className='buttonModal' >Title</Button> */}
                    <div onClick={() => {this.showModal({type: "image"})}} className="iconModal"><FaImage color="darkorange"/><br/>Image</div>
                    <div onClick={() => {this.showModal({type: "video"})}} className="iconModal"><FaFilm color="darkorange"/><br/>Video</div>
                    <div onClick={() => {this.showModal({type: "objects"})}} className="iconModal"><FaBook color="darkorange"/><br/>Learnings</div>
                    <div onClick={() => {this.showModal({type: "text"})}} className="iconModal"><FaEdit color="darkorange"/><br/>Text</div>
                    <div onClick={() => {this.showModal({type: "collection"})}} className="iconModal"><FaFolder color="darkorange"/><br/>Collection</div>
                </div>

                {/* <div className='editFlow'>
                  <label className='editLabel'></label>
                  { <div className='editField'> }
                    <Button type='primary' className='editSave editField buttonAdd' onClick={() => {
                      B.currentColl = item;
                      console.log(item);
                      EditCtl.create('item');
                    } }>Add new Item</Button>
                    { <Button type='primary' className='editSave' onClick={() => {
                      B.currentColl = item;
                      EditCtl.create('collection');
                    } }>Collection</Button>
                  </div> }
                </div> */}

              </div>
              {/* {wip} */}
              {excludeSwitch}
              {/* {noRecommendations} */}
              {addToCollSwitch}
              {cancel}
              {submit}
            </div>
          </Form>
        </div>
      );
    }

    const otg = item.otgMetadata && this.state.otgChecked && 
      <div className='editFlow'>
        <label className='editLabel'>Meta Data</label>
        <JSONInput
          confirmGood={true}
          id='JsonInput'
          onChange={this.handleJson.bind(this)}
          placeholder={item.otgMetadata}
          theme='light_mitsuketa_tribute'
          locale={locale}
          height='300px'
          style={{
            outerBox: {display: 'inline-block'}
          }}
        />
      </div>
    return (
      <Form model={item} eventsEmitter={eventsEmitter} onSubmit={data => {
      }}>
        {header}
        <div className="wrapperEdit edit_box">
          {this._setFields(this.storeDef.mapping)}
          {this.storeDef.ontheGO && otgSwitch}
          {otg}
          {/* {wip} */}
          {addToCollSwitch}
          {excludeSwitch}
          {this.storeDef.Recommendations && noRecommendations}
          {cancel}
          {submit}
        </div>
      </Form>
    );
  }
}