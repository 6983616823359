/*
  @author Félix Fuin
  Copyright Nokia 2018. All rights reserved.
*/
import React, { Component } from 'react';
import {Col, Row } from 'antd';
import Header from '../components/Header'
import Footer from '../components/Footer';
import Source from '../tools/data';
// import {Icon} from 'antd';
import '../css/NotFound.css';

export default class NotFound extends Component {
  storeDef;
  render() {
    const {name} = this.props.match.params;
    this.storeDef = Source.getDef(name);
    return (
      <div id="hpage">
          <div className="head">
            <Header {...this.props} data={this.storeDef}/>
          </div>
          <Row type="flex" justify="center">
          <Col xs={22} sm={20} md={20} lg={18} xl={18}>
            <div className="notFound-welcome c">
              {/* <Icon type="file-search" theme="outlined" id="iconSearchNF"/> */}
              <h1 className="center"><p>Oops !</p></h1>
              <p className="center">{this.props.disconnected ? "You must be connected to see this page" : "We can't seem to find the page you're looking for"}</p>
            </div>
            <div>
              <img className="imgcenter" alt='' src={`${process.env.PUBLIC_URL}/img/nokiaer.png`}/>
            </div>         
        </Col>
        </Row>
      <Footer {...this.props} data={this.storeDef}/>
    </div>
    );
  }
}
