import React, { Component } from 'react';
//import {Link} from 'react-router-dom'
import '../App.css';
import '../css/IconLibrary.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Remote from '../util/remote';
import { BackTop, notification } from 'antd';
import AdvancedLoader from './AdvancedLoader'
import  FooterComponent  from './Footer';
import Source from '../tools/data';
import HeaderComponent from './Header';
import B from '../tools/back';
import 'antd/dist/antd.css';  // or 'antd/dist/antd.less'
//import config from '../../public/store.json';


const delim = 'Uimg/';
const library = Remote('iconLibrary', 'list', "https://learningstore.nokia.com/srv1");

const openNotificationWithIcon = (type, url) => {
    notification[type]({
      message: url + " copied into clipboard !",
      placement: "bottomRight",
    });
  };

export default class IconLibrary extends Component {

  state = { 
    isLoading: true,
    activeElement: 2,
  };

  storeDef;

  UNSAFE_componentWillMount() {
    if(B.images.length === 0){
      
      library.list().then( rep  => {
        let count = 0;
        const complete = () => {
          count++;
          if (count === rep.rep.length) this.setState({isLoading:false});
        }
        rep.rep.forEach( link => {
          const url = "https://learningstore.nokia.com" + link;
          const img = new Image();
          img.onerror = (evt) => {
            console.log('onload error', evt);
            complete();
          }
          img.onload = () => {
            const size = img.width + "x" + img.height;

            if(((img.width / img.height) >= 1.85) && ((img.width / img.height) <= 2.15)/*&&((img.height != 125)||(img.width != 250))*/){
              B.images.push([url, size]);
            }
            complete();
          }
          img.src = url;
        });
        })
      .catch( err => console.log(err) );
    }else{
        this.setState({isLoading: false});
    }
  }

  renderImage(url, size) {
    const shortUrl = delim + url.split(delim)[1];
    return (
      <CopyToClipboard text={shortUrl} key={shortUrl} onCopy={() => openNotificationWithIcon('success', shortUrl) }>
        <div className="imgb"><img src={url} alt={url} className="image" /><br/>{size}</div>        
      </CopyToClipboard>
    );
  }

  render() {

    const {name} = this.props.match.params;
    this.storeDef = Source.getDef(name);

    if (this.state.isLoading) return (
      <div className="container">
        <div className="head">
          <HeaderComponent {...this.props} data={this.storeDef} icon/>
        </div>
        <div className="loading">
          <AdvancedLoader />
        </div>
      </div>
    );

    return (
      <div className="container">
        <div className="head">
          <HeaderComponent {...this.props} data={this.storeDef} icon/>
        </div>
        <div className="images">
          <BackTop />
          {
            B.images.map( ([url, size]) => this.renderImage(url, size) ).filter( item => item )
          }
        </div>        
        <FooterComponent props={this.props} data={this.storeDef} />
      </div>
    );
  }
}
