import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
// import renderHTML from 'react-render-html';
import '../css/Store.css';
import Source from '../tools/data';
import HeaderComponent from './Header';
import FooterComponent from './Footer';
import EditCtl from '../tools/editCtl';
import { Table, Button, Icon, Input } from 'antd';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';
import  B  from '../tools/back';
import AdvancedLoader from './AdvancedLoader'
import '../css/Audit.css';
import moment from 'moment';


export default class Audit extends Component {
    state = { 
        isLoading: true,
        searchText: '',
    }

    data2 = [];

    data = {
        empty_catalogs: [],
        catalogs_with_LOs: [],
        empty_trainings: [],
        empty_topics: [],
        trainings_with_collections: [],
        empty_collections: [],
        not_excluded_LOs: [],
        collections_without_parents: [],
        trainings_without_parents: [],
        topics_without_parents: [],
        LOs_without_parents: [],
        too_short_trainings: [],
        LOs_without_description: [],
        trainings_without_description: [],
        topics_without_description: [],
        collections_without_description: [],
        collections_with_parents_other_than_Badge_Catalog: [],
        LOs_with_more_than_one_parent: [],
        LOs_with_Badge_Catalog_as_parent: [],
        Organization_Levels_with_parents_other_than_Organizations: [],
        Priority_Groups_with_Organizations_as_parent: [],
        Priority_Groups_without_parents: [],
        collections_with_parents_other_than_Admin_Panel: [],
        LOs_with_Admin_Panel_as_parent: [],
        old_admin_LOs: [],
        old_admin_LOs_days: [],
        old_admin_trainings: [],
        old_admin_topics: [],
        old_admin_trainings_days: [],
        old_admin_topics_days: [],
        old_admin_collections: [],
        old_admin_collections_days: [],
        items_without_title: [],
        items_without_ID: []
    }

    componentDidMount() {
        document.body.style.overflow = 'auto';
        window.scrollTo(0, 0);
        const {name} = this.props.match.params;
        console.log('Audit, I am going to fetch', name)
        Source.fetch(name)
        .then( store => {
            // console.log(store)
            this.store = store;
            let data = store.getAllData();
            if(data){
                let rules = this.storeDef.auditRules;
                // console.log(rules)
                // console.log(store.allData.length, "objects");
                if (rules)
                store.allData.forEach(elt => {
                    let days = Math.floor(moment.duration(moment(new Date()).diff(moment(new Date(elt.date)))).asDays());
                    
                    let parents = Source.getCollections(name, elt.ID);

                    if(rules.indexOf("items_without_title") > -1){
                        if(!elt.Title) this.data.items_without_title.push(elt);
                    }

                    if(rules.indexOf("items_without_title") > -1){
                        if(!elt.ID) this.data.items_without_ID.push(elt);
                    }
                    
                    if(elt.Solutions){
                        if(elt.Format === "Catalog"){
                            if(rules.indexOf("empty_catalogs") > -1){
                                if(elt.Solutions.length === 0) this.data.empty_catalogs.push(elt); 
                            }
                            if(rules.indexOf("catalogs_with_LOs") > -1){
                                let i=0;
                                if(elt.Solutions.length > 0) elt.Solutions.forEach(id => {
                                    if((!this.store.getByID(id).Sections) && (!this.store.getByID(id).Solutions)){
                                        if(i === 0) this.data.catalogs_with_LOs.push(elt);
                                        i++;
                                    } 
                                });
                            }
                        }
                        if(elt.Format === "Priority Group"){
                            if(rules.indexOf("Priority_Groups_with_Organizations_as_parent") > -1){
                                let i = 0;
                                if(parents) parents.forEach(parent => {
                                    if((parent.title === "Organizations") && (i===0)) this.data.Priority_Groups_with_Organizations_as_parent.push(elt);
                                    i++;
                                })
                            }
                            if(rules.indexOf("Priority_Groups_without_parents") > -1){
                                if(!parents) this.data.Priority_Groups_without_parents.push(elt);
                            }
                        }
                        if(elt.Format === "Organization Level"){
                            rules.forEach(rule => {
                                if(rule.startsWith("Organization_Levels_with_parents_other_than_")){
                                    // let parents = Source.getCollections(name, elt.ID);
                                    let title = rule.split("Organization_Levels_with_parents_other_than_")[1].replace("_", " ");
                                    if(parents){
                                        if(parents.length > 1) this.data[rule].push(elt);
                                        else if(parents.length === 1 && parents[0].title !== title){
                                            this.data[rule].push(elt);
                                            // console.log(parents)
                                        } 
                                    } 
                                }
                            })
                        }
                        if(rules.indexOf("empty_collections") > -1){
                            if(elt.Solutions.length === 0) this.data.empty_collections.push(elt); 
                        }
                        if(rules.indexOf("collections_without_parents") > -1){
                            if(!parents) this.data.collections_without_parents.push(elt);
                        }
                        if(rules.indexOf("collections_without_description") > -1){
                            if(!elt.Description) this.data.collections_without_description.push(elt);
                        }
                        if(rules.indexOf("old_admin_collections") > -1){
                            if (elt.Wip && days >= 10){
                                this.data.old_admin_collections.push(elt);
                                this.data.old_admin_collections_days.push(days);
                            } 
                        }

                        rules.forEach(rule => {
                            if(rule.startsWith("collections_with_parents_other_than_")){
                                // let parents = Source.getCollections(name, elt.ID);
                                let title = rule.split("collections_with_parents_other_than_")[1].replace("_", " ");
                                if(parents){
                                    if(parents.length > 1) this.data[rule].push(elt);
                                    else if(parents.length === 1 && parents[0].title !== title){
                                        this.data[rule].push(elt);
                                        // console.log(parents)
                                    } 
                                } 
                            }
                        })
                    }else if(elt.Sections){
                            if(elt.Format === "Training Solution"){
                                if(rules.indexOf("empty_trainings") > -1){
                                    if (elt.Sections.length === 0) this.data.empty_trainings.push(elt);
                                    else if(rules.indexOf("trainings_with_collections") > -1){
                                            elt.Sections.forEach(section => {
                                                if(section.type === "objects"){
                                                    section["objects_"+section.ID].forEach(item => {
                                                        if(item.Solutions) this.data.trainings_with_collections.push(elt);
                                                    })
                                                }
                                            });
                                        }    
                                }
                                if(rules.indexOf("trainings_without_parents") > -1){
                                    if(!parents) this.data.trainings_without_parents.push(elt);
                                }
                                if(rules.indexOf("too_short_trainings") > -1){
                                    if((elt.Sections.length > 0) && (elt.Sections.length < 2) ) this.data.too_short_trainings.push(elt);
                                }
                                if(rules.indexOf("trainings_without_description") > -1){
                                    if(!elt.Description) this.data.trainings_without_description.push(elt);
                                }
                                if(rules.indexOf("old_admin_trainings") > -1){
                                    if (elt.Wip && days >= 10){
                                        this.data.old_admin_trainings.push(elt);
                                        this.data.old_admin_trainings_days.push(days);
                                    } 
                                }
                            }
                            if((rules.indexOf("empty_topics") > -1) && (elt.Sections.length === 0)) this.data.empty_topics.push(elt);
                            if(rules.indexOf("topics_without_parents") > -1){
                                if(!parents) this.data.topics_without_parents.push(elt);
                            }
                            if(rules.indexOf("topics_without_description") > -1){
                                if(!elt.Description) this.data.topics_without_description.push(elt);
                            }
                            if(rules.indexOf("old_admin_topics") > -1){
                                if (elt.Wip && days >= 10){
                                    this.data.old_admin_topics.push(elt);
                                    this.data.old_admin_topics_days.push(days);
                                } 
                            }
                        }else{
                            if(rules.indexOf("not_excluded_LOs") > -1){
                                if(!elt.Exclude) this.data.not_excluded_LOs.push(elt);
                            }
                            if(rules.indexOf("LOs_without_parents") > -1){
                                if(!parents) this.data.LOs_without_parents.push(elt);
                            }
                            if(rules.indexOf("LOs_without_description") > -1){
                                if(!elt.Description) this.data.LOs_without_description.push(elt);
                            }
                            if(rules.indexOf("old_admin_LOs") > -1){
                                    if (elt.Wip && days >= 10){
                                        this.data.old_admin_LOs.push(elt);
                                        this.data.old_admin_LOs_days.push(days);
                                    } 
                            }
                            if(rules.indexOf("LOs_with_more_than_one_parent") > -1){
                                // let parents = Source.getCollections(name, elt.ID);
                                if(parents){
                                    if(parents.length > 1){
                                        this.data.LOs_with_more_than_one_parent.push(elt);
                                        if(rules.indexOf("LOs_with_Badge_Catalog_as_parent")){
                                            if(parents.length === 1 && parents[0].title === "Badge Catalog") this.data.LOs_with_Badge_Catalog_as_parent.push(elt);
                                        }
                                    }
                                } 
                            }
                        }
                })
                
                if(this.storeDef.auditRules) this.data2 = this.storeDef.auditRules.map((rule, index) => {
                    // let items = "<ul>";
                    // this.data[rule].forEach(item => {
                    //     items += "<li><a href='" + process.env.PUBLIC_URL + "/" + name + "/item/" + item.ID + "' target='blank'>" + item.Title + "</a></li>";
                    // });
                    // items += "</ul>";
                    return {
                        key: index,
                        elts: this.data[rule].length + " " +rule.replace(/_/g, " ")
                                                                .replace(/LOs/g, "learning objects")
                                                                .replace(/trainings/g, "training solutions")
                                                                .replace(/excluded/g, "excluded from search")
                                                                .replace(/too short training solutions/g, "training solutions with less than 2 sections")
                                                                .replace(/admin/g, "admin mode")
                                                                .replace(/topics/g, "topic pages"),
                        list: this.data[rule],
                        days: this.data[rule + "_days"]
                    }        
                })
                this.setState({isLoading: false});
            } 
        });
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm)}
              icon="search"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        ),
        filterIcon: filtered => (
          <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} className="filterTable" />
        ),
        onFilter: (value, record) => {
            let accept = false;
            record.list.forEach(elt => {
                if((elt.Title && elt.Title.includes(value.toLowerCase())) || (elt.Owner && elt.Owner.includes(value.toLowerCase())) || (elt.name && elt.name.includes(value.toLowerCase()))) accept = true;
            });
            return accept;
        },
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select());
          }
        },
      });
    
      handleSearch = (selectedKeys, confirm) => {
        confirm();
        this.setState({ searchText: selectedKeys[0] });
      };
    
      handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
      };

    render(){
        const {name} = this.props.match.params;
        this.storeDef = Source.getDef(name);
        
        if(!EditCtl.editMode) return (
            <Redirect to={'/'+name} />
        );

        if (this.state.isLoading) return (
            <div>
            <div className="head">
                <HeaderComponent {...this.props} data={this.storeDef}/>
            </div>
            <div className="loading">
                <AdvancedLoader />
            </div>
            </div>
        );
        // console.log(B.expandedKeys)

        B.set(this);

        if(!this.store) console.log('no data')
        // console.log('data2', this.data2)

        const columns = [
            { 
                title: 'Elements with problems', 
                dataIndex: 'elts', 
                key: 'elts',
                width: '100%',
                ...this.getColumnSearchProps('elts'),  
            },
          ];

        return(
            <div>
                <div className="head">
                    <HeaderComponent {...this.props} data={this.storeDef}/>
                </div>
                <div className="store audit">
                    <Table
                        columns={columns}
                        expandedRowRender={record => <ul style={{ margin: 0 }}>
                                                        {
                                                            record.list.map((elt, index) => 
                                                                <li key={index}>
                                                                    <Link to={process.env.PUBLIC_URL + "/" + name + "/item/" + elt.ID}>
                                                                        <Highlighter
                                                                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                                                                            searchWords={[this.state.searchText]}
                                                                            autoEscape
                                                                            textToHighlight={(!elt.Wip? "PUBLISHED: ": "") + elt.Title + (elt.Owner ? " - " + elt.Owner.replace(/<(?:.|\n)*?>/gm, '').trim() : "") + (elt.name ? " - " + elt.name : "") + (record.days ? " (" + record.days[index] + " days old) " : "")}
                                                                        />
                                                                    </Link>

                                                                </li>)

                                                        }
                                                     </ul>}
                        dataSource={this.data2}
                        className='auditTable'
                        pagination = {false}
                    />
                </div>
                <FooterComponent props={this.props} data={this.storeDef}/>
            </div>
            
        );
    }
}