/*
  @author Gilles Gerlinger
  Copyright Nokia 2017. All rights reserved.
*/

import md5 from 'md5';
import {Config} from '../config.js';

const hash = item => item && md5(item)
const editName = localStorage.editName || ''; // to filter the wip.admin collection to contain only the user's Wip
const getName = email => email && email.split('@')[0].match(/\w+$/)[0]

// TD: do not dump those items

const subWip = ['admin', 'unsaved', 'ready', 'review'];
const sortWip = [];
sortWip['admin'] = 1;
sortWip['ready'] = 2;
sortWip['review'] = 3;
sortWip['unsaved'] = 10;

const sortWipFix = (id) => sortWip[id] || 1000

class Wip {
  indexOf = id => this.Solutions.indexOf(id)

  constructor(arg) {
    Object.assign(this, arg);
  }

  save(id) {
    // console.log('pushing', id, 'to', this);
    if (this.indexOf(id) === -1) this.Solutions.push(id);
    if (this.unsaved) this.unsaved.removeID(id);
    this.sort();
  }
  
  unsave(id) {
    if (this.unsaved) {
      // console.log('unsaved', id)
      this.unsaved.save(id);
      wip.save('unsaved');
    }
  }
  
  removeID(tbr) {
    this.Solutions = this.Solutions.filter( id => id !== tbr );
  }
  // remove(i) {
  //   if (i > -1) this.Solutions[i] = '';
  // }
  
  stay() {
    if (!this.unsaved) return 0;
    const ret = this.unsaved.Solutions.filter(id => id).length;
    // this.unsaved.Icon = ret ? "Uimg/n.1525345187370.png" : null;
    return ret;
  }
  
  update(ID) {
    this.Solutions = this.Solutions.filter( id => id !== ID)
  }

  isNotWip(item) {
    return (item !== wip) && (item !== wip.unsaved) && (item !== wip.admin) && (item !== wip.ready) && (item !== wip.review)
  }

  clean() { wip.Solutions = wip.Solutions.filter( wid => subWip.indexOf(wid) > -1 ? wip[wid].Solutions.length > 0 : true ); }

  cleanWip(id) {
    // wip.removeID(id); //?
    // wip.unsaved.removeID(id);
    wip.admin.removeID(id);
    wip.ready.removeID(id);
    wip.review.removeID(id);
    // wip.Solutions = wip.Solutions.filter( wid => subWip.indexOf(wid) > -1 ? wip[wid].Solutions.length > 0 : true );
    this.sort();
  }

  process(item, edit) {
    // if (item.ID === 'n.1457426096681') {
    //   console.log('ICI', item)
    // }
    if (item.Status === 'Development') {
      item.Wip = true;
      // console.log('Development', edit, item)
      if (hash(item.name) === editName || edit) {
        this.cleanWip(item.ID);
        // wip.admin.Solutions.push(item.ID);
        wip.admin.save(item.ID);
        wip.save('admin');
      }
    }
    else if (item.Status === 'Review') {
      item.Wip = true;
      // console.log('review', item, edit)
      if (hash(getName(item.Reviewer)) === editName) {// item.Reviewer is benoit.vaillant@nokia.com
        this.cleanWip(item.ID);
        wip.review.Solutions.push(item.ID);
        wip.save('review');
      }
      else if (hash(item.name) === editName || edit) {
        this.cleanWip(item.ID);
        wip.ready.Solutions.push(item.ID);
        wip.save('ready');
      }
    }
    else if (item.Status === 'Published' && item.Wip) {
      this.cleanWip(item.ID);
      delete item.Wip;
      // wip.save(item.ID);
    }
    // wip.sort();
  }

  sort() { 
    wip.Solutions = wip.Solutions
      .filter( wid => subWip.indexOf(wid) > -1 ? wip[wid].Solutions.length > 0 : true )
      .sort( (a,b) => sortWipFix(a) < sortWipFix(b) ? -1 : 1 ); 
  }
}

const wip = new Wip({
  ID: 'wip',
  Title: '__ Workbench __',
  Description: "Shows items modified locally on your PC. Click 'Submit' to send them to the server. (You MUST be connected to Intranet to do it). Subfolders show work in progress both locally and on server.",
  Solutions: [],
  Exclude: true,
  _admin: true,
  Icon: Config.defaultIcon
});
wip.unsaved = new Wip({
  ID: 'unsaved', Title: 'Need to be saved*',
  Description: 'This is a dynamic collection that shows the items modified but not saved',
  Solutions: [],
  Exclude: true,
  _admin: true,
  Icon: "Uimg/Need_to_be_saved.png"
});
wip.admin = new Wip({
  ID: 'admin', Title: 'Under Development',
  Description: 'This collection shows the items not published where you are the last editor.',
  Solutions: [],
  Exclude: true,
  _admin: true,
  Icon: "Uimg/Under_development_editor.png"
});
wip.ready = new Wip({
  ID: 'ready', Title: 'Ready for Review',
  Description: 'This is a dynamic collection that shows the items that you assigned to their reviewer',
  Solutions: [],
  Exclude: true,
  _admin: true,
  Icon: "Uimg/Ready_for_review.png"
});
wip.review = new Wip({
  ID: 'review', Title: 'To be reviewed',
  Description: 'This is a dynamic collection that shows the items that were assigned to you for review',
  Solutions: [],
  Exclude: true,
  _admin: true,
  Icon: "Uimg/Under_review.png"
});

// wip.cart = new Wip({
//   ID: 'cart', Title: 'Shopping Cart',
//   Description: '',
//   Solutions: [],
//   Exclude: true,
//   _admin: true,
//   Icon: "Uimg/n.1525345187370.png"
// });

export default wip;
