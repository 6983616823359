/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, { Component, Fragment } from 'react'
import B from '../tools/back'
import Source from '../tools/data'
import renderHTML from 'react-render-html'
import EditCtl from '../tools/editCtl'
import {
  Button,
  Col,
  Icon,
  Modal,
  Row,
  Tag,
  Tooltip,
  // Divider,
  notification,
  Popover,
} from 'antd'
import { Link } from 'react-router-dom'
import QRCode from 'qrcode.react'
import '../css/Item.css'
import { FaInfoCircle } from 'react-icons/fa'
import Thumbnail from './Thumbnail'
import Remote from '../util/remote'
import Ctl from '../tools/editCtl'
import wipC from '../tools/editWip'
import ViewsAndLikes from '../components/viewsAndLikes'
import lz from 'lz-string'
import authService from '../util/authService'
import { Collapse } from 'antd'
import QueueAnim from 'rc-queue-anim'
import HeaderComponent from './Header'
// import DateField from './DateField'
// import { ListItemSecondaryAction } from '@material-ui/core'
import { Jsql } from 'web_jsql';


const { Panel } = Collapse

const csodp = Remote(
  'create',
  'printCatalog',
  'https://learningstore.nokia.com/srv1',
)

const urlDelim = '>>'

const csod = Jsql({ key: 'b43fdd98b1fd705ae4c3a10cf25aad8a', url: 'https://backend.store.dyn.nesc.nokia.net/jsql' })


const content = (
  <div>
    <span>
      The Nokia ontheGO app is a multifunctional learning tool, it allows mobile
      learning. How it works: download the app from the iOS or Play store (look
      for Nokia ontheGO). Scan the QR code with the app, the training content is
      downloaded to your phone and allows mobile learning (even offline). The
      learning objects could be PDF, html5, video and 360 video, gamifications
      and 3D models of our Nokia equipment (with support of AR).
    </span>
    <span style={{ color: 'red' }}>
      This is also the reason why a normal QR scanner cannot be used, since
      those only support web content, not yet 3D and offline viewing.
    </span>
  </div>
)

export default class Item extends Component {
  state = {
    isLoading: true,
    open: true,
    disabledSend: false,
    loading: false,
    iconLoading: false,
    verif: false,
    loadPDF: false,
    allCollections: false,
    clicked: false,
    hovered: false,
    show: true,
    currentUser: null
  }

  hide = () => {
    this.setState({
      clicked: false,
      hovered: false,
    })
  }

  handleHoverChange = (visible) => {
    this.setState({
      hovered: visible,
      clicked: false,
    })
  }

  handleClickChange = (visible) => {
    this.setState({
      clicked: visible,
      hovered: false,
    })
  }

  enterLoading = () => {
    this.setState({ loading: true })
  }

  enterIconLoading = () => {
    this.setState({ iconLoading: true })
  }

  onClickShow = () => {
    this.setState({
      show: !this.state.show,
    })
  }

   callback = (key) => {
    //console.log("key",key);
  }

  UNSAFE_componentWillMount() {
    document.body.style.overflow = 'hidden'

    const { name, id, reco } = this.props.match.params
    this.storeDef = Source.getDef(name)
    //console.log('affichages', this.storeDef.id)
    this.url = this.storeDef.url || '.'
    this.mapping = this.storeDef.mapping
    this.issueReport = this.storeDef.issueReport
    // console.log("componentWillMount")
    // ReactGA.initialize(storeDef.analytics);
    Source.getSync(name).then((store) => {
      this.setState({ isLoading: false, store: store })
      // console.log('ga', '/' + name + '/index.html#?item=' + id + (recommended ? '&recommended' : ''));
      B.pageview(
        '/' + name + '/index.html#?item=' + id + (reco ? '&recommended' : ''),
      )
      B.incView(name, id)
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { name, id, reco } = nextProps.match.params
    // console.log("componentWillReceiveProps", nextProps)
    // console.log('ga', '/' + name + '/index.html#?item=' + id + (recommended ? '&recommended' : ''));
    B.pageview(
      '/' + name + '/index.html#?item=' + id + (reco ? '&recommended' : ''),
    )
    B.incView(name, id)
  }

  printPDF(ID) {
    this.setState({ loadPDF: true })
    csodp
      .printCatalog(ID, 'learningObject')
      .then((rep) => {
        if (rep.err) {
          notification.error({
            message: 'Error',
            description: 'The request failed',
            placement: 'bottomRight',
          })
          this.setState({ loadPDF: false })
        } else {
          const byteCharacters = atob(rep.rep)
          const byteNumbers = new Array(byteCharacters.length)
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
          }
          // saveAs(new Blob([new Uint8Array(byteNumbers)], {type: 'application/pdf'}), title.replace(/ /g, '_') + '.pdf');
          window.open(
            'https://learningstore.nokia.com/customer/learning_object/' +
              ID +
              '.pdf',
            'tab',
          )
          this.setState({ loadPDF: false })
        }
      })
      .catch((err) => console.log(err))
  }

  handleSend = () => {
    let item = this.state.store.getByID(this.props.match.params.id)
    B.addToCart(item.ID, item.Title)
    this.setState({ disabledSend: true })
  }

  getBadgeUrl = (urlImg) => {
    let split = urlImg.split('/img/')
    if (split.length > 1)
      return split[0] + '/index.aspx#?item=n.' + split[1].split('.')[1]
    return ''
  }

  errorimg = (e) => {
    e.target.onError = null
    const item = this.state.store.getByID(this.props.match.params.id)
    e.target.src = item.Icon = '/img/CSOD-Icon-Material.png'
    wipC.save(item.ID)
  }

  onCn = (courseNumber) => {
    this.setState({ loading: true, iconLoading: true })
    // console.log('here')
    csod({ query: {"rst: csod.get":""}, variables: { courseNumber }})
    .then( rep => {
  // csod.get(courseNumber).then((rep) => {
      if (rep.error) {
        const msg = rep.err.split(':')
        notification.error({
          message: 'Error',
          description: msg[0] || 'error',
          placement: 'bottomRight',
        })
        this.setState({ loading: false, iconLoading: false })
        return
      }
      // rep = rep.rep
      rep = rep.data.rst      
      const item = this.state.store.getByID(this.props.match.params.id)
      const tmp = JSON.stringify(item)

      B.formatCSOD(rep, item)
      // If no named sections are found in the descripton, we just copy the full text from rep.Description
      if (
        !item.Description &&
        !item.Audience &&
        !item.Prerequisites &&
        !item.Objectives &&
        rep.Description
      )
        item.Description = rep.Description

      Ctl._push(tmp, item)
      wipC.save(item.ID)
      this.setState({ loading: false, iconLoading: false, verif: true })
    })
  }

  onLOID = (loid) => {
    this.setState({ loading: true, iconLoading: true })

    csod.getLO(loid).then((rep) => {
      if (rep.err) {
        const msg = rep.err.split(':')
        notification.error({
          message: 'Error',
          description: msg[0] || 'error',
          placement: 'bottomRight',
        })
        this.setState({ loading: false, iconLoading: false })
        return
      }
      rep = JSON.parse(rep.rep).data[0].trainingItem

      const item = this.state.store.getByID(this.props.match.params.id)
      // in the data returned by CSOD, "Description" is called "Descr" and the format is not avaiable, etc
      rep.Description = rep.Descr
      rep.TrainingSubType = item.Format
      rep.Duration = Number(rep.Duration)
      rep.Customfields = rep.Fields

      const tmp = JSON.stringify(item)

      B.formatCSOD(rep, item)
      // If no named sections are found in the descripton, we just copy the full text from rep.Description
      if (
        !item.Description &&
        !item.Audience &&
        !item.Prerequisites &&
        !item.Objectives &&
        rep.Description
      )
        item.Description = rep.Description

      Ctl._push(tmp, item)
      wipC.save(item.ID)
      this.setState({ loading: false, iconLoading: false, verif: true })
    })
  }

  reload = (item) => {
    if (item.cn) {
      //console.log('Reloading from cn', item.cn)
      this.onCn(item.cn)
    } else if (item.loid) {
      //console.log('Reloading from loid', item.loid)
      this.onLOID(item.loid)
    } //else console.log('item.js / reload: I should never come here')
  }

  print = (src, qrcode, item, dataCollections) => {
    let mywindow = window.open('', 'PRINT')

    mywindow.document.write(
      '<html><head><title>Nokia Learning Store - Employee Store</title>',
    )
    mywindow.document.write('<link rel="stylesheet" href=\'/print.css\'>')
    mywindow.document.write('</head><body >')
    mywindow.document.write(
      "<br><div class='itemTitle'>" + Source.format(item.Title) + '</div>',
    )
    mywindow.document.write("<img class='img' src='" + src + "' /><br>")
    if (qrcode) {
      mywindow.document.write("<img class='img' src='" + qrcode + "' /><br>")
      mywindow.document.write("<div class='title'>On the go</div>")
    }

    let content = this.mapping.map((field) => {
      const short = field.split('|')[0].replace('*', '')
      if (item[short])
        return (
          "<div><div class='title'> " +
          short +
          "</div>  <div class='itemFieldBody'> " +
          item[short] +
          '</div><br/></div>'
        )
      return null
    })

    if (dataCollections) {
      let collections = dataCollections.map(
        (coll) => " <div class='collectionPrint'>" + coll.title + '</div>',
      )
      content.push(
        "<div><div class='title'>Parent Collection(s)</div>  <div class='itemFieldBody'> " +
          collections +
          ' </div><br/></div>',
      )
    }

    for (let i = 0; i < content.length; i++) {
      content[i] && mywindow.document.write(content[i])
    }

    mywindow.document.write(
      '<script>function p(){window.print();window.close();};window.onload=p;</script>',
    )
    mywindow.document.write('</body></html>')
    mywindow.document.close() // necessary for IE >= 10
    mywindow.focus() // necessary for IE >= 10*/
  }

  render() {

    B.set(this)

    if (this.state.isLoading) return null

    // if (this.props.match.params.edit === "edit") EditCtl.switchEditMode(true, true);

    const editOrCloseBtn = (
      <Tooltip
        placement="bottom"
        title={<span>Edit this page</span>}
        overlayClassName="tool"
      >
        <Button
          type="primary"
          shape="circle"
          icon="edit"
          size={'large'}
          className="editButton"
          onClick={() => EditCtl.modify()}
        />
      </Tooltip>
    )

    const deleteBtn = (
      <Tooltip
        placement="top"
        title={<span>Archive this page</span>}
        overlayClassName="tool"
      >
        <Button
          type="primary"
          shape="circle"
          icon="delete"
          size={'large'}
          className="editButton del"
          onClick={() => EditCtl.del()}
        />
      </Tooltip>
    )

    let item = this.state.store.getByID(this.props.match.params.id)

    // let dateEdit = this.props.match.params.name == 'employlee'
    document.title = item.Title + ' | Nokia Learning Store'

    B.currentItem = item
    const back = (e) => {
      e.stopPropagation()
      document.body.style.overflow = 'auto'
      //console.log('back', B.back, this.props.history, this.props.location)
      if (B.back && this.props.history.length > 2) this.props.history.goBack()
      else
        this.props.history.push(this.props.location.pathname.split('/item/')[0])
    }
    let fields = this.mapping
      .filter((field) => !field.startsWith('_'))
      .filter((field) => !field.startsWith('Duration'))
      .filter((field) => !field.startsWith('Format'))
      .filter((field) => !field.startsWith('date'))
      .filter((field) => !field.startsWith('Reviewer'))

      .map((field, index) => {
        let disp = field.split('|disp:')[1]
        if (disp) disp = disp.split(/(=|\|)/)[0]
        field = field.split('|')[0]
        const short = field.replace('*', '')

        //console.log(item)
        if (item[short]) {
         
          if(short === 'Owner' && Source.format(item.Description)!=="" && renderHTML(`<div>${item[short]}</div>`) !== "" ) {
            // console.log('B.intranet', B.intranet)
            // console.log(item[short].replace(/(<|>)/g, ''))

            return this.state.currentUser ? (
              <div className="itemField" key={index}>

                <div className="styleContact">
                  <Collapse defaultActiveKey={['2']} >
                    <Panel
                      onClickShow={this.onClickShow}
                      header="Admin Information (visible to Nokia employees only)"
                      key="1"
                    >
                      <div style={{color:'red', marginBottom:'15px'}}>The information below is private to Nokia. It is only visible when connected as a Nokia employee. Do not share externally.</div>
                      <div>Owner(s): {Source.format(item.Owner)}</div> 
                      <div>Reviewer(s): {Source.format(item.Reviewer)}</div>
                      <div>Last editor: {Source.format(item.name)}</div>
                      <div>Last edited on{' '}{item.date && new Date(item.date).toISOString().split('T')[0]}</div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            ) : null
          }

          return (
            <Fragment key={index}>
                <div className="itemField" key={index} id={short}>
                  <Collapse defaultActiveKey={['0']} onChange={this.callback}>
                    <Panel
                      onClickShow={this.onClickShow}
                      header={disp || short}
                      key={index}
                    >
                      <QueueAnim delay={300} className="queue-simple">
                        <div className="demo-tbody" key="b">
                          <div className="itemFieldBody">
                            {renderHTML(`<div>${item[short]}</div>`)}
                          </div>
                        </div>
                      </QueueAnim>
                    </Panel>
                  </Collapse>
                </div>
            </Fragment>
          )
        }
        return null
      })


    const { name, reco } = this.props.match.params

    let url = item.Url ? item.Url.split(urlDelim) : ['']
    let target = '_blank'
    if (url[0].startsWith('login ')) {
      if (authService.app.getUser() || authService.getCLogin()) {
        url[0] = url[0].slice(6)
      } else {
        url[0] = `/${name}/login/${item.ID}/${lz.compressToBase64(
          url[0].slice(6),
        )}`
        target = '_self'
      }
    }

    //console.log('sid', item.sid, this.props.match.params.name)
    if (item.sid && item.sid !== this.props.match.params.name)
      // for link from employee to customer...
      url = [`https://learningstore.nokia.com/${item.sid}/#${item.ID}`]

    const src = Source.decodeIcon(item, this.url)

    const dataCollections = Source.getCollections(name, item.ID)

    let qrcode
    if (item.otgMetadata)
      qrcode = `https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=http://learningstore.nokia.com/employee/otg/${item.ID}.json`

    const issueReport =
      this.issueReport ||
      'https://www.yammer.com/nokia.com/#/threads/inGroup?type=in_group&feedId=10063907'

    // let recommendations = Source.getDef(name).Recommendations;

    let recommended = item.recommended
      ? item.recommended
          .map((id) => this.state.store.getByID(id))
          .filter((item) => item && !item.Wip)
          .map((item) => (
            <div className="thumbitem" key={item.ID}>
              <div className="box">
                <Thumbnail
                  props={this.props}
                  data={item}
                  store={this.storeDef}
                  recommended={true}
                />
              </div>
            </div>
          ))
      : ''

    const cartdisabled = B.shopcart.some((cart) => cart.id === item.ID)

    return (
      <div>
        <div className='head headcolor'> {/* Do not remove - Header is needed to retrieve the currentUser*/}
        <HeaderComponent
          isEditOnItem={false}
          setCurrentUser={p => this.setState({currentUser: p})}
          {...this.props}
          data={this.storeDef}
        />
        </div>


        <div style={{ margin: 0 }}>
          <div
            id="loader"
            className="printLoader"
            style={{ display: this.state.loadPDF ? 'block' : 'none' }}
          ></div>
        </div>
        <div className={this.state.loadPDF ? 'loadPrint' : ''}>
          <div id="editDimmer">
            <div>
              <img src="" id="editDimmerImg" alt="Dimmer" />
              <div id="editDimmerText"></div>
            </div>
          </div>

          {/* New modale with collapse & transition */}

          {/* <div className="demo-thead" key="a"> */}
            <Modal
              className='modal'
              onClickShow={this.onClickShow}
              // ref = {this.setRef}

              key={this.props.match.params.id}
              title={
                <Row
                  type="flex"
                  justify="space-between"
                  align="middle"
                  style={{ width: '97%' }}
                  className="titleModal"
                >
                  <div>
                    <Row type="flex" justify="center">
                      <img
                        onError={this.errorimg}
                        className="imageItem"
                        src={src}
                        alt="format csod not valid"
                        style={{minHeight:'180px'}}
                      />
                    </Row>

                    <p>{Source.format(item.Date)}</p>
                  </div>

                  <Col>
                    <div
                      className="textTitle"
                      style={
                        EditCtl.editMode && item.Wip ? { color: 'orange' } : {}
                      }
                    >
                      <strong> {Source.format(item.Title)} </strong>
                      <div>
                        <h6>
                          {' '}
                          {Source.format(item.Format)}{' '}
                          {Source.format(item.Duration)} {' '}
                          {this.state.currentUser && <div>
                            Last edited on{' '}
                            {item.date && new Date(item.date).toISOString().split('T')[0]}
                          </div>}
                        </h6>
                      </div>
                    </div>
                  </Col>

                  <Col>
                    {item.sid.startsWith('customer') && (
                      <Button
                        type="ghost"
                        onClick={() =>
                          this.printPDF(this.props.match.params.id)
                        }
                        className="printB"
                        icon={'printer'}
                      >
                        Print
                      </Button>
                    )}
                    {reco && (
                      <div className={'buttonReturn'}>
                        <img
                          onClick={back}
                          src={`${process.env.PUBLIC_URL}/img/return.jpg`}
                          alt="return"
                          width="25px"
                          height="25px"
                        />
                      </div>
                    )}
                  </Col>
                  <Col >
                    <Row type="flex" justify="center" >
                      {/* image QR */}
                      {/* <img id="QRCode" src={qrcode} alt=''/>  */}
                      {qrcode && (
                        <QRCode
                          size={120}
                          bgColor="transparent"
                          fgColor="#15418f"
                          value={`http://learningstore.nokia.com/employee/otg/${item.ID}.json`}
                        />
                      )}
                    </Row>
                    <Row type="flex" justify="center">
                      {qrcode && (
                        <Row type="flex" justify="center">
                          <div className="otg" id="itemFieldTitle ">
                            Use this QR code with the Nokia ontheGO app for
                            Mobile Learning&nbsp;
                            <Popover
                              content={content}
                              trigger="hover"
                              visible={this.state.hovered}
                              onVisibleChange={this.handleHoverChange}
                            >
                              <Popover
                                content={<div>{content}</div>}
                                trigger="click"
                                visible={this.state.clicked}
                                onVisibleChange={this.handleClickChange}
                              >
                                <FaInfoCircle
                                  style={{ color: '#15418f' }}
                                  size={20}
                                />
                              </Popover>
                            </Popover>
                          </div>
                        </Row>
                      )}
                    </Row>
                  </Col>
                </Row>
              }
              afterClose={(B.currentItem = null)}
              visible={this.state.open}
              onCancel={back}
              closable={reco ? null : true}
              width={990}
              maskClosable
              // className="fontNokia"
              zIndex={1000000}
              centered
              maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.55)' }}
              footer={
                <Row type="flex" justify="space-between" className="fontNokia">
                  <Col>
                    <ViewsAndLikes item={item} />
                  </Col>
                  <Col>
                    {EditCtl.editMode && (
                      <div className="lastModified">
                        {item.name && (
                          <div>
                            Last submitted by {item.name} on{' '}
                            {new Date(item.date)
                              .toISOString()
                              .replace('T', ' ')
                              .slice(0, 19)}
                          </div>
                        )}
                      </div>
                    )}
                    <a
                      className={'reportIssue'}
                      target={'_blank'}
                      href={issueReport}
                      rel="noopener noreferrer"
                    >
                      Report an issue
                    </a>
                    {EditCtl.editMode && (item.cn || item.loid) && (
                      <Button
                        style={{ margin: '0 10px' }}
                        icon={this.state.verif ? 'check' : ''}
                        loading={this.state.loading}
                        onClick={() => this.reload(item)}
                      >
                        Reload
                      </Button>
                    )}

                    {this.storeDef.hasCart &&
                    !EditCtl.editMode && ( //&& item.Format !== "Complimentary"
                        <Button
                          style={{ margin: '0 10px' }}
                          onClick={this.handleSend}
                          disabled={cartdisabled ? true : false}
                        >
                          <Icon type="info-circle" />
                          {cartdisabled ? 'Added' : 'Add to Info Cart'}
                        </Button>
                      )}

                    {item.NokiaLearn && ( // badge does not have Url
                      <a
                        className="reportIssue"
                        href={item.NokiaLearn}
                        title="Open in Nokia Learn"
                        onClick={() => {
                          B.pageview(
                            '/' + name + '/index.html#?launch=' + item.ID,
                          )
                          B.csOpen(name, item.ID)
                        }}
                        target={target}
                        rel="noopener noreferrer"
                      >
                          Nokia Learn
                      </a>
                    )}
                    {url[0] && ( // badge does not have Url
                      <a
                        className="itemLaunch"
                        href={url[0]}
                        title="Launch"
                        onClick={() => {
                          B.pageview(
                            '/' + name + '/index.html#?launch=' + item.ID,
                          )
                          B.csOpen(name, item.ID)
                        }}
                        target={target}
                        rel="noopener noreferrer"
                      >
                        <Button
                          type="primary"
                          style={{ backgroundColor: '#124191' }}
                        >
                          {url[1] || 'Open'}
                          <Icon type="right" theme="outlined" />
                        </Button>
                      </a>
                    )}
                  </Col>
                </Row>
              }
            >
              {EditCtl.editMode && (
                <div id="editItem">
                  {deleteBtn}
                  {editOrCloseBtn}
                </div>
              )}
              
              <Row className="fontNokia" >
                <Col>
                  <div className="modalText">
                    <div className="itemField">
                      {fields}
                      <div className="itemField">
                        {dataCollections && dataCollections.length ? (
                          <div>
                            {/* <div className="itemFieldTitle"> */}
                              <Collapse defaultActiveKey={['2']}>
                                <Panel 
                                  onClickShow={this.onClickShow}
                                  header="Included In"
                                  key="5000"
                                >
                                  <div className="Small">
                                    {dataCollections.map((coll, index) =>
                                      this.state.allCollections || index < 3 ? (
                                        <Link
                                          to={
                                            '/' + item.sid + '/item/' + coll.id
                                          }
                                          key={index}
                                        >
                                          <Tag className={'collectionLink'}>
                                            {' '}
                                            {coll.title}
                                          </Tag>
                                        </Link>
                                      ) : null,
                                    )}
                                    {dataCollections.length > 3 &&
                                      !this.state.allCollections && (
                                        <Tag
                                          className={'collectionLink'}
                                          onClick={() =>
                                            this.setState({
                                              allCollections: true,
                                            })
                                          }
                                        >
                                          ...
                                        </Tag>
                                      )}
                                  </div>
                                </Panel>
                              </Collapse>
                            </div>
                          // </div>
                        ) : null}
                      </div>

                      {this.storeDef.Recommendations && !item.noReco && item.recommended && item.recommended.length > 0 && (
                          <div id="Recommandations">
                            <div className="itemField">
                              <Collapse defaultActiveKey={['1']}>
                                <Panel
                                  onClickShow={this.onClickShow}
                                  header="Learning related to this topic"
                                  key="2"
                                >
                                    <div className="demo-tbody" key="b">
                                      <div className="itemFieldBody">
                                        {recommended}
                                      </div>
                                    </div>
                                </Panel>
                              </Collapse>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Modal>
          </div>
        </div>
      // </div>
    )
  }
}
