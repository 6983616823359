import React, { Component } from 'react';
import { Button, Modal, Table, Divider, Alert } from 'antd';
import Source from '../tools/data';
// import {TextField} from 'react-components-form';
// import lz from 'lz-string';
import '../css/ModalTags.css';
import getOrg from '../tools/getOrg';

const columns = [
  {
    title: 'Field',
    dataIndex: 'field',
  },
  {
    title: 'Value',
    dataIndex: 'value',
  },
];

const LabelTable = (props) => {
  return (
    <div>
      <Divider />

      <Table
        rowSelection={{
          type: 'checkbox',
          ...props.rowSelection,
        }}
        columns={columns}
        dataSource={props.data}
        pagination={{ pageSize: 5 }}
      />
    </div>
  );
};



export default class ModalTags extends Component {

  tagStringify = (list) => {
    let string = "";
    Object.keys(list).forEach((key, index) => {
      string += list[key];
      if (index < (Object.keys(list).length - 1)) string += ' | ';
    })
    return string;
  }

  storeDef = Source.getDef(this.props.match.params.name);

  state = {
    data: [],
    error: false,
    targetPerson: "",
    stringLabels: "",
    tags: [],
  }

  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let temp = {};
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      selectedRows.forEach(elt => {
        temp[elt.field] = elt.value;
      })
      this.setState({ tags: temp, stringLabels: this.tagStringify(temp) });
      // console.log(this.state.tags, this.state.stringLabels);
    },
  };

  searchPerson = () => {
    const fields = this.storeDef.userFields;
    getOrg(this.state.targetPerson)
      .then(r => {
        if (r.rep && r.rep === "no record") {
          throw new Error("email not matching to anyone");
        }

        // Extract Job from Position and create a new field
        //r.rep["Job Discipline Text"] = r.rep["Position"].split(">")[0].trim().slice(0, -1).trim()
        //r.rep["Job Discipline Text"] = r.rep["Position"].split(">")[0].trim().match(/.+(\d)*/)[0].trim()
        r.rep["Job Discipline Text"] = r.rep["Position"].split(">")[0].trim().match(/.+(\d)*/)[0].trim()
        console.log("Selected job is [", r.rep["Job Discipline Text"], "]")

        this.setState({
          data: Object.keys(r.rep).map((label, index) => {
            return {
              key: index,
              field: label,
              value: r.rep[label]
            };
          }).filter(elt => (fields.indexOf(elt.field.toLowerCase().trim()) > -1) || ((elt.field.match(/^L([0-9]{1,})/)) && elt.value))
        })
        this.setState({ error: false });
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true });
      })
  }

  render() {
    return (
      <Modal
        destroyOnClose={true}
        title={
            <p className="modalTitle">Create a new tag</p>
          }
        visible={this.props.visible}
        onCancel={this.props.handleCancel}
        closable
        width="70%"
        maskClosable
        className="modalTags"
        zIndex={10000000}
        centered
        maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.55)' }}
        footer={[
          this.state.stringLabels && <input key='textlabel' type='text' className='editField stringLabels' value={this.state.stringLabels} readOnly />,
          <Button className="buttonModal" key="back" onClick={this.props.handleCancel}>Cancel</Button>,
          this.state.stringLabels && <Button className="buttonModal" key="submit" type="primary" onClick={() => this.props.handleOk(this.state.tags)}>
            Confirm
                        </Button>
        ]}
      >
        <br />
        <p>
          Tags are properties matching Nokia profiles: jobs, regions, organizations.
          One tag can combine multiple properties. e.g "Procurement Staff in Espoo",
          "Everyone in Spain", "NSW  Engineers". They are build from existing Nokia Profiles.
          Start by entering the email of someone  belonging to the target audience.
          Then select relevant properties to build the tag.
          </p><br />
        <label className="editLabel">People like:</label>
        <input type='text' placeholder="joe.doe@nokia.com" className='editField emailLabel' onChange={(e) => this.setState({ targetPerson: e.target.value })} />
        <Button className="buttonModal searchLabel" key="back" onClick={this.searchPerson}>Get Profile</Button>
        {this.state.error && <Alert type='error' message='The request failed !' />}
        <div className="noTagList">
          {this.state.data && (this.state.data.length > 0) && !this.state.error && <LabelTable data={this.state.data} rowSelection={this.rowSelection} />}
          {(!this.state.data || (this.state.data.length === 0) || this.state.error) && <span>Enter an email address to show properties to build the tag.</span>}
        </div>
      </Modal>
    );
  }
}