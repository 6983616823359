/*
  @author Gilles Gerlinger
  Copyright 2021. All rights reserved.
*/

import B from './back';
import { Jsql } from 'web_jsql';
const jsqlGetOrg = Jsql({ key: 'efe919e10c4909b93d23f09e83dee84e', url: B.server + '/jsql' })

const query = `{ rep: ac_getOrg(email:"$email") { 
    NID
    _id
    HC
    L1
    L2
    L3
    L4
    L5
    Manager
    Division
    Market
    Region
    Country
    Location
    City
    HireDate
    Position
    Role
 } }`

let stack = null, semaphore = 0
const getOrg = email => new Promise( (resolve, reject) => {
    if (semaphore) stack.push([email, resolve]) // avoid reentrance
    else {
        stack = []
        semaphore = 1
        jsqlGetOrg({ query: query.replace('$email', email) })
        .then( r => {
            // console.log('dbg', r)
            const rst = r.data.rep && r.data.rep.NID ? r.data : { rep: 'no record' }
            resolve(rst)
            stack.forEach( ([mail, resolv]) => resolv(mail === email ? rst : { rep: 'no record' } ))
            semaphore = 0
        })
        .catch(e => reject(e) )
    }
})

export default getOrg
