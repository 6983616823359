/*
  @author Alexia Leguedois
  Copyright Nokia 2018. All rights reserved.
*/

import React, {Component} from 'react';
import '../css/gcc.css';
import { Popover, Button, Row, Col } from 'antd';
import {FaTimes } from 'react-icons/fa';
import { Modal} from 'antd';

export default class Gcc extends Component {

  state = {
    visible: false,
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  }
  
  hide = () => {
    this.setState({
      visible: false,
    });
  }
  
  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  render() {
    const content = (
      <div style={{color:"#0f4193",padding:"5px"}}><p> Learning Help & Support </p></div>
    );

    return (
      <div>
      <Popover content={content} className="p">
      <img onClick={this.showModal} className="ask" src={process.env.PUBLIC_URL + "/img/help-square.png"} alt="Access" />
      </Popover>
        <Modal
        // eslint-disable-next-line
          title={<div className="popover"><h2>Learning Help & Support</h2><a onClick={this.hide}><FaTimes className="ask-close"></FaTimes></a></div>}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          closable = {false}
          style={{top: 50}}
          width={'70%'}
          zIndex={1000000000}
          footer={<div>NokiaEDU Global Contact Center</div>}
        >  
          <Row>
          The Global Contact Center (GCC) is your primary point of contact for NokiaEDU and is available to 
          efficiently answer your learning-related questions.
          </Row> 
          <br />         
          <Row gutter={4}>
            <Col span={4}>
              <Button type="primary" href="mailto:gcc.nokiaedu@nokia.com">Global Contact</Button>
            </Col>
            <Col span={4}>
            <Button type="primary" href="mailto:nokiaedu_china.contact@nokia-sbell.com">China Contact</Button>
            </Col>
            <Col span={4}>
            <Button type="primary" href="https://learningstore.nokia.com/gcc/index.html">Gcc webpage</Button>
            </Col>
          </Row>
          <br />
          <Row>
            <h3>Regional Call Numbers and Hours</h3>
          </Row> 
          <br />
          <Row gutter={16}>
            <Col span={8}>
              <p style={{textAlign:"justify"}}>Americas Working Hours <br />
            +1 888 582 3688<br />
            +1 469 646 4025<br />
            8:00am - 6:00pm (Local Time)</p>
            </Col>
            <Col span={8}>
              <p style={{textAlign:"justify"}}>EMEA Working Hours<br />
            +33 2 9604 7500 (France)<br />
            8:00am - 5:00pm (Local Time)<br />
            +40 256 34 3732 (Romania)<br />
            8:00am - 5:00pm (Local Time)</p>
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col span={8}> 
              <p style={{textAlign:"justify"}}>China <br />
              +86 21 2082 5000</p>
            </Col>
            <Col span={8}> 
              <p style={{textAlign:"justify"}}>India and APJ Working Hours<br />
              +612 8306 6661 (Singapore)<br />
              9:00am - 7:30pm Eastern Times </p>  
            </Col>
          </Row>
        </Modal>
      </div>
    );
  }

}

// /*
//   @author Alexia Leguedois
//   Copyright Nokia 2018. All rights reserved.
// */

// import React, {Component} from 'react';
// import '../css/gcc.css';
// import { Popover } from 'antd';
// import {FaTimes } from 'react-icons/fa';
// import { Modal} from 'antd';
// import Iframe from 'react-iframe'

// export default class Gcc extends Component {

//   state = {
//     visible: false,
//   }

//   showModal = () => {
//     this.setState({
//       visible: true,
//     });
//   }

//   handleOk = (e) => {
//     console.log(e);
//     this.setState({
//       visible: false,
//     });
//   }

//   handleCancel = (e) => {
//     console.log(e);
//     this.setState({
//       visible: false,
//     });
//   }
  
//   hide = () => {
//     this.setState({
//       visible: false,
//     });
//   }
  
//   handleVisibleChange = (visible) => {
//     this.setState({ visible });
//   }

//   render() {
//     const content = (
//       <div style={{color:"#0f4193",padding:"5px"}}><p> Learning Help & Support </p></div>
//     );

//     return (
//       <div>
//       <Popover content={content} className="p">
//       <img onClick={this.showModal} className="ask" src={process.env.PUBLIC_URL + "/img/help-square.png"} alt="Access" />
//       </Popover>
//         <Modal
//           title={null}
//           visible={this.state.visible}
//           onOk={this.handleOk}
//           onCancel={this.handleCancel}
//           closable = {false}
//           footer={null}
//         >
//         <div><FaTimes onClick={this.hide} className="ask-close" style={{color:"rgb(140, 140, 140)"}}></FaTimes></div>
        
//         <Iframe url="https://nokia.sharepoint.com/sites/gcc"
//           width="450px"
//           height={String(window.innerHeight-190)}
//           display="initial"
//           position="relative"
//           allowFullScreen/>          
//         </Modal>
//       </div>
//     );
//   }
// }