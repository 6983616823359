/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, {Component} from 'react';
import Menu, {Item as MenuItem, SubMenu} from 'rc-menu';
import {FaAngleDown} from 'react-icons/fa';
import Edit from '../tools/editCtl';
import wipC from '../tools/editWip';
import B from '../tools/back';
import { Badge, Row, Button, Tooltip, notification } from 'antd';
import Source from '../tools/data';

import 'antd/dist/antd.css';
import 'rc-menu/assets/index.css';
import '../css/NavigationEdit.css';



localStorage.edit = localStorage.edit || '[]';
// const localify = (data, pos) => localStorage.edit = JSON.stringify(data, null, 2);
const getStorage = () => JSON.parse(localStorage.edit);
// const resetStorage = () => localStorage.edit = '[]';


export default class Navigation extends Component {
  
  state = {count: 0};


  onClick(info) {
    switch (info.key) {
      // case 'c': 
      //   Edit.clipboard();
      //   break;
      case 'd':
        Edit.dump();
        break;
      case 'e':
        Edit.modify();
        break;
      case 'f':
        Edit.create('collection');
        break;
      case 'h':
        B.history.push('/');
        break;
      case 'l':
        Edit.showLog();
        break;
      case 'n':
        Edit.create('item');
        break;
      case 'nt':
        Edit.create('topic');
        break;
      case 'o':
        Edit.open();
        break;
      case 'r':
        Edit.reset();
        break;
      case 's':
        Edit.saveAs();
        break;
      case 'ex':
        Edit.switchEditMode(false, true);
        // console.log('edd', this.props.props.match.params.name);
        if (this.props.props.match.params.name) {
          B.history.push(`/${this.props.props.match.params.name}`);
        } else {
          B.history.push('/');
        }
        break;
      case 'cl':
        if(B.back){
          this.props.props.history.goBack();
        }
        break;
      case 'u':
        Edit.upload();
        break;
      case 'w':
        // Edit.switchEditMode(true, false);
        Edit.gotoWip();
        break;
      case 'x':
        Edit.del();
        break;
      case 'y':
        Edit.redo();
        break;
      case 'z':
        Edit.undo();
        break;
      case 'csod':
      if (this.props.props.match.params.name) {
        B.history.push(`/${this.props.props.match.params.name}/csod`);
      } else {
        B.history.push('/');
      }
      break;
      case 'gc':
        if (this.props.props.match.params.name) {
          B.history.push(`/${this.props.props.match.params.name}`);
        } else {
          B.history.push('/');
        }
        break;
      case 'gr':
        window.open('https://nokia.sharepoint.com/:b:/s/LearningStoreContentAdministration/EQkup_sOnhZNgT2jt0KR1AMBkourw9W6RbDbIfNNPNPuHQ?e=fxRBES', 'LS Rules');
        break;
      case 'cln':
        let firstElt = Edit._getItem();
        if (firstElt && firstElt.item){
          let item = JSON.parse(JSON.stringify(firstElt.item));
          item.ID = 'n.' + new Date().getTime();
          item.Title = 'Clone of ' + firstElt.item.Title;
          item.Sections.forEach(section => {
            Edit.sleep(10);
            section.ID = 's.' + new Date().getTime();
            Object.keys(section).forEach(key => {
              if ((key !== 'type') && (key !== 'ID')){
                if(key.startsWith("objects")) section[key.split('_')[0] + '_' + section.ID] = [...section[key]]; 
                else section[key.split('_')[0] + '_' + section.ID] = section[key];
                delete section[key];
              }
            });
          });
          item.del = true;
          B.origin[item.ID] = JSON.stringify(item);
          delete item.del;
          Edit.update(item);
          Edit._push(B.origin[item.ID], item);
          wipC.save(item.ID);
          B.history.replace(`/${this.props.props.match.params.name}/item/wip`);
          console.log(firstElt.item, item);
        }
        break;
      case 'au':
          if (this.props.props.match.params.name) {
            B.history.push(`/${this.props.props.match.params.name}/audit`);
          } else {
            B.history.push('/');
          }
        break;
      default:
    }
  }

  cancelMethod = () => {
    if(this.props.props.location.state && this.props.props.location.state.from === 'csod'){
      notification.error({
        message: 'Error',
        description: "You should edit all the required fields and save",
        placement: 'bottomRight'
      });
    }else {
      if(B.back) this.props.props.history.goBack()
    }
  }

  render() {
    const { name } = this.props.props.match.params;
    const submit = getStorage();
    const storeDef = Source.getDef(name);
    let unik = [], countwip = 0;
    submit.forEach(item => {
      if (/*!item.new.del &&*/ item.new.sid === name && !unik[item.new.ID]) {
        unik[item.new.ID] = item;
        countwip++;
      }
    });

    const createCollection = B.pathname.split('/')[2] === 'edit' || B.pathname.split('/')[2] === 'create' ? <MenuItem style={{color: 'grey'}}>Create collection</MenuItem> : (
                          <MenuItem key="f">Create new collection</MenuItem>
                       );
    const createItem = B.pathname.split('/')[2] === 'edit' || B.pathname.split('/')[2] === 'create' ? <MenuItem style={{color: 'grey'}} className="divide">Create item</MenuItem> : (
                        <MenuItem key="n" className="divide">Create new item</MenuItem>
                      );
    const createTopic = B.pathname.split('/')[2] === 'edit' || B.pathname.split('/')[2] === 'create' ? <MenuItem style={{color: 'grey'}} className={storeDef.topicPage ? '' : "divide"}>Create topic page</MenuItem> : (
                        <MenuItem key="nt" className={storeDef.topicPage ? '' : "divide"}>Create new topic page</MenuItem>
                      );

    const elt = Edit._getItem();
    let item;
    if(elt) item = elt.item;
    const cloneTopic = item && item.Sections && ( B.pathname.split('/')[2] === 'edit' || B.pathname.split('/')[2] === 'create' ? <MenuItem style={{color: 'grey'}} className={storeDef.topicPage ? '' : "divide"}>Clone this page</MenuItem> : (
                        <MenuItem key="cln" className={storeDef.topicPage ? '' : "divide"}>Clone this page</MenuItem>
                      ));
    const editOrClose = B.pathname.match(/\/(edit|create)\//) ? <MenuItem key="cl" className="divide">Close</MenuItem> : <MenuItem key="e" className="divide">Edit this page</MenuItem>;

    const editOrCloseBtn = B.pathname.match(/\/(edit|create)\//)
     ? <Tooltip placement="bottom" title={<span>Close this page</span>}><Button type="primary" shape="circle" icon="close" size={'large'} className='editButton' onClick={this.cancelMethod} /></Tooltip>
     : <Tooltip placement="bottom" title={<span>Edit this page</span>}><Button type="primary" shape="circle" icon="edit" size={'large'} className='editButton' onClick={() => Edit.modify()} /></Tooltip>

     const deleteBtn = <Tooltip placement="top" title={<span>Archive this page</span>} ><Button type="primary" shape="circle" icon="delete" size={'large'} className='editButton del' onClick={() => Edit.del()} /></Tooltip>

    return (
      <div>
        <Row type="flex" justify="center">
          <div className={this.props.isDisplayOnItem ? 'containerEditItem' : 'containerEdit'}>
            <div className="navigationEdit" id="navigationEdit">
              <Menu
                onClick={(info) => this.onClick(info)}
                mode="horizontal"
              >
                <MenuItem className="floatRight" key="u">Submit</MenuItem>
                <MenuItem className="floatRight" key="au">Audit Tool</MenuItem>
                <SubMenu className="floatRight" title={<span>Edit <FaAngleDown color='#7C7B7B'/></span>}>
                  {storeDef.generalRules && <MenuItem key="gr" className="divide">Read the General Rules</MenuItem>}
                  {createCollection}                  
                  {storeDef.topicPage && createTopic}
                  {storeDef.topicPage && cloneTopic}
                  {storeDef.createItem && createItem}
                  {storeDef.import && <MenuItem key="csod" className = "divide">Import from NLDH</MenuItem>}
                  {editOrClose}
                  <MenuItem key="x">Archive this page</MenuItem>
                </SubMenu>
                <SubMenu className="floatRight" title={<span>Workbench<Badge count={countwip}></Badge> <FaAngleDown color='#7C7B7B'/></span>}>
                  <MenuItem key="w" className = "divide">Go to Workbench</MenuItem>
                  <MenuItem key="z">Undo the last saved operation</MenuItem>
                  <MenuItem key="y" className="divide">Redo the last undone operation</MenuItem>
                  <MenuItem key="r">Reset all saved operations</MenuItem>
                </SubMenu>
                <SubMenu className="floatRight" title={<span>File <FaAngleDown color='#7C7B7B'/></span>}>
                  <MenuItem key="gc" className="divide">Go home</MenuItem>
                  <MenuItem key="s">Download to a local file all modifications saved</MenuItem>
                  <MenuItem key="o" className="divide">Load one or more files</MenuItem>
                  <MenuItem key="ex">Exit admin mode</MenuItem>
                </SubMenu>
              </Menu>
            </div>
          </div>
        </Row>
        {deleteBtn}
        {editOrCloseBtn}
      </div>
    );
  }
}