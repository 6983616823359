import React, {Component} from 'react';
import { PulseLoader as Loader } from 'halogenium'
import { Card } from 'antd';
import {  FaSignInAlt } from 'react-icons/fa'

export default class ConnectionRequest extends Component {

  loadingMessage() {
    return <Card title="Authentication..." style={{ width:'50%', marginTop:'200px', marginLeft:'auto', marginRight:'auto' }} >
      <Loader color="#124191" size="18px" margin="4px"/>
      <p style={{marginTop:'20px'}}>
          If this message persists, <span style={{fontWeight: 'bold', color:'#DC143C'}}>connect with the icon on the top-right corner</span>.
          (<FaSignInAlt  style={{position: 'relative', top: '5px', marginLeft:'5px', marginRight:'5px', color:'#C0C0C0'}} size={20} />)
      </p>
    </Card>
  }  

  render() {
     return this.loadingMessage()
  }

}