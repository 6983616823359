/*
  @author championne du monde
  Copyright Nokia 2019. All rights reserved.
*/
import React, {Component} from 'react';
import Source from '../tools/data';
import {Row,Col, Icon, Button, notification, Input, Radio, Form, 
Divider, Checkbox, Modal, InputNumber} from 'antd';
import '../css/ShoppingCart.css'
import FooterComponent from './Footer';
import HeaderComponent from './Header';
import Stepper from 'react-stepper-horizontal';
import B from '../tools/back.js';
import Select from 'react-select';
import { FaTrash } from 'react-icons/fa';
import shopcartItem from '../ressources/shopcart.json';
import cart from '../tools/cart.js';

const RadioGroup = Radio.Group;
const optionsShopcart = shopcartItem.selectOption; //json file
const ContactMailCart = shopcartItem.ContactMail; //json file

export default class ShoppingCart extends Component {  

    constructor() {
        super();
        this.state = {
            fields: {},
            errors: [],
            selectedOption: '',
            ContactMail:'',
            checked: false,
            Cnumber: null,
            disabled: false,
            shop: [],
            value1: 'yes',
            value2: 'yes',
            disabledSend: false,
          steps: [{
            title: 'Your learning',
            onClick: (e) => {
                e.preventDefault()
              }
            }, {
            title: 'Start Your Learning Request (1/2)',
            onClick: (e) => {
                e.preventDefault()                
              }
          }, {
            title: 'Information Request (2/2)',
            onClick: (e) => {
                e.preventDefault()                
              }
          }, {
            title: 'Nokia Contact',
            onClick: (e) => {
                e.preventDefault()
              }
          }],
          currentStep: 0,
        };
        this.onClickPrevious = this.onClickPrevious.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
      }
      

    UNSAFE_componentWillMount(){
        window.scrollTo(0, 0);
    const {name,/*id*/} = this.props.match.params;        
        this.storeDef = Source.getDef(name);
        this.url = this.storeDef.url || '.';
        this.mapping = this.storeDef.mapping;
        this.issueReport = this.storeDef.issueReport;
        Source.getSync(name).then(store => {
            this.setState({isLoading: false, store: store});        
            B.pageview('/' + name + '/shoppingcart');
        });
    }

    onClickNext() {
        const { currentStep } = this.state;
        
        let fields = this.state.fields;        
        let errors = [];
        let formIsValid = true;
        const regEmail = new RegExp('^[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}$','i');
        // const tel = new RegExp('(0|\\+33|0033)[1-9][0-9]{8}');

         if(!fields["CName"]){
            formIsValid = false;
            errors["CName"] = "Cannot be empty";
         }
         if(!fields["CCompany"]){
            formIsValid = false;
            errors["CCompany"] = "Cannot be empty";
         }
         if(regEmail.test(fields["CEmail"]) === false){
             formIsValid = false;
             errors["CEmail"] = "Please enter a valid email address"
         } 
         if(!fields["CTel"]){
            formIsValid = false;
            errors["CTel"] = "Cannot be empty";
         }

         if(formIsValid === false && currentStep === 1){
             this.setState({errors: errors});
         }
         else {
            this.setState({currentStep: currentStep + 1});
         }       

       return formIsValid;
    }

    onClickPrevious(){
        const { currentStep } = this.state;
        this.setState({
            currentStep: currentStep - 1,
        });
    }

    toggleChecked = () => {
        this.setState({ checked: !this.state.checked });
    }      

    handleRemove(el) {
        const self = this, shop = B.shopcart;
        Modal.confirm({
            title: 'Do you really want to remove this item ?',          
            onOk() {
                B.removeFromCart(el);
                self.setState({shop: shop});
            }
        });
    }

    onChange = (e) => {
        this.setState({
          checked: e.target.checked,
        });
    }
    onchangeCnb = (value) => {
        this.setState({
            Cnumber: value,
        });       
    }

   contactSubmit(e){
        e.preventDefault();
    }

    handleChange(field, e){       
        let fields = this.state.fields;
        (e.target.type === "checkbox") ? fields[field] = (e.target.checked ? e.target.value : null) : fields[field] = e.target.value;      
        this.setState({fields});
    }

    handleChangeSelectOption = (selectedOption) => {
        this.setState({ selectedOption });
    }

    handleChangeContactCart = (contactMail) => {
        this.setState({contactMail})
        // console.log(contactMail)
    }

    handleSend = () => {
        this.setState({disabledSend:true});
        let { selectedOption, contactMail } = this.state;

        const WebForm = {id: B.shopcart, ...this.state.fields, IQuote: this.state.checked === true ? true : false, 
            CLocation: selectedOption.value || '', CNumber: this.state.Cnumber || 0, Smail: contactMail.value }
        const emailBody = cart(WebForm);

        const post = {sender:WebForm.CEmail, ContactCenter:contactMail.value,bodyGCC:emailBody[0], bodyCustomer:emailBody[1]}
        // console.log (post)
        fetch('https://prod-10.westeurope.logic.azure.com:443/workflows/bcbe9330fe1d402a9c99f5b52ef978ea/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=41CYRM-L2aXTfWQMhl6YCCwqVLeMh_ItJqx1j8JjK4I', {
          method:'POST',
          headers: {'Content-type': 'application/json'},
          body:JSON.stringify(post)
        })
        .then( () => {localStorage.removeItem('shopcart'); B.shopcart = []; this.openNotificationSend('success')});                  
    }

    openNotificationSend = (type) => {
        let { contactMail } = this.state;
        notification[type]({
          placement: 'bottomRight',
          message: 'Notification',
          description: <div>Your request has been sent to <p><b>{contactMail.value}</b></p></div>,
        });
    };  

    render(){ 
        let {disabledSend} = this.state;     
        const { steps, currentStep, selectedOption, contactMail} = this.state;
        const clickNext = currentStep === 3 ? this.handleSend : this.onClickNext;
        const clickPrev = currentStep === 0 || disabledSend ? true : false;  
        this.contact = this.state.contactMail;
        
        // const contactMail = ["learningoffers.emea@nokia.com","learningoffers.americas@nokia.com","learningoffers.apac@nokia.com"]
        

        if(B.shopcart.length/* or this.state.shopcart*/ === 0){
        return (
            <div>
                <div className="head">
                    <HeaderComponent {...this.props} data={this.storeDef}/>
                </div>
                <Row type="flex" justify="center">
                <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                    <div className="shopcart-home">
                        <p>Your Info Cart is empty. </p>
                        <p>Navigate the store and clik the "Add to Info Cart" button to add items.</p>
                        {/* <p>Your cart is empty for the moment</p> */}
                    </div>   
                </Col>
                </Row>             
                <FooterComponent props={this.props} data={this.storeDef}/>
            </div>
        )
        } else {
            return(
                <div>
                    <div className="head">
                        <HeaderComponent {...this.props} data={this.storeDef}/>                        
                    </div>
                    <Row type="flex" justify="center">
                    <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                        <div className="shopcart-home">
                            <div className="shopcart-head">
                                <h2>Business to Business Information Cart</h2>                                
                                <p>NokiaEDU provides customers with Nokia product and technology learning solutions. Please follow the 'Next step' button
                                    in the corner below to contact a NokiaEDU representative who will help you with this training or additional training inquires.</p>
                                <p>Individuals must provide their company name for a pricing quote of for additional information; NokiaEDU learning products are
                                    not available for individual sale.</p>                        
                            </div>                            
                            <div className="shopcart-head">                            
                                <Stepper steps={ steps } activeStep={ currentStep } /> 
                                <Divider></Divider>                               
                                <Row type="flex" justify="center">
                                <Col xs={22} sm={20} md={20} lg={18} xl={18}>
                                {currentStep === 0 && 
                                <div className='cart-step'>
                                    {B.shopcart.map( (item, index) => 
                                        <div className="card" key={index}>                                                                              
                                        <FaTrash onClick={this.handleRemove.bind(this, item)} style={{float:'right',fontSize:"25px"}}></FaTrash>
                                            {item.title}
                                        </div>
                                    )}                                    
                                </div>}
                                {/* currentStep === 1 */}
                                {currentStep === 1 && 
                                <div className='cart-step'>
                                    <p>Please share with Nokia your contact information and question(s) in order to receive either
                                        a pricing quote or additional information about a learning solution. We will give your request 
                                        our utmost attention and an email or phone reply. Thank you for contacting NokiaEDU.</p>
                                        <Form name="contactform" className="contactform" onSubmit= {this.contactSubmit.bind(this)}>
                                            <span className="label bordershare">Name *</span>        
                                            <Form.Item>         
                                                <Input ref="CName" name="CName" required placeholder='John DOE' onChange={this.handleChange.bind(this, "CName")} value={this.state.fields["CName"]}/>
                                                <span style={{color: "red"}}>{this.state.errors["CName"]}</span>         
                                            </Form.Item>
                                            
                                            <span className="label bordershare">Company *</span>
                                            <Form.Item>            
                                                <Input refs="CCompany" name="CCompany" required placeholder='Nokia' onChange={this.handleChange.bind(this, "CCompany")} value={this.state.fields["CCompany"]}/>
                                                <span style={{color: "red"}}>{this.state.errors["CCompany"]}</span>
                                            </Form.Item>
                                            
                                            <span className="label bordershare">Country</span>
                                            <Form.Item>            
                                                <Input refs="CCountry" name="CCountry" required placeholder='France' onChange={this.handleChange.bind(this, "CCountry")} value={this.state.fields["CCountry"]}/>                                                
                                            </Form.Item>
                                            
                                            <span className="label bordershare">Address</span>
                                            <Form.Item>
                                                <Input refs="CAddress" name="CAddress" required placeholder='7 Route de Villejust, 91620 Nozay, France' onChange={this.handleChange.bind(this, "CAddress")} value={this.state.fields["CAddress"]}/>                                                
                                            </Form.Item>
                                            
                                            <span className="label bordershare">Email *</span>
                                            <Form.Item>
                                                <Input refs="CEmail" name="CEmail" required placeholder='john.doe@external.com' onChange={this.handleChange.bind(this, "CEmail")} value={this.state.fields["CEmail"]}/>
                                                <span style={{color: "red"}}>{this.state.errors["CEmail"]}</span>
                                            </Form.Item>
                                            
                                            <span className="label bordershare">Telephone *</span>
                                            <Form.Item>
                                                <Input refs="CTel" name="CTel" required placeholder='+33677889900' onChange={this.handleChange.bind(this, "CTel")} value={this.state.fields["CTel"]}/>
                                                <span style={{color: "red"}}>{this.state.errors["CTel"]}</span>
                                            </Form.Item> 
                                            <p>* these fields are required</p>                                                                                     
                                        </Form>
                                </div>}
                                {/* currentStep === 2 */}
                                {currentStep === 2 && 
                                <div className='cart-step'>
                                    <Form onSubmit= {this.contactSubmit.bind(this)}>
                                        <span className="label bordershare">What information are you looking for: </span>
                                        <Checkbox name="IDetails" onChange={this.handleChange.bind(this, "IDetails")} value={'Detailed information about those trainings'}>Detailed information about those trainings</Checkbox>                                                                            
                                        <br />
                                        <Checkbox
                                            checked={this.state.checked}
                                            onChange={this.onChange}
                                            name="IQuote"
                                            value={'Quote for this training'}
                                        >Quote for this training</Checkbox>
                                        {this.state.checked &&
                                        <div>
                                            <br /><span>To give you an accurate quote, we kindly ask you to answer following questions as best as possible</span>
                                            <span className="label bordershare">What is the expected number of participants?</span>
                                                <InputNumber name="CNumber" min={0} onChange={this.onchangeCnb}/>
                                            <span><h4>Do you have any desired location?</h4></span>
                                            <span className="label bordershare">NokiaEDU site, please select</span>
                                                <Select name="CLocation" className="shopSelect" styles={{width:"700px"}} placeholder = "Select a location" value={selectedOption} onChange={this.handleChangeSelectOption} options={optionsShopcart}/>
                                            <span className="label bordershare">Onsite</span>
                                                <Input name="COnSite" required placeholder='Please provide address if already known' onChange={this.handleChange.bind(this, "COnSite")} value={this.state.fields["COnSite"]}/>
                                        
                                        </div>}
                                        <br />                                        
                                        <Checkbox name="IBooking" onChange={this.handleChange.bind(this, "IBooking")} value={'Available Schedule'}>Available Schedule</Checkbox><br />
                                        <Checkbox name="IOther" onChange={this.handleChange.bind(this, "IOther")} value={'Other'}>Other</Checkbox>
                                    </Form>
                                </div>}
                                {/* currentStep === 3 */}
                                {currentStep === 3 && 
                                <div className='cart-step'>
                                    <Form onSubmit= {this.contactSubmit.bind(this)}>
                                        <span className="label bordershare">Do you have a contact person at Nokia?</span>
                                        <Input className="inputTitle" name='NContact' id='form-contact' required placeholder='John DOE' onChange={this.handleChange.bind(this, "NContact")} value={this.state.fields["NContact"]}/>
                                        <span className="label bordershare">Did you find what you were looking for? If not, do you want us to contact you?</span>
                                        <RadioGroup name='NCusto' onChange={this.handleChange.bind(this, "NCusto")} value={this.state.fields["NCusto"]}>
                                            <Radio value={'yes'}>Yes</Radio>
                                            <Radio value={'no'}>No</Radio>
                                        </RadioGroup>
                                        <span className="label Bordershare">Do you have any comments, questions or feedback</span>
                                        <Input className="inputTitle" name='NComments' id='form-comment' required placeholder='Your comment here' onChange={this.handleChange.bind(this, "NComments")} value={this.state.fields["NComments"]}/>
                                        <span className="label bordershare">Would you like to receive a monthly update on new courses?</span>
                                        <RadioGroup name="NUpdate" onChange={this.handleChange.bind(this, "NUpdate")} value={this.state.fields["NUpdate"]}>
                                            <Radio value={'yes'}>Yes</Radio>
                                            <Radio value={'no'}>No</Radio>
                                        </RadioGroup>
                                    </Form>
                                </div>} 
                                </Col>
                                </Row>
                                <Button className="btn-p" onClick={ this.onClickPrevious } disabled={clickPrev}><Icon type="left" />Previous step</Button>
                                
                                <Button className="btn-n" onClick={ clickNext } onSubmit={this.contactSubmit.bind(this)} disabled={(currentStep === 3 && contactMail === undefined) ? true : disabledSend}>{currentStep === 3 ? 'Send' : 'Next step'}<Icon type="right" /></Button>
                                {currentStep === 3 && !disabledSend && <span className="recipient"><Select name="Smail" value={contactMail} onChange={this.handleChangeContactCart} options={ContactMailCart} placeholder = "Select an email recipient"/></span>}
                            </div>  
                        </div>
                    </Col>
                    </Row>             
                    <FooterComponent props={this.props} data={this.storeDef}/>
                </div>
            )
        }
    }
}