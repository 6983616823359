/*
  @author Benoit Vaillant
  Copyright Nokia 2023. All rights reserved.
*/

//React
import React, {Component} from 'react';
import {Tooltip } from 'antd';
//Style
import '../css/Ziggy.css';


export default class Ziggy extends Component {
   
  render() {
  
    return <>
    <Tooltip placement='topLeft' title='Get help from Ziggy in a new window.'>    
        <img 
              onClick={()=> window.open("https://ziggy-chatbot.nokia.com/", "Ziggy")} 
              className='ask' 
              src={process.env.PUBLIC_URL + "/img/ziggyicon.jpg"} alt='Access to Ziggy' />
    </Tooltip></>
  }
}