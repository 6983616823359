/*
  @author Gilles Gerlinger
  Copyright Nokia 2017. All rights reserved.
 */

import { saveAs } from 'file-saver'
import md5 from 'md5'
import Source from './data'
import B from './back'
import wipC from './editWip'
import { Modal, notification } from 'antd'
import authService from '../util/authService'

import { Jsql } from 'web_jsql';
const tunnel = Jsql({ key: 'b43fdd98b1fd705ae4c3a10cf25aad8a', url: 'https://backend.store.dyn.nesc.nokia.net/jsql' })

const dbNames = []
dbNames['employee'] = 'edit'
dbNames['mlkb'] = 'knowledge'
const itemParse = /\/item\/|\/edit\//


localStorage.authorID = localStorage.authorID || new Date().getTime().toString()
localStorage.edit = localStorage.edit || '[]'
const localify = (data, pos) =>
  (localStorage.edit = JSON.stringify(data, null, 2))
//console.log(typeof localStorage.edit, localStorage.edit)
const getStorage = () => JSON.parse(localStorage.edit)
const resetStorage = () => {
  JSON.parse(localStorage.edit).forEach((item) => {
    if (item.new.sid === edit._getName()) {
      edit._pop(item.new.ID)
    }
  })
}
const editTmp = []
const logs = []

// const gun = Config.gun ? new Gun(Config.gun) : null;
// window.gun = gun // for debugging gun

const switchEditMode = () => {
  // if (B.intranet) {
  edit.switchEditMode(!edit.getEditMode(), true)
  window.scrollTo(0, 0)
  if (B.component) B.component.forceUpdate()
  // }
}

document.addEventListener('keydown', (event) => {
  if (
    (!B.intranet || !B.userProfile) &&
    //(!B.intranet) &&
    ((event.ctrlKey && event.keyCode === 13) ||
      (event.altKey &&
        [
          13,
          23,
          67,
          68,
          69,
          70,
          72,
          76,
          78,
          79,
          82,
          83,
          85,
          87,
          88,
          89,
          90,
        ].includes(event.keyCode)))
  ) {
    event.preventDefault()
    event.stopPropagation()
    notification.error({
      message: 'Error',
      description: 'You need to be both connected to the Intranet AND the Learning Store',
      //description: 'You need to be connected to the Intranet',
      placement: 'bottomRight',
    })
    return
  }

  // if (B.pathname && B.pathname.split('/')[2] === "advanced" &&
  //   ( (event.ctrlKey && event.keyCode === 13) ||
  //   (event.altKey && [13,67,68,69,70,72,76,78,79,82,83,85,87,88,89,90].includes(event.keyCode)) ) ) {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     notification.error({
  //     message: 'Error',
  //     description: "You cannot enter admin mode on this page",
  //     placement: 'bottomRight'
  //   });
  //   return;
  // }


  if (B.userProfile) {
    if (event.ctrlKey && event.keyCode === 13) switchEditMode()
    // ctl-enter
    else if (event.ctrlKey && event.keyCode === 76) {
      authService.login().then((token) => {
        if (token) {
          B.header.setState({ msUser: authService.app.getUser() })
        }
      })
    } // ctrl+l
    else if (event.altKey) {
      event.preventDefault()
      event.stopPropagation()
      switch (event.keyCode) {
        case 13: // alt-enter
          switchEditMode()
          break
        case 67: // alt-c
          edit.clipboard()
          break
        case 68: // alt-d
          edit.dump()
          break
        case 69: // alt-e
          edit.modify()
          break
        case 70: // alt-f
          edit.create('collection')
          break
        case 72: // alt-h
          B.history.push('/')
          break
        case 76: // alt-l
          edit.showLog()
          break
        case 78: // alt-n
          edit.create('item')
          break
        case 79: // alt-o
          edit.open()
          break
        case 82: // alt-r
          edit.reset()
          break
        case 83: // alt-s
          edit.saveAs()
          break
        case 85: // alt-u
          edit.upload()
          break
        case 87: // alt-w
          edit.gotoWip()
          break
        case 88: // alt-x
          edit.del()
          break
        case 89: // alt-y
          edit.redo()
          break
        case 90: // alt-z
          edit.undo()
          break
        default:
      }
    }
    return false
  }
})


const file = document.createElement('input') // the file reader
file.setAttribute('type', 'file')
file.setAttribute('multiple', 'true')
file.addEventListener('change', (evt) => {
  // console.log(file.files.length)
  reader.index = -1
  readFile()
})
const readFile = () => {
  reader.index++
  if (reader.index < file.files.length) {
    reader.fileName = file.files[reader.index].name
    reader.readAsText(file.files[reader.index])
  }
}
const reader = new FileReader()
reader.onload = (file) => {
  // console.log(reader.fileName);
  edit.log('Importing file ' + reader.fileName)
  const name = edit._getName()
  if (!name) return
  try {
    localify(importData(name, JSON.parse(reader.result), getStorage()))
  } catch (e) {
    edit.log('File import failed', e)
  }
  readFile() // read next file
}

const compare = (obj1, obj2) => {
  // console.log('?', obj1, obj2)
  // if (!obj1) return false;
  for (let p in obj1) {
    // console.log(p)
    if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) return false //Check property exists on both objects
    if (typeof obj1[p] === 'object') {
      //Deep compare objects
      if (!compare(obj1[p], obj2[p])) return false
    } else if (obj1[p] !== obj2[p]) return false //Compare values
  }
  // console.log('pass 1')
  for (let p in obj2) {
    //Check object 2 for any extra properties
    // console.log(p)
    if (typeof obj1[p] === 'undefined') return false
  }
  // console.log('pass 2')
  return true
}

const importData = (name, data, storage) => {
  if (!data.length) return []
  edit.log('Importing ' + data.length + ' item(s)')
  const ids = []
  data.forEach((pair) => {
    // reduce the list of updates: a->b then b->c becomes a->c
    if (pair.old.sid === name) {
      if (!ids[pair.old.ID]) ids[pair.old.ID] = pair
      else {
        ids[pair.old.ID].new = pair.new
        edit.log('Import - reducing ' + pair.old.ID + ' - ' + pair.old.Title)
      }
    }
  })
  Object.keys(ids)
    .map((id) => ids[id])
    .forEach((pair) => {
      if (pair.old.sid === name) {
        const local = Source.get(name).getByID(pair.old.ID)
        // const local = {...old}
        // delete local.noDel;
        // console.log('clone', old, local)
        if (!local) {
          if (!pair.new.del) {
            edit.log(
              'Import - creating ' +
                pair.new.ID +
                ' - ' +
                pair.new.Title +
                '?' +
                pair.new.del,
            )
            wipC.save(pair.new.ID)
            wipC.process(pair.new, true)
            edit.update(pair.new)
            storage.push(pair)
          }
        } else if (compare(local, pair.old)) {
          if (pair.new.del) {
            // console.log(local, pair.old, pair.new)
            edit.log('Import - deleting ' + local.ID + ' - ' + local.Title)
            wipC.save(pair.new.ID)
          } else {
            edit.log('Import - updating ' + local.ID + ' - ' + local.Title)
            wipC.save(local.ID)
            wipC.process(pair.new, true)
          }
          edit.update(pair.new)
          storage.push(pair)
        } else if (compare(local, pair.new)) {
          edit.log('Import - skipping ' + local.ID + ' - ' + local.Title)
        } else {
          // console.log(local, pair.old, pair.new)
          edit.log('Import - refusing ' + pair.new.ID + ' - ' + pair.new.Title)
        }
      } else
        edit.log(
          'Import - skipping ' +
            pair.old.ID +
            ' - ' +
            pair.old.Title +
            ' / ' +
            pair.old.sid,
        )
    })
  edit._reload()
  return storage
}

class Edit {
  editMode = false

  //use case: edit an item twice - apply the change (employee.json) - reload to purge localstorage
  setEditMode(mode) {
    this.editMode = mode
  }

  getEditMode() {
    return this.editMode
  }

  switchEditMode(forceMode, dimmer) {
    // console.log('swiitch', B);

    if (forceMode) {
      this.editMode = true
    } else if (!forceMode) {
      this.editMode = false
    } else {
      this.editMode = !this.editMode
    }
    if (dimmer) {
      edit.dimmerEdit()
    }

    if (this.editMode === false) {
      let name = this._getName()
      if (
        B.pathname.split('/')[2] === 'create' ||
        B.pathname.split('/')[3] === 'wip'
      ) {
        B.history.push('/' + name)
        // console.log('specific');
      } else if (B.pathname.split('/')[2] === 'edit') {
        // console.log('back');
        B.history.go(-1)
      }
    }
  }

  dimmerEdit() {
    function fadeIn(element) {
      let op = 0.1
      const timer = setInterval(function () {
        if (op >= 1) {
          clearInterval(timer)
        }
        element.style.opacity = op
        op = op + 0.2
      }, 10)
    }

    function fadeOut(element, callback) {
      let op = 1
      const timer = setInterval(function () {
        if (op <= 0) {
          clearInterval(timer)
          callback()
        }
        element.style.opacity = op
        op = op - 0.2
      }, 10)
    }

    let el

    if (this.editMode) {
      if (document.getElementById('editDimmer') !== null) {
        document.getElementById('editDimmer').style.display = 'block'
        document.getElementById('editDimmerText').innerHTML =
          'Welcome to admin mode!'
        // document.getElementById("editDimmerImg").src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACqklEQVR4XuWb4XHbMAxGP2zQEdwNukGyQeoJ2kzQeIJmg7oTtJ6g7gRNNvAGiTdIJkAPPtEny5INUKRF0LzTD99RMt8jREGkSKikMPMXALfNMWuwNgDkWBPR3z5U8s7PzJ8B/AAQoIeQXgEsiGjdruBaADN/BfDL2IlLIlqEc9wKiIQP3D+J6EF+uBQwEj5ImMvt4E5AIniR8EpEH69ZgEiYuxMgrU4YBSuXAhJK2BQvoOntGyK67z7uUkRC0QI6gL9zSChWwEDvppawLVLAmdBOKaG8QVB5X6eScF9UBCjhw1g4VsKWiGbFCDDCp5BQTiocCT9GwoqI5E1y+pehkfAxEvbwkwtIBG+RcAA/qQAD/DuAJYDviomPUwPjbQj7yWeEjPDS8I3hnF4JQ/Iu/hQwgEjP7+BD4w3nqiVcVIAB4Ai+JeFxzO3QjYSLCUgE/wnAPwAfFOPB0YA32bR4qfAXeQqUDJ9dQOnwWQV4gM8mwAt8FgGe4JML8AafVIBH+GQCvMInEeAZfrQA7/CjBNQAHy2gFvgoATXBmwXUBm8SUCO8WkCt8CoBNcOfFVA7/EkB1wA/KOBa4HsFXBP8kQBmlg+OXxRTzqfm7ZNPXSvaE13lYF2g+fL6j+Jq8vn5vFuPmV3B90WALEJ+UwiQKgfLTx7h+wQ8AbhRCthL8ArfJ4AN8KGqbECQnRrJlqsi2hB9yn4MYGaBkHW3XEW1Vpfrz4eu2xYgGwhk60mOUiT8wS3AzBLKdxnoi4XvCpDn/7mNR1Y/RcPvBRgSIIuA4uHbAmTrmSYB0gpwAd8WYEmAzklwA98WYE2AhiS4gm8LiEmAuhLcwe8EjEiAnpt9uRI9GyKSranuigjQJEDbDqxAV1FEQF8CVEXvanpIBEhvvgEIoVxN72oE/AeLazLkUOkDDwAAAABJRU5ErkJggg==";
        document.getElementById(
          'editDimmerImg',
        ).src = `${process.env.PUBLIC_URL}/img/pencil.png`
        el = document.getElementById('editDimmer')
        fadeIn(el)
        setTimeout(function () {
          fadeOut(el, function () {
            document.getElementById('editDimmer').style.display = 'none'
          })
        }, 800)
      }
    } else {
      if (document.getElementById('editDimmer') !== null) {
        document.getElementById('editDimmer').style.display = 'block'
        document.getElementById('editDimmerText').innerHTML = 'Exit admin mode!'
        // document.getElementById("editDimmerImg").src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAABr0lEQVR4Xu3bbW7DIAwGYL8nW4+y3WQ7Sbeb7GZMVI200AQwX8HG+VNVaSP81Dh8NHDOORJ0AEDL5sIALAOsC6RqwC8RfQHwr8OPsIteWQMugZgJYPv1h0LMCDAUYmaAIRASALpCSALoAiERoCmEZIAHRO192wCCoXotaDiS6z4Zqm2wZYBlwH6yFmaUc+4O4KN0kiK+Czy7yHcpghYAnwBFCJoAihCmB0j17YMlPVYmaARgZYJWgGwEzQBZCMMBLliGj9aEFQCimbAKwCnCSgCHCKsB/AB4/z+2WAngJfjHgk3vqnwwe0vtRKUGfyXnD4NfBeA0+EsASn6+2HcSGRwNXjtAMnjNAFnBawXIDl4EQGpVODjPCl4bADt4TQBFwWsB8NPd3fCWc6sdPhLkNM5/NlUDuNezrbFAwDJg9GSIm7LWBWxzNL45ys0oK4JWBPcC3e8CtSn6krL2vIA9MGFPjLTsVr4G3Ijok4jeWl54u1btv8R6tGm3L7C96QUhBqAXhDiA1hBiAVpBiAeohVADUAqhDoALoRYgF0I9QApiGYAziOUAQggAfqg97fEH9vT2UHiK4H8AAAAASUVORK5CYII=";
        document.getElementById(
          'editDimmerImg',
        ).src = `${process.env.PUBLIC_URL}/img/exit.png`
        el = document.getElementById('editDimmer')
        fadeIn(el)
        setTimeout(function () {
          fadeOut(el, function () {
            if (document.getElementById('editDimmer')) {
              document.getElementById('editDimmer').style.display = 'none'
            }
          })
        }, 800)
      }
    }
  }

  load(name) {
    const newStorage = []
    const storage = getStorage()
    importData(name, storage, newStorage)
    localify(storage.filter((pair) => pair.old.sid !== name).concat(newStorage)) // keep other stores data
    try {
    } catch (err) {
      this.log('error while loading localStorage.edit ' + err)
    }
  }

  open() {
    if (this._getName()) file.click()
  }

  _getName() {
    const name = window.location.pathname.split(`${process.env.PUBLIC_URL}/`)[1]
    if (!name) return
    return name.split('/')[0]
  }

  dump() {
    const name = this._getName()
    if (!name) return
    const store = Source.stores[name]
    if (!store) return
    const fileName = name + '.json'
    // console.log(store.getByID('n.1489224120111'))
    saveAs(
      new Blob(
        [
          JSON.stringify(
            Object.keys(store.ids)
              .map((key) => store.ids[key])
              .filter((item) => !item._admin),
            null,
            2,
          ),
        ],
        { type: 'text/plain;charset=utf-8' },
      ),
      fileName,
    )
    this.log('Saving current store in file ' + fileName)
  }

  saveAs() {
    const sid = this._getName()
    const wip = getStorage().filter((item) => item.new.sid === sid)

    const fileName =
      sid +
      '-' +
      localStorage.authorID +
      '.' +
      new Date().getTime().toString() +
      '.json'
    saveAs(
      new Blob([JSON.stringify(wip)], { type: 'text/plain;charset=utf-8' }),
      fileName,
    )
    this.log('Saving current modifications in file ' + fileName)
  }

  create(type) {
    let url = B.pathname.split('/')[3]
    let name = this._getName()
    if (!name) return
    if (url === type) {
      notification.error({
        message: 'Error',
        description: 'Save before creating another ' + type,
        placement: 'bottomRight',
      })
    } else {
      B.history.push('/' + name + '/create/' + type)
    }
  }

  _getItem() {
    let url = window.location.pathname.split(itemParse)
    if (url.length === 1) return
    const id = url[1]
    let name = url[0].split('/')
    name = name[name.length - 1]

    const item = Source.get(name).getByID(id)
    if (!item) return
    if (item.sid !== name) return
    return { name: name, id: id, item: item }
  }

  modify() {
    let a = null
    try {
      a = this._getItem()
    } catch (e) {
      // console.log('eeeeer', e);
    }

    const name = window.location.pathname.split('/')[1]
    const storeDef = Source.getDef(name)
    // console.log('a', a);
    if (!a && storeDef.homepage.id && storeDef.homepage.editable) {
      a = Source.get(name).getByID(storeDef.homepage.id)
      B.history.push({
        pathname: '/' + name + '/edit/' + a.ID,
        state: { name: name, id: a.ID },
      })
      return
    } else {
      if (!a || a.item.ReadOnly) {
        console.log(a)
        notification.error({
          message: 'Error',
          description: 'You cannot modify this page',
          placement: 'bottomRight',
        })
        return
      }
    }
    B.history.push({
      pathname: '/' + a.name + '/edit/' + a.id,
      state: { name: a.name, id: a.id },
    })
  }

  sleep(milliseconds) {
    var start = new Date().getTime()
    for (var i = 0; i < 1e7; i++) {
      if (new Date().getTime() - start > milliseconds) {
        break
      }
    }
  }

  del() {
    // console.log('try del');
    const a = this._getItem()
    // console.log('before', {...a});
    if (a && B.origin[a.item.ID]) a.item = JSON.parse(B.origin[a.item.ID])
    // console.log('del', a);
    // console.log('after', {...a});
    if (!a || a.item.ReadOnly || a.item.noDel) {
      notification.error({
        message: 'Error',
        description: 'You cannot archive this page',
        placement: 'bottomRight',
      })
      return
    }
    const dataCollections = Source.getCollections(a.item.sid, a.item.ID) || []
    const self = this
    Modal.confirm({
      title: 'Do you really want to archive this item ?',
      content:
        dataCollections.length > 1
          ? `This item is used in ${dataCollections.length} collections. Archiving this item will remove it from all the collections that contain it.`
          : '',
      zIndex: 1000000,
      onOk() {
        console.log('deleting', a.id, 'from', a.name)
        const old = JSON.stringify(a.item) // make a copy of the item
        const cur = { sid: a.item.sid, ID: a.item.ID, del: true, Owner: a.item.Owner, Title: a.item.Title }
        // console.log('cur', cur)
        self._push(old, cur)
        self.update(cur)
        wipC.save(cur.ID)
        if (B.pathname.match(/\/(edit|create)\//)) {
          self.gotoWip(true)
        } else {
          if (B.back) B.history.go(-1)
        }
      },
    })
  }

  _push(old, cur) {
    // old is the item old value stringified
    const storage = getStorage()
    storage.push({ old: JSON.parse(old), new: cur })
    localify(storage)

    if (old.del) this.log('Creating item ' + cur.ID + ' - ' + cur.Title)
    else if (cur.del) this.log('Deleting item ' + old.ID + ' - ' + old.Title)
    else this.log('Updating item ' + cur.ID + ' - ' + cur.Title)
  }

  _pop(id) {
    // old is the item old value stringified
    let storage = getStorage()
    storage = storage.filter((item) => item.new.ID !== id)
    this._reload()
    localify(storage)

    // if (old.del)
    //   this.log('Creating item ' + cur.ID + ' - ' + cur.Title);
    // else if (cur.del)
    //   this.log('Deleting item ' + old.ID + ' - ' + old.Title);
    // else
    //   this.log('Updating item ' + cur.ID + ' - ' + cur.Title);
  }

  undo() {
    if (wipC.Solutions.length > 2) {
      const storage = getStorage()

      if (storage.length) {
        const item = storage.pop()
        editTmp.push(item)
        console.log('undo edit', item)
        this.update(item.old)
        localify(storage)
        // console.log('undo', storage.some( elem => elem.old.ID === item.old.ID ))
        if (!storage.some((elem) => elem.old.ID === item.old.ID))
          wipC.update(item.old.ID) // remove item from WIP
        if (Source.get(this._getName()).getByID(item.old.ID)) this._reload()
        else this.gotoWip()
        this.log('Undo last operation')
      }
    }
  }

  redo() {
    if (editTmp.length) {
      const item = editTmp.pop()
      const storage = getStorage()
      storage.push(item)
      this.update(item.new)
      localify(storage)
      wipC.save(item.new.ID)
      if (
        item.new.del &&
        ((B.currentColl && B.currentColl.ID === item.new.ID) ||
          (B.currentItem && B.currentItem.ID === item.new.ID))
      ) {
        //console.log('item', item, B.currentColl.ID)
        if (
          B.pathname.split('/')[2] === 'edit' ||
          B.pathname.split('/')[2] === 'create'
        )
          this.gotoWip()
        else B.history.goBack()
      } else this._reload()
      this.log('Redo last operation')
    }
  }

  reset() {
    resetStorage()
    window.location.reload() // so that the user can see | clear the logs
  }

  _reload() {
    // console.log('rendering...')
    if (B.component) B.component.setState({ toggle: !B.component.state.toggle })
    if (B.component && B.component.undo) B.component.undo()
  }

  update(item) {
    const ids = Source.stores[item.sid].ids
    item.del ? delete ids[item.ID] : (ids[item.ID] = item)
    // console.log('update', item)
  }

  // clipboard() {
  //   const item = this._getItem();
  //   // console.log(item);
  //   if (item) {
  //     const tmp = document.createElement("input");
  //     document.body.appendChild(tmp);
  //     tmp.setAttribute("id", "dummy_id");
  //     tmp.setAttribute('value', item.id);
  //     tmp.select();
  //     document.execCommand("copy");
  //     document.body.removeChild(tmp);
  //   }
  // }

  log(text) {
    logs.push(new Date().toLocaleString() + ' | ' + text)
  }

  showLog() {
    const nw = window.open('', 'LS logs')
    nw.document.body.innerText = ''
    nw.document.write('<h3>Edit Logs</h3>')
    logs.reverse().forEach((line) => nw.document.write(line + '<br />'))
    logs.reverse()
  }

  gotoWip(replace) {
    // if (wipC.back) {
    //   delete wipC.back;
    //   B.history.goBack();
    //   return;
    // }
    const name = this._getName()
    if (!name) return
    wipC.back = true
    replace
      ? B.history.replace(`/${name}/item/wip`)
      : B.history.push(`/${name}/item/wip`)
  }

  upload() {
    const submit = getStorage()
    if (!submit.length) {
      notification.error({
        message: 'Error',
        description: 'You cannot submit because the workbench is empty',
        placement: 'bottomRight',
      })
    } else {
      // adal
      let name = this._getName()
      name = dbNames[name] || name
      authService.getToken().then((token) => {
        // console.log('login', authService.email(), name)
        localStorage.editName = md5(authService.name())
        // tunnel.login(token, authService.sid(), name)
        // tunnel.bLogin(authService.email(), authService.sid(), name)
        tunnel({
          query: { 'rst: tunnel.bk_bLogin': { cache: 'no-cache' } },
          variables: { email: authService.email(), db: name },
        })
          .then((rep) => {
            // console.log('rep', rep)
            if (!rep.data.rst) {
              notification.error({
                message: 'Error',
                description:
                  'You are not authorized to submit modifications to this store',
                placement: 'bottomRight',
              })
            } else {
              // let count = 0;
              const unik = [],
                todo = [],
                date = new Date().getTime()
              const sid = this._getName() // a little bit of confusion...
              // console.log('db name', name, sid)
              name = authService.name(name === 'priority' ? true : false) // get full author's name
              // console.log('editor name', name)
              submit
                .reverse()
                .filter((item) => item.old.sid === sid)
                .forEach((item) => {
                  // submit.reverse().forEach(item => {
                  if (!unik[item.new.ID]) {
                    unik[item.new.ID] = item
                    const elem = item.new
                    // console.log('elem', elem)
                    elem.name = name
                    elem.date = date
                    delete elem.noDel
                    if (!elem.Owner) elem.Owner = 'system' // takung care of items without Owners, like in the Partner Store
                    todo.push(elem) 
                  }

                 
                })

              let subject = "Changes have been made to your item"

              // const query = Object.fromEntries(
              //   todo.map((elem, i) => [
              //     `rst${i}: tunnel.bk_putDoc2`,
              //     {
              //       cache: 'no-cache',
              //       variables: {
              //         id: `${elem.ID} ° ${name}`,
              //         db: 'react',
              //         item: elem,
              //       },
              //     },
              //   ]).concat(todo.map((elem, i)=>[
              //     `ntf${i}: notification.record`,
              //     {
              //       variables:{ list: [
              //         {                      
              //           _id: `${elem.ID} ° ${name}`,
              //           date: date,
              //           subject: subject,
              //           body: `Dear ${elem.Owner[0].toUpperCase() +elem.Owner.slice(1).split('.')[0] }, <br> <br> Changes have been made to your item : <a href="https://learningstore.nokia.com/employee/item/${elem.ID}" > "${elem.Title}" </a>, by <strong> ${authService.email()} </strong> in the <strong> ${elem.sid.charAt(0).toUpperCase()+sid.substr(1)} Store </strong>.  <br> https://learningstore.nokia.com/employee/item/${elem.ID}  <br><br> Kind regards, <br> The Learning Store Bot`,
              //           to: elem.Owner, 
              //           item: `Owner : ${elem.Owner} ° Title : ${elem.Title} ° Store: ${elem.sid}`,
                       
              //     }] }}

              //   ])),
              // )
                const completedModif = todo.map((elem,i)=>[
                  `rst${i}: tunnel.bk_putDoc2`, 
                  {
                    cache: 'no-cache',
                    variables: {
                      id: `${elem.ID} ° ${name}`,
                      db: 'react',
                      item: elem,
                    },
                  }
                ])
                // console.log("completedModif", completedModif)

                const notifList = todo.map( elem => ({
                  _id: `${elem.ID} ° ${name}`,
                  date: date,
                  subject: subject,
                  body: `Dear ${elem.Owner[0].toUpperCase() +elem.Owner.slice(1).split('.')[0] }, <br> <br> Changes have been made to your item : <a href="https://learningstore.nokia.com/employee/item/${elem.ID}" > "${elem.Title}" </a>, by <strong> ${authService.email()} </strong> in the <strong> ${elem.sid.charAt(0).toUpperCase()+sid.substr(1)} Store </strong>.  <br> https://learningstore.nokia.com/employee/item/${elem.ID}  <br><br> Kind regards, <br> The Learning Store Bot`,
                  to: elem.Owner, 
                  item: `Owner : ${elem.Owner} ° Title : ${elem.Title} ° Store: ${elem.sid}`,
                }) )
                .filter( item => item.to !== authService.email())

                const notifOwner = notifList.length ?  [['notification.record', { variables: { list: notifList }}]] : []
                // console.log('dbg', completedModif, notifOwner, completedModif.concat(notifOwner))

                const query = Object.fromEntries(completedModif.concat(notifOwner))

              tunnel({ query })
                .then((rep) => {
                  // console.log('rep', rep);
                  if (rep.data) {
                    // console.log('dbg', rep)
                    const length = Object.keys(rep.data).filter( rst => !rst.startsWith('notification')).length
                    logs.push('uploading')
                    notification.info({
                      message: 'Information',
                      placement: 'bottomRight',
                      description: `${length}/${
                        todo.length
                      } change(s) submitted`,
                    })
                  } else
                    notification.error({
                      message: 'Error',
                      description: rep.error,
                      placement: 'bottomRight',
                    })
                })

                .catch((err) => {
                  console.log('err', err)
                  notification.error({
                    message: 'Error',
                    description: err.message,
                    placement: 'bottomRight',
                  })
                })

              // todo.forEach(elem => {
              //   // tunnel.putDoc2('react', `${elem.ID} ° ${name}`, elem)
              //   tunnel({"rst: tunnel.bk_putDoc2":""}, { id: `${elem.ID} ° ${name}`, db: 'react', item: elem })
              //   .then(rep => {
              //     console.log(rep, count, submit.length);
              //     if (rep.data.rst) count++;
              //     if (count === todo.length) {
              //       logs.push('uploading');
              //       notification.info({
              //         message: 'Information',
              //         description: `${count}/${todo.length} change(s) submitted`,
              //         placement: 'bottomRight'
              //       });
              //       // this.reset();
              //     }
              //   })
              //   .catch(err => {
              //     console.log(err);
              //   });
              // })
            }
          })
          .catch((err) => {
            console.log(err)
            notification.error({
              message: 'Error',
              description: err.message,
              placement: 'bottomRight',
            })
          })
      })
    }
  }


}

const edit = new Edit()
edit.log('Started')
export default edit
