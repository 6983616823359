// /*
//   @author Félix Fuin
//   Copyright Nokia 2017. All rights reserved.
// */

import React, { Component } from 'react'
import ModalWindow from './ModalWindow'
import { Link } from 'react-router-dom'
import '../css/sliderHome.css'
import { StyleRoot } from 'radium'
import NokiaCoverflow from './NokiaCoverflow'


export default class SliderHome extends Component {
  constructor(props) {
    super(props)
    this.state = {
      ModalOpen: [],
      currentSlide: null,
    }
  }

  closeModal = () => {
    this.setState({ ModalOpen: [] })
  }

  showModalWindow(index) {
    let nextModalOpen = this.state.ModalOpen
    nextModalOpen[index] = true
    this.setState({
      ModalOpen: nextModalOpen,
      index,
    })
  }

  closeCarouselModal = () => {
    if (this.state.showcarouselitem) this.setState({ showcarouselitem: false })
  }



  render() {
    const { name } = this.props.match.params
    this.slides = this.props.data.carousel.map((slide, index) => {

      const img = (
        <div style={{ display: 'block', overflow: 'hidden', width: '100%' }}>
          <img src={this.props.data.url + '/' + slide.img} alt="" />
        </div>
      )

      let link =
        slide.id && index === this.state.currentSlide ? (
          <div>
            <Link
              // onClick={(e) => dragging && e.preventDefault()}
              to={'/' + name + '/item/' + slide.id}
            >
              <div> {img} </div>            
            </Link>
            {index === this.state.currentSlide && <div className='imagehint'>open Learning Store solution...</div>}
          </div>
        ) : slide.url ? (<div>
            <a href={slide.url} target="_blank" rel="noopener noreferrer">
              <div> {img} </div>
              
            </a>
            {index === this.state.currentSlide && <div className='imagehint'>open in new window...</div>}
          </div>
        ) : slide.html ? (
          <div
            onClick={() => {
              if (index === this.state.currentSlide) this.showModalWindow(index)
            }}
          >
            <div>{img}</div>
            {index === this.state.currentSlide && <div className='imagehint'>read more...</div>}
          </div>
        ) : (
          <div>{img}</div>
        )
      return <div key={index}>{link}</div>
    })

    // console.log(this.state.currentSlide)

    let slide = this.props.data.carousel[this.state.index]

    // Case of single slide : no carousel
    if (this.slides.length === 1)
      return (
        <div style={{ height: '100' }}>
          {this.slides[0]}
          {slide && (
            <ModalWindow
              html={slide.html}
              title={slide.title}
              closeModal={this.closeModal}
              open={this.state.ModalOpen[this.state.index]}
            />
          )}
        </div>
      )

    const getCurrentslide = (p) => {
      if (this.state.currentSlide !== p) this.setState({ currentSlide: p })
    }

    // Case of multiple slides

    return (
      <div className="slider">
        <StyleRoot>
          <NokiaCoverflow
            ref="coverflow"
            height={350}
            displayQuantityOfSide={2}
            infiniteScroll={true}
            currentSlide={getCurrentslide}
            enableScroll={false}
            enableHeading={false}
            media={{
              '@media (max-width: 720px)': {
                width: '100%',
                height: '200px',
              },
              '@media (min-width: 720px)': {
                width: '100%',
                height: '430px',
              },
            }}
          >
            {this.slides}
          </NokiaCoverflow>
        </StyleRoot>
        {slide && (
          <ModalWindow
            html={slide.html}
            title={slide.title}
            closeModal={this.closeModal}
            open={this.state.ModalOpen[this.state.index]}
          />
        )}
      </div>
    )
  }
}
