import React, { useState, Component } from 'react'
import { Link } from 'react-router-dom'
import AdvancedLoader from './AdvancedLoader'
import HeaderComponent from './Header'
import FooterComponent from './Footer'
import Thumbnail from './Thumbnail'
import ThumbnailContainer from './ThumbnailContainer'

import Source from '../tools/data'
import EditCtl from '../tools/editCtl'
import { BackTop, Input, Select, Tag, Table, Button, Tooltip } from 'antd'
import B from '../tools/back'
//import authService from '../util/authService'
import moment from 'moment'

import '../css/Advanced.css'
//import { LoadingIndicator } from 'react-select/src/components/indicators'
// import { blue } from 'ansi-colors';
//import Remote from '../util/remote'
//const privacy = new Remote( 'sendMail', ['getOrg'], 'https://learningstore.nokia.com/srv1',)

const urlCsod = 'https://nokialearn.csod.com/GlobalSearch/search.aspx?s=&q='

const { Option } = Select
// const { Search } = Input;

let theData = null;

// B.set(Search);

const editMode = EditCtl.editMode ? '20px' : '0px'

const makeTable = (items, name) =>
  items
    .filter(
      (item) =>
        (item.Status === 'Published' && !item.Exclude) || EditCtl.editMode,
    )
    .map((item, key) => {
      return {
        key: key + 1,
        title: [item.Title, item.ID, name],
        parents: Source.getCollections(name, item.ID)
          ? Source.getCollections(name, item.ID).map((coll) => [
              coll.title,
              coll.id,
              name,
            ])
          : [],
        segments: item.Segment || [],
        format:
          item.Format ||
          (item.Solutions
            ? 'Collection'
            : item.Sections
            ? 'Topic Page'
            : 'Learning Object'),
        date: item.date,
      }
    })

const getFormats = (storeDef) => ['', ...storeDef['searchFormatFilter']]
const getSegments = (storeDef) => {
  if (storeDef['searchSegmentFilter'])
    return ['', ...storeDef['searchSegmentFilter']]
  return []
}

const showItems = (search, props, storeDef, value, lim, setLim, table, columns, currentUser) => {
  if (!search) return null;
  //console.log('showItems', value, search.length)
  let ret = search.filter(
    (item) =>
      item &&
      item.Title &&
      item.Icon &&
      (EditCtl.editMode || (item.Status === 'Published' && !item.Exclude)),
  )

  function handleClick() { console.log('this vaut :', urlCsod + value) }

  if (table) return <Table className="tableResults" columns={columns} dataSource={makeTable(ret, props.match.params.name)} bordered />
  
  // display icons
    let itemList = ret.map(item => <Thumbnail props={props} currentUser={currentUser} data={item} store={storeDef} />)

    if (itemList.length === 0)
    return (
      <div className="icons-list" id="iconSearchP">
        <p>
          Sorry, no result for <b>"{value}"</b>.
        </p>{' '}
        <br />
        <p>If you set filters, you can remove them and try again.</p> <br />
        <div className="icons-list">
          {' '}
          {storeDef.extendSearchToNLDH && (
            <Button
              size="large"
              href={
                'https://nokialearn.csod.com/GlobalSearch/search.aspx?s=&q=' +
                value
              }
              target="_blank"
              onClick={handleClick}
            >
              Extend your search for "{value}" on NLDH{' '}
            </Button>
          )}
          <br />
          <br />
        </div>
      </div>
    )

  // console.log('showItems', 'itemList', itemList)
 
  return <div>
            <ThumbnailContainer thumbnailList={itemList} batchSize={20} />
            <div className="moreRslt">
              {storeDef.extendSearchToNLDH && (
                <Button
                  size="large"
                  href={'https://nokialearn.csod.com/GlobalSearch/search.aspx?s=&q=' + value}
                  target="_blank"
                  onClick={handleClick}
                >
                  Extend your search for "{value}" on NLDH{' '}
                </Button>
              )}
            </div>
          </div>
}

const showSegment = (setSegment, listSegments) => {
  // Only display segments if some data are available. The className is set to advSearchSelect to keep the same geometry
  if (listSegments.length === 0 || listSegments[1] === '#NOT USED#') {
    return <div> </div>
  } else
    return (
      <Select
        placeholder="Filter by audience"
        name="segment"
        onChange={(data) => setSegment(data)}
        className="advSearchSelect"
        style={{marginTop: editMode, marginRight: '20px'}}
      >
        {listSegments.map((segmentString, index) => (
          <Option value={segmentString} key={index}>
            {segmentString ? segmentString : '-- no filter --'}
          </Option>
        ))}
      </Select>
    )
}

export default (props) => {
  const { name, uriFormat } = props.match.params
  const storeDef = Source.getDef(name)

  const [value, setValue] = useState(new URLSearchParams(props.location.search).get('search'))
  const [format, setFormat] = useState(uriFormat)
  const [segment, setSegment] = useState('')
  const [date] = useState(null)
  const [lim, setLim] = useState(20)
  const [, setEdit] = useState(EditCtl.editMode)
  const [sortAl, setSortAl] = useState(false)
  const [sortDa, setSortDa] = useState(false)
  const [currentUser, setCurrentUser] = useState(B.userProfile)
  const [data, setData] = useState(theData)
  // BV: set the default display to Table is defaultSearchTableMode is defined in store.json
  const [table, setTable] = useState(storeDef['defaultSearchTableMode'])

  if (!data) Source.fetch(name).then( resp => setData(theData = resp) )

  const compareDates = (a, b) => moment(a).isBefore(moment(b)) ? 1 : moment(b).isBefore(moment(a)) ? -1 : 0
  const addRecommended = rst => {
    // console.log('addRecommended', currentUser && currentUser.recommended)
    if (currentUser) currentUser.recommended.forEach( elt => {
      const i = rst.indexOf(elt)
      if (i > -1) {
        rst.splice(i, 1)
        rst.unshift(elt)
      }
    })
    return rst
  }

  const results = data ? addRecommended(Source.advancedFilter(name, value, format, segment, date)
  .sort((a, b) => sortAl && a.Title.trim().localeCompare(b.Title.trim()))
  .sort((a, b) => sortDa && compareDates(a.date, b.date)) ) : null;

  const adv = new Component()
  adv.props = props
  adv.state = {}
  adv.forceUpdate = () => {
    setEdit(EditCtl.editMode)
  }
  B.set(adv)

  // if(EditCtl.editMode) EditCtl.switchEditMode();

  const listFormats = getFormats(storeDef)
  const listSegments = getSegments(storeDef)

  const imageFile = (format) => {
    const url = `${process.env.PUBLIC_URL}/img/Icon-${(format || 'filler').trim().replace(/ /g, '')}.png`

    // Test if image exists. If not return default image
    var http = new XMLHttpRequest();
    http.open('HEAD', url, false);
    http.send();
    if (http.status === 404) return `${process.env.PUBLIC_URL}/img/Icon-Material.png`

    return url
  }

  const columns = [
    {
      title: '',
      dataIndex: 'format',
      width: '4%',
      key: 'formatIcon',
      align: 'center',
      render: (format) => (
        <img
          src={imageFile(format)}
          alt=""
          className="iconThumb tableIcon"
          height={30}
        />
      ),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      width: '19%',
      key: 'title',
      sorter: (a, b) => a.title[0].trim().localeCompare(b.title[0].trim()),
      sortDirections: ['descend', 'ascend'],
      render: ([text, id, name]) => (
        <Link className="link" to={{ pathname: `/${name}/item/${id}` }}>
          {text}
        </Link>
      ),
    },
    {
      title: 'Included in ...',
      dataIndex: 'parents',
      width: '19%',
      key: 'parents',
      render: (data) => (
        <span>
          {data.length > 0
            ? data.map(([text, id, name], index) => (
                <span key={index}>
                  <Link
                    className="link"
                    to={{ pathname: `/${name}/item/${id}` }}
                  >
                    {text}
                  </Link>
                  <br />
                </span>
              ))
            : '-'}
        </span>
      ),
      // width: '20%',
    },
    {
      title: 'Format',
      key: 'format',
      dataIndex: 'format',
      width: '19%',
      sorter: (a, b) => a.format.trim().localeCompare(b.format.trim()),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Last Edited',
      key: 'date',
      dataIndex: 'date',
      width: '19%',
      sorter: (a, b) => compareDates(a.date, b.date),
      sortDirections: ['descend', 'ascend'],
      render: (date) => moment(date).format('ddd Do MMM YYYY'),
    },
    {
      title: 'Segments',
      key: 'segments',
      dataIndex: 'segments',
      width: '19%',
      render: (tags) => (
        <span>
          {(typeof tags !== 'string' && tags.length) > 0
            ? tags.map((tag, index) => {
                return (
                  <span key={index}>
                    <Tooltip placement="bottom" title={<span>{tag}</span>}>
                      <Tag>{trunc(tag, 20)}</Tag>
                    </Tooltip>
                    <br />
                  </span>
                )
              })
            : 'No Segment'}
        </span>
      ),
    },
  ]

  const toggleBtn = table 
          ? (
              <Tooltip placement="left" title={<span>Thumbnails View</span>}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="appstore"
                  size={'large'}
                  className="editButtonSearch"
                  style={{ marginRight: 13 }}
                  onClick={() => setTable(false)}
                />
              </Tooltip>) 
          : (
              <Tooltip placement="left" title={<span>Table View</span>}>
                <Button
                  type="primary"
                  shape="circle"
                  icon="table"
                  size={'large'}
                  style={{ marginRight: 13 }}
                  className="editButtonSearch"
                  onClick={() => setTable(true)}
                />
              </Tooltip>
            )

  const azBtn = !table && (
    <Tooltip placement="left" title={<span>Sort alphabetically</span>}>
      <Button
        type="primary"
        shape="circle"
        icon="sort-ascending"
        size={'large'}
        className="editButtonSearch"
        style={{ marginTop: '50px', marginRight: 13 }}
        onClick={() => {
          setSortDa(false)
          setSortAl(true)
        }}
      />
    </Tooltip>
  )
  const dateBtn = !table && (
    <Tooltip placement="left" title={<span>Sort chronologically</span>}>
      <Button
        type="primary"
        shape="circle"
        icon="calendar"
        size={'large'}
        className="editButtonSearch"
        style={{ marginTop: '100px', marginRight: 13 }}
        onClick={() => {
          setSortAl(false)
          setSortDa(true)
        }}
      />
    </Tooltip>
  )

  const trunc = (data, n) => data.length > n ? data.substr(0, n) + '...' : data
  const items = showItems(results, props, storeDef, value, lim, setLim, table, columns, currentUser)

  if (!data) return (
    <div>
      <div className="head">
        <HeaderComponent {...props} setCurrentUser={setCurrentUser} data={storeDef} />
      </div>
      <div className="loading">
        <AdvancedLoader />
      </div>
    </div>
  )
  document.title = 'Search ' +  (value?value:'') + ' | Nokia Learning Store'

  return (
    <div className="store">
      <div className="head">
        <HeaderComponent {...props} title={((uriFormat)?uriFormat:'Learning Store') + ((value && value!=='')? " | " + value : '')} setCurrentUser={setCurrentUser} data={storeDef} />
      </div>

      <div className="searchEngine">
        <Select
          placeholder="Filter by category"
          name="format"
          //onChange={(data) => setFormat(data)}
          onChange={(data) => {
            setFormat(data)
            props.history.push({
              pathname: '/' + name + '/advanced/' + data,
              search: value !== null ? '?search=' + value : '',
            })
          }}
          className="advSearchSelect"
          value={uriFormat}
          style={{ marginTop: editMode, marginRight: '20px'}}
        >
        {listFormats.map((formatString, index) => (
            <Option value={formatString} key={index}>
              {formatString ? formatString : '-- no filter --'}
            </Option>
        ))}
        </Select>
        {showSegment(setSegment, listSegments)}
        <Input
          type="text"
          name="value"
          className="advSearchInput"
          placeholder="Search ..."
          autoFocus={true}
          defaultValue={value}
          // enterButton="Search" 
          onChange={ e => {
            setValue(e.target.value)    
            props.history.push({
              pathname:
                '/' + name + '/advanced/' + format !== null ? format : '',
              search:
                e !== null ? '?search=' + e.target.value : '',
            })
          }}
          style={{ marginTop: editMode}}
        />
        {/* <DatePicker onChange={value => setDate(value)} style={{marginTop: editMode}} /> */}
      </div>
      {
        <div className='resultsGrid'>
          {items}
        </div>
      }
      {toggleBtn}
      {azBtn}
      {dateBtn}
      <FooterComponent props={props} data={storeDef} />
      <BackTop visibilityHeight={1500} />
      {/* <BottomScrollListener onBottom={this.loadMore} offset={1000}/> */}
    </div>
  )
}
