/*
  @author Félix Fuin
  Copyright Nokia 2017. All rights reserved.
*/

import React, { Component } from 'react'
import 'rc-menu/assets/index.css'
import '../css/Navigation.css'
import { Menu } from 'antd'
import { FaPlusCircle } from 'react-icons/fa'
import Box from '@material-ui/core/Box'
// import { makeStyles } from '@material-ui/core/styles';

import B from '../tools/back'

// import FaTh from "react-icons/lib/fa/th-list";

const item = 'item/'


const styles = theme => ({
  BtnLinks: {
    float: 'left',
    width: '100%',
    border: '1px solid #0065ed',
    backgroundColor: '#fff',
    color: '#000324',
    cursor: 'pointer',
    transition: 'all 0.5s ease 0s',
    "&:hover": {
      backgroundColor: '#0065ed',
      color: '#fff'
    }

  },
});

export default class Navigation extends Component {
  state = { openKeys: [] }

  onOpenChange = (openKeys) => {
    // console.log('openKeys bef', openKeys)
    const lastRoot = openKeys.map((key) => key.charAt(0)).lastIndexOf('r')
    // const lastRoot = openKeys.length - 1 - openKeys.reverse().findIndex( key => key.startsWith('r') );
    // console.log('rooot', lastRoot)
    B.openKeys = openKeys = openKeys.slice(lastRoot)
    // console.log('openKeys aft', openKeys);
    this.setState({
      openKeys, // latestOpenKey ? [latestOpenKey] : []
    })
  }

  onClick(info) {
    // console.log('info', info, 'hh', B.openKeys, this.state.openKeys)
    if (info.key.indexOf('http') === 0 || info.key.indexOf('mailto:') === 0) {
      const uri = info.key.split('|') // to support "url": "https://learningstore.nokia.com/sites/badge|Learning Store"
      window.open(uri[0], uri[1] || '_blank')
    } else if (info.key.startsWith('/')) 
      this.props.history.push('/' + this.props.history.location.pathname.split('/')[1] + info.key,)
    else 
      this.props.history.push(
        '/' +
          this.props.history.location.pathname.split('/')[1] +
          '/' +
          item +
          info.key,
      )
    this.props.toggleSideNav()
  }

  menuGen(node) {
    this.index++
    if (!node.content)
      return (
        <Menu
          key={node.id || node.url || this.index}
          style={{ width: 'auto' }}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}  
          mode="inline"
          fontWeight= 'bold'
        >
          {' '}
          <Menu.Item
            key={node.id || node.url || this.index}
            // style={
            //   node.bold
            //     ? { fontWeight: 'bold', color: '#096dd9' }
            //     : { color: '#096dd9' }
            // }
          >
            <span className="dropdown-item" style={styles.BtnLinks} >
              {' '}
              <Box> {node.title}</Box>
            </span>
          </Menu.Item>
        </Menu>
      )

    return (
      <Menu.SubMenu
        key={node.key || this.index}
        title={
          <span>
            <FaPlusCircle
              style={{
                marginRight: 10,
                color: 'lightgrey',
                fontSize: '1em',
              }}
            />
            <span className={'textMenuDrawer'}>{node.title}</span>
          </span>
        }
      >
        {node.content.map((link) => this.menuGen(link))}
      </Menu.SubMenu>
    )
  }

  UNSAFE_componentWillMount() {
    this.props.data.menu.forEach((link, index) => {
      if (link.content) link.key = 'r' + index
    })
    this.setState({ openKeys: B.openKeys || (B.openKeys = this.props.data.defaultKeys) || [] })
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ openKeys: B.openKeys || this.props.data.defaultKeys || [] })
  }

  render() {
    this.index = 0
    const links = this.props.data.menu.map((link) => this.menuGen(link))

    return (
      <div>
        <div className="navigation">
          <Menu
            onClick={(key) => this.onClick(key)}
            mode="inline"
            style={{ width: 320 }}
            openKeys={this.state.openKeys}
            onOpenChange={this.onOpenChange}
            className="textMenuDrawer"
          >
            {links}
          </Menu>
        </div>
      </div>
    )
  }
}
